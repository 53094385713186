import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  TableRow,
  TableCell,
  Typography,
  Grid,
  Dialog,
  IconButton,
  Box,
} from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { theme as koneTheme, CircleCancel, theme } from '@konecorp/ui-library';

import {
  Answer,
  isNewDeviation,
  PutAnswerPayload,
  Question,
  QuestionValueType,
} from '../../schemas';
import { getLocalizedText } from '../../helpers/custom-i18n';
import { useTranslation } from 'react-i18next';
import { useIfReadOnly } from '../../hooks/useIfReadOnly';
import ReviewQuestionInfo from '../../components/ReviewQuestionInfo';
import {
  CreateDeviationPayload,
  EditDeviationPayload,
} from '../../components/DeviationForm';
import QuestionNumericValue from '../../components/QuestionNumericValue';
import CustomizedLocalizedQuestionText from '../../components/CustomizedLocalizedQuestionText';
import AnswerValueWithDeviation from '../AnswerValueWithDeviation';
import QuestionStringValue from '../../components/QuestionStringValue';

export interface ReviewQuestionProps {
  question: Question;
  questionSetId: string;
  questionSequence: number;
  /*
            The index could be 0 if the question is type dummy or header of the group of questions.
            Otherwise the index could be either odd or even, which reflects on the different background color.
           */
  tableRowIndex: number;
  /**
   * This is required as part of the Info modal and NoAnswerForm Modal heading part
   */
  answer?: Answer | null;
  globalArr?: string[];
  saveAnswer: (newAnswer: PutAnswerPayload) => Promise<void>;
  onCreateDeviation: (deviation: CreateDeviationPayload) => Promise<void>;
  onEditDeviation: (deviation: EditDeviationPayload) => Promise<void>;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    evenRow: {
      backgroundColor: theme.palette.background.default,
      padding: '4px 0 4px 12px',
    },
    oddRow: {
      backgroundColor: theme.palette.grey[100],
      padding: '4px 0 4px 12px',
    },
    infoIcon: {
      width: 30,
      height: 30,
      marginRight: 10,
    },
    infoIconUpload: {
      width: 30,
      height: 30,
      marginRight: '65px',
    },
    infoIconWarning: {
      width: 30,
      height: 30,
    },
    headerRow: {
      color: theme.palette.primary.main,
      paddingTop: theme.spacing(4),
      textTransform: 'uppercase',
    },
    answerContainer: {
      flexWrap: 'wrap',
      justifyContent: 'end',
      paddingRight: theme.spacing(0.5),
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(5),
      },
    },
    closeDialogIcon: {
      width: 30,
      height: 30,
      position: 'absolute',
      right: theme.spacing(1.5),
      top: theme.spacing(1.5),
      [theme.breakpoints.up('sm')]: {
        width: 50,
        height: 50,
        right: theme.spacing(4),
        top: theme.spacing(4),
      },
    },
    modal: {
      alignItems: 'center',
      display: 'flex',
      background: theme.palette.background.paper,
      justifyContent: 'center',
      '& .MuiDialog-container': {
        width: '100%',
      },
    },
  })
);

const ReviewQuestion = (props: ReviewQuestionProps): JSX.Element => {
  const { i18n } = useTranslation();
  const [isReadOnlyMode] = useIfReadOnly();
  const classes = useStyles(koneTheme);

  const {
    question,
    questionSetId,
    questionSequence,
    tableRowIndex,
    answer,
    saveAnswer,
    onCreateDeviation,
    onEditDeviation,
    globalArr,
  } = props;

  const localizedQuestionText = getLocalizedText(i18n, question.text)?.text || '';
  const localizedQuestionTextWithoutWhiteSpace = localizedQuestionText
    .replaceAll(' ', '-')
    .trim();

  const [modalState, setModalState] = useState({
    isOpen: false,
    content: <></>,
  });

  const onCloseModal = () => setModalState({ isOpen: false, content: <></> });
  const createGenerateClassName = (valueType: QuestionValueType) => {
    if (valueType === QuestionValueType.PICTURE) {
      return classes.infoIconUpload;
    } else if (valueType === QuestionValueType.WARNING_OK_NOTOK_NA) {
      return classes.infoIconWarning;
    } else {
      return classes.infoIcon;
    }
  };
  const onInfoButtonClick = () => {
    const localizedAdditionalInfo =
      getLocalizedText(i18n, question.additionalInfo)?.text || '';

    setModalState({
      isOpen: true,
      content: (
        <ReviewQuestionInfo
          title={localizedQuestionText}
          imageLink={question.imageLink}
          additionalInfo={localizedAdditionalInfo}
        />
      ),
    });
  };

  if (tableRowIndex === 0) {
    return (
      <TableRow>
        <TableCell
          className={classes.headerRow}
          data-testid={`header-question-row-${localizedQuestionTextWithoutWhiteSpace}`}
        >
          <Typography variant="h6">{localizedQuestionText}</Typography>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        <TableCell
          className={tableRowIndex % 2 === 0 ? classes.evenRow : classes.oddRow}
          data-testid={
            tableRowIndex % 2 === 0
              ? `even-question-row-${localizedQuestionTextWithoutWhiteSpace}`
              : `odd-question-row-${localizedQuestionTextWithoutWhiteSpace}`
          }
        >
          <Dialog
            fullScreen
            fullWidth
            className={classes.modal}
            onClose={onCloseModal}
            open={modalState.isOpen}
          >
            <IconButton onClick={onCloseModal} data-testid="review-question-close-modal">
              <CircleCancel
                htmlColor={theme.palette.grey[900]}
                className={classes.closeDialogIcon}
              />
            </IconButton>
            {modalState.content}
          </Dialog>
          <Grid container alignItems="flex-start">
            <Grid item xs={7}>
              <CustomizedLocalizedQuestionText
                localaizedQuestionText={localizedQuestionText}
              />
            </Grid>
            <Grid container item xs={5} justify="flex-end">
              <Box display="flex" alignItems="center" className={classes.answerContainer}>
                <InfoOutlined
                  data-testid="review-question-info-button"
                  onClick={onInfoButtonClick}
                  className={createGenerateClassName(
                    question.valueType || QuestionValueType.DEFAULT
                  )}
                />

                {(question.valueType === QuestionValueType.YES_NO ||
                  QuestionValueType.OK_NOTOK ||
                  QuestionValueType.OK_NOTOK_NA ||
                  QuestionValueType.WARNING_OK_NOTOK_NA ||
                  QuestionValueType.YES_NO_CHOICE ||
                  QuestionValueType.PICTURE) && (
                  <AnswerValueWithDeviation
                    answer={answer?.value}
                    question={question}
                    questionSetId={questionSetId}
                    questionSequence={questionSequence}
                    isReadOnly={isReadOnlyMode}
                    onAnswer={async (newAnswer, deviation) => {
                      if (deviation) {
                        if (isNewDeviation(deviation)) {
                          await onCreateDeviation(deviation as CreateDeviationPayload);
                        } else {
                          await onEditDeviation(deviation as EditDeviationPayload);
                        }
                      }

                      saveAnswer(newAnswer);
                    }}
                  />
                )}
                {question.valueType === QuestionValueType.NUMBER && (
                  <QuestionNumericValue
                    question={question}
                    answer={answer?.value}
                    onAnswer={saveAnswer}
                    isReadOnly={isReadOnlyMode}
                  />
                )}
                {question.valueType === QuestionValueType.STRING && (
                  <QuestionStringValue
                    question={question}
                    answer={answer?.value}
                    questionSetId={questionSetId}
                    onAnswer={saveAnswer}
                    isReadOnly={isReadOnlyMode}
                    globalArr={globalArr || []}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
};

export default ReviewQuestion;
