import { omit, pick } from 'lodash';
import { InstallationTask } from '../components/InstallationTask';
import { get } from './fetch';

const flatAttributes = [
  'description',
  'eqCity',
  'eqCustname',
  'eqPostcode',
  'eqStreet',
  'sapOrderId',
  'sapOrderPosition',
  'tacoConfigurationId',
  'tacoConfigurationDescription',
  'tacoManningType',
  'tacoProjectNumber',
  'tacoSupervisor',
  'tacoWorkdayLength',
  'typFlNetwork',
];

/**
 * Fetch single installation task.
 *
 * @param objId - Object id
 * @param token - JSON web token
 */
export const getByObjId = async (
  objId: string,
  token: string
): Promise<InstallationTask> => {
  try {
    const task = await get(`v1/installationtasks/${objId}`, token);

    if ('attributeValues' in task) return task as InstallationTask;

    // Here we rebuild attributeValues because it can be missing from the data.
    // If attributeValues is missing, its values are part of the task object.
    // TODO: Return result as InstallationTask and update type
    // once the backend has removed support for attributeValues
    return {
      ...omit(task, flatAttributes),
      attributeValues: pick(task, flatAttributes),
    } as InstallationTask;
  } catch (error) {
    console.error('installationTasks:getByObjId', error);
    return {} as InstallationTask;
  }
};

export enum ResultFilterTypes {
  KoneEqNum = 'koneEqNum',
  TypFlNetwork = 'typFlNetwork',
  TacoProjectNumber = 'tacoProjectNumber',
  Description = 'description',
  EqCustname = 'eqCustname',
}

/**
 * Fetch installation task ids by some filter.
 *
 * @param selection - Result filter, can be `koneEqNum`, `typFlNetwork`, `tacoProjectNumber`, `description` or `eqCustname`
 * @param value     - Value for filter
 * @param token     - JSON web token
 */
export const getBySelectionAndValue = async (
  selection: ResultFilterTypes,
  value: string,
  token: string
): Promise<string[]> => {
  try {
    const response = await get(`v1/installationtasks?${selection}=${value}`, token);

    return response || [];
  } catch (error) {
    console.error('installationTasks:getBySelectionAndValue', error);
    return [];
  }
};
