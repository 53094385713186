import { uniqBy, uniq, omit, pick } from 'lodash';

import {
  generateIndexedDBKey,
  storedIndexedDBObjectType,
  getIndexedDBObject,
  upsertIndexedDBObject,
} from './indexedDB';
import {
  Deviation,
  ServiceWorkerEventMessageType,
  ServiceWorkerEventMessagePayload,
} from '../schemas';

export type DeviationToSync = {
  guid: string;
  timestamp: number;
};

export type DeviationsLock = {
  syncInProgress: boolean;
  syncTimestamp?: number;
};

export enum ModifyAction {
  CREATE = 'create',
  UPDATE = 'update',
}

export type DeviationSyncResult = {
  ok: boolean;
  updatedDeviation: Deviation;
};

const SYNC_MAX_DURATION_MINUTES = 5;

const API_URL = process.env.REACT_APP_APIGW_URL || '';

export const cacheDeviationsData = async (
  networkNumber: string,
  deviationsData: Deviation[]
): Promise<void> => {
  const deviationsDataKey = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.DEVIATIONS
  );
  await upsertIndexedDBObject(deviationsData, deviationsDataKey);
};

export const modifyDeviationOffline = async (
  request: Request,
  modifyAction: ModifyAction
): Promise<Deviation | undefined> => {
  const regexRule =
    modifyAction === ModifyAction.CREATE
      ? '/deviations/([0-9]{8})'
      : '/deviations/([0-9]{8})/(.*)$';

  const regexMatched = request.url.match(regexRule);
  const networkNumber = regexMatched ? regexMatched[1] : '';

  const now = Date.now();

  try {
    await setDeviationsLock(networkNumber, { syncInProgress: true, syncTimestamp: now });

    const cloneRequest = request.clone();
    const payload = await cloneRequest.json();

    const deviationToSyncFromIndexedDB = await getDeviationsToSync(networkNumber);
    const deviationsFromIndexedDB = await getDeviations(networkNumber);

    if (modifyAction === ModifyAction.CREATE) {
      const deviationToSync: DeviationToSync = {
        guid: `temp_${now}`,
        timestamp: now,
      };

      await setDeviationsToSync(networkNumber, [
        ...deviationToSyncFromIndexedDB,
        deviationToSync,
      ]);

      // GUID will be replaced after sent back from backend
      // Temporary GUID stays to synchronize local changes with backend data
      const newDeviation: Deviation = {
        ...payload,
        guid: `temp_${now}`,
        tempGuid: `temp_${now}`,
        createdAt: new Date(now).toISOString(),
      };

      await setDeviations(networkNumber, [...deviationsFromIndexedDB, newDeviation]);

      return newDeviation;
    } else {
      let guid = regexMatched ? regexMatched[2] : '';

      if (guid.includes('temp')) {
        // This is to handle the situation that user try to update a deviation created offline (with tempGUID)
        // that is current in syncing process and once the sync is finish, there will be a real guid to be used
        const deviation = deviationsFromIndexedDB.find((x) => x.tempGuid === guid);
        guid = deviation ? deviation.guid : guid;
      }

      const deviationToSync: DeviationToSync = {
        guid,
        timestamp: now,
      };

      await setDeviationsToSync(networkNumber, [
        ...deviationToSyncFromIndexedDB,
        deviationToSync,
      ]);

      const modifedDeviationsList = deviationsFromIndexedDB.map((deviation) =>
        deviation.guid === guid
          ? {
              ...deviation,
              ...payload,
              userComment: deviation.userComment
                ? `${deviation.userComment}, ${payload.userComment}`
                : payload.userComment,
              files: [...(deviation.files || []), ...(payload.files || [])],
            }
          : deviation
      );

      await setDeviations(networkNumber, modifedDeviationsList);

      const modifiedDeviation = modifedDeviationsList.find(
        (deviation) => deviation.guid === guid
      );

      return modifiedDeviation;
    }
  } catch (e) {
    return Promise.reject(
      `Error while trying to modifiy Deviation offline (action: ${modifyAction}). Error: ${e}`
    );
  } finally {
    await setDeviationsLock(networkNumber, { syncInProgress: false });
  }
};

export const syncDeviations = async (
  networkNumber: string,
  jwtToken: string
): Promise<DeviationSyncResult[]> => {
  const syncStart = Date.now();

  const deviationsLock = await getDeviationsLock(networkNumber);

  const minutesSinceLastSync = deviationsLock.syncTimestamp
    ? (syncStart - deviationsLock.syncTimestamp) / 60000
    : 0;

  if (deviationsLock.syncInProgress && minutesSinceLastSync < SYNC_MAX_DURATION_MINUTES) {
    return [];
  } else {
    await setDeviationsLock(networkNumber, {
      syncInProgress: true,
      syncTimestamp: syncStart,
    });
  }

  const deviationsToSync = await getDeviationsToSync(networkNumber);

  if (deviationsToSync.length === 0) {
    await setDeviationsLock(networkNumber, { syncInProgress: false });
    return [];
  }

  const deviations = await getDeviations(networkNumber);

  const deviationUpdates = uniqBy(deviationsToSync, 'guid')
    .map((deviationToSync) => deviationToSync.guid)
    .map((guid) => deviations.find((deviation) => deviation.guid === guid))
    .filter((deviation): deviation is Deviation => Boolean(deviation))
    .map(async (deviation) => {
      const isNewDeviation = deviation.guid.startsWith('temp');

      const url = isNewDeviation
        ? `${API_URL}/v1/deviations/${networkNumber}`
        : `${API_URL}/v1/deviations/${networkNumber}/${deviation.guid}`;
      const method = isNewDeviation ? 'POST' : 'PATCH';

      const body = isNewDeviation
        ? omit(deviation, ['guid', 'tempGuid', 'createdAt'])
        : pick(deviation, [
            'assignee',
            'blocker',
            'compliance',
            'delay',
            'status',
            'userComment',
            'type',
            'files',
            'description',
            'closedBy',
            'closedAt',
          ]);

      try {
        const response = await fetch(url, {
          method,
          headers: { Authorization: jwtToken, 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw Error(
            `got response not OK from the server, for networkNumber: ${deviation.guid}`
          );
        }

        const responseBody = await response.json();

        return <DeviationSyncResult>{
          ok: response.ok,
          updatedDeviation: { ...deviation, ...responseBody },
        };
      } catch (error) {
        return <DeviationSyncResult>{ ok: false, updatedDeviation: deviation };
      }
    });

  const syncedResults = (await Promise.allSettled(deviationUpdates))
    .filter(
      (result): result is PromiseFulfilledResult<DeviationSyncResult> =>
        result.status === 'fulfilled'
    )
    .map((result) => result.value);

  const notProcessedDeviationsToSync: DeviationToSync[] = (
    await getDeviationsToSync(networkNumber)
  ).filter((deviation) => {
    const addedDuringSync = deviation.timestamp > syncStart;

    const isSyncSuccess = syncedResults.some(
      (result) =>
        (result.updatedDeviation.guid === deviation.guid ||
          result.updatedDeviation.tempGuid === deviation.guid) &&
        result.ok
    );

    return addedDuringSync || !isSyncSuccess;
  });

  setDeviationsToSync(networkNumber, notProcessedDeviationsToSync);

  try {
    const updatedDeviationsResponse = await fetch(
      `${API_URL}/v1/deviations?networkNumber=${networkNumber}`,
      {
        method: 'GET',
        headers: { Authorization: jwtToken, 'Content-Type': 'application/json' },
      }
    );
    if (updatedDeviationsResponse.ok) {
      const deviationFromBackend: Deviation[] = await updatedDeviationsResponse.json();

      const deviationsFromBackendWithTempGuid = deviationFromBackend.map((deviation) => {
        const syncedDeviationResult = syncedResults.find(
          (result) => result.updatedDeviation.guid === deviation.guid
        );

        return syncedDeviationResult
          ? { ...deviation, tempGuid: syncedDeviationResult.updatedDeviation.tempGuid }
          : deviation;
      });

      const deviationToSet = mergeDeviationsAfterSync(
        deviationsFromBackendWithTempGuid,
        deviations,
        notProcessedDeviationsToSync
      );

      setDeviations(networkNumber, deviationToSet);
    }
  } catch {
    console.error('Cannot update deviation data');
  }

  await setDeviationsLock(networkNumber, { syncInProgress: false });
  return syncedResults;
};

export const isSyncDeviationsInProgress = async (
  networkNumber: string
): Promise<boolean> => (await getDeviationsLock(networkNumber)).syncInProgress;

const getDeviationsLock = async (networkNumber: string): Promise<DeviationsLock> => {
  const key = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.DEVIATIONS_LOCK
  );
  return (await getIndexedDBObject<DeviationsLock>(key)) || { syncInProgress: false };
};

const setDeviationsLock = async (
  networkNumber: string,
  deviationsLock: DeviationsLock
): Promise<void> => {
  const key = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.DEVIATIONS_LOCK
  );
  await upsertIndexedDBObject(deviationsLock, key);
};

export const getDeviationsToSync = async (
  networkNumber: string
): Promise<DeviationToSync[]> => {
  const key = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.DEVIATIONS_TO_SYNC
  );
  return (await getIndexedDBObject<DeviationToSync[]>(key)) || [];
};

const setDeviationsToSync = async (
  networkNumber: string,
  deviationsToSync: DeviationToSync[]
): Promise<void> => {
  const key = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.DEVIATIONS_TO_SYNC
  );
  await upsertIndexedDBObject(deviationsToSync, key);
};

const getDeviations = async (networkNumber: string): Promise<Deviation[]> => {
  const key = generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.DEVIATIONS);
  return (await getIndexedDBObject<Deviation[]>(key)) || [];
};

const setDeviations = async (
  networkNumber: string,
  deviations: Deviation[]
): Promise<void> => {
  const key = generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.DEVIATIONS);
  await upsertIndexedDBObject(deviations, key);
};

const mergeDeviationsAfterSync = (
  deviationsFromBackend: Deviation[],
  deviationsFromIndexedDB: Deviation[],
  unsyncedDeviation: DeviationToSync[]
) => {
  const mergedDeviations = uniq([
    ...deviationsFromBackend.map((deviation) => deviation.guid),
    ...deviationsFromIndexedDB.reduce((acummulator: string[], deviation: Deviation) => {
      /* if deviation in indexDB has a temp guid we check if the backend successful create a real guid or not
                     if yes then we use the backend generated guid instead of the temp guid
                   */
      if (deviation.guid.startsWith('temp')) {
        const found = deviationsFromBackend.find((x) => x.tempGuid);
        const guidToPush = found ? found.guid : deviation.guid;

        return [...acummulator, guidToPush];
      }
      return [...acummulator, deviation.guid];
    }, [] as string[]),
    ...unsyncedDeviation.map((result) => result.guid),
  ])
    .map((guid) => [
      deviationsFromBackend.find((deviation) => deviation.guid === guid),
      deviationsFromIndexedDB.find((deviation) => deviation.guid === guid),
      unsyncedDeviation.some((result) => result.guid === guid),
    ])
    .map(([backend, local, unsynced]) => {
      if (!backend || (local && unsynced)) {
        return local as Deviation;
      } else {
        return backend as Deviation;
      }
    });

  return mergedDeviations;
};

/* This function is a promisified version of sending message (and wait for the response)
   between UI and service worker. Since it mostly browser utility functions, skipping unit tests
*/
const channelSendMessageToServiceWorker = (
  message: ServiceWorkerEventMessagePayload
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    const senderPort = channel.port1;
    const receiverPort = channel.port2;

    senderPort.onmessage = (event: MessageEvent) => {
      if (event.data.error) {
        reject(event.data.error);
      } else {
        resolve(event.data);
      }
    };

    navigator.serviceWorker.controller?.postMessage(message, [receiverPort]);
  });
};

export const manualSyncFromUI = async (
  networkNumber: string,
  token: string
): Promise<void> => {
  const message: ServiceWorkerEventMessagePayload = {
    messageType: ServiceWorkerEventMessageType.MANUAL_SYNC_DEVIATIONS,
    accessToken: token,
    networkNumber,
  };

  await channelSendMessageToServiceWorker(message);
};
