import { useContext } from 'react';
import Context from '../context/index';
import { useGetCurrentUserRole } from './useGetCurrentUserRole';
import { ActivityDifferentiator, Answer, Installation } from '../schemas';

type updateAnswerInContextFunction = (
  newAnswer: Answer,
  questionSetIdParam: string | null,
  questionSequenceNumber: number | null
) => void;

export const useUpdateAnswerInContext = (): [updateAnswerInContextFunction] => {
  const { installationData, updateInstallationData } = useContext(Context);
  const [userRole] = useGetCurrentUserRole();

  const updateAnswerInContext = (
    newAnswer: Answer,
    questionSetIdParam: string | null,
    questionSequenceNumber: number | null
  ) => {
    if (newAnswer && questionSequenceNumber !== null && questionSetIdParam) {
      switch (userRole) {
        case ActivityDifferentiator.INST: {
          const newAnswerSets = installationData?.installerAnswers.map((answerSet) => {
            if (answerSet.questionSetId === questionSetIdParam) {
              const newAnswers = answerSet.answers.map((ans, i) =>
                i === questionSequenceNumber ? newAnswer : ans
              );
              return {
                questionSetId: answerSet.questionSetId,
                answers: newAnswers,
              };
            }
            return answerSet;
          });
          updateInstallationData({
            ...installationData,
            installerAnswers: newAnswerSets,
          } as Installation);
          break;
        }

        case ActivityDifferentiator.CMSN:
        case ActivityDifferentiator.SPV:
        case ActivityDifferentiator.SEEN: {
          const newAnswerSets = installationData?.testerAnswers.map((answerSet) => {
            if (answerSet.questionSetId === questionSetIdParam) {
              const newAnswers = answerSet.answers.map((ans, i) =>
                i === questionSequenceNumber ? newAnswer : ans
              );
              return {
                questionSetId: answerSet.questionSetId,
                answers: newAnswers,
              };
            }
            return answerSet;
          });
          updateInstallationData({
            ...installationData,
            testerAnswers: newAnswerSets,
          } as Installation);
          break;
        }
      }
    }
  };
  return [updateAnswerInContext];
};
