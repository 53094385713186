import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
  Button,
  Box,
  Divider,
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Context from '../../context';

export const techincalErrorToString = (technicalError: unknown): string => {
  if (axios.isAxiosError(technicalError)) {
    return JSON.stringify(technicalError.response);
  }

  if (technicalError instanceof Error) {
    return `Name: ${technicalError.name}. Message: ${technicalError.message}`;
  }

  if (typeof technicalError === 'string') {
    return technicalError;
  }

  return 'unknow technical error';
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginBottom: theme.spacing(3),
  },
  clickToCopyText: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  copiedText: {
    color: theme.palette.success.main,
  },
}));

const ErrorMessageDialog = (): JSX.Element => {
  const { errorMessage, updateErrorMessage } = useContext(Context);
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const [isTechnicalErrorCopied, setIsTechnicalErrorCopied] = useState<boolean>(false);

  const handleOnCloseClicked = () => {
    setIsTechnicalErrorCopied(false);
    updateErrorMessage(null);
  };

  const handleOnCopyToClipboardClicked = async () => {
    const technicalMessageText = techincalErrorToString(errorMessage?.error);

    await window.navigator['clipboard'].writeText(technicalMessageText);
    setIsTechnicalErrorCopied(true);
  };

  return (
    <Dialog open={!!errorMessage}>
      <DialogContent className={classes.dialogContent}>
        <Box pb={2}>
          <Typography variant="h3">Oh no !!!</Typography>
          <Typography variant="h6" gutterBottom={true}>
            {t('errorMessageDialog.thereIsAnError')}
          </Typography>
          <Divider />
        </Box>
        <Box pb={4}>
          <Typography variant="h6" gutterBottom={true}>
            {t('errorMessageDialog.error', { errorText: errorMessage?.message })}
          </Typography>

          <Box display="flex" alignContent="space-between">
            <Typography
              variant="subtitle1"
              className={classes.clickToCopyText}
              onClick={handleOnCopyToClipboardClicked}
            >
              <FileCopy fontSize="small" />{' '}
              {t('errorMessageDialog.clickToCopyToClipboard')}
            </Typography>
          </Box>

          {isTechnicalErrorCopied && (
            <Typography variant="subtitle1" className={classes.copiedText}>
              {t('errorMessageDialog.technicalErrorCopied')}
            </Typography>
          )}
        </Box>
        <Button
          color="primary"
          variant="contained"
          fullWidth={true}
          onClick={handleOnCloseClicked}
          data-testid="error-dialog-close-button"
        >
          {t('errorMessageDialog.close')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorMessageDialog;
