import { parseISO, format } from 'date-fns';
interface convertToDurationProps {
  days: number;
  hours: number;
  minutes: number;
}

const addLeadingZero = (value: number): string =>
  String(value).length === 1 ? `0${value}` : String(value);

const convertToDuration = (
  value: number,
  dayLengthInHours: number,
  withDays = true
): convertToDurationProps => {
  const getDecimal = (value: number): number => (value ? value - Math.trunc(value) : 0);

  let dDays = 0;
  let dHours;

  if (withDays) {
    dDays = value / dayLengthInHours;
    dHours = getDecimal(dDays) * dayLengthInHours;
  } else {
    dHours = value;
  }
  const dMinutes = getDecimal(dHours) * 60;

  return {
    days: Math.trunc(dDays),
    hours: Math.trunc(dHours),
    minutes: Math.trunc(dMinutes),
  };
};

export const getDayCount = (value: string, dayLengthInHours: number): number => {
  const flooredNumber = Math.floor(Number(value) / dayLengthInHours);

  return flooredNumber === 0 ? 1 : flooredNumber;
};

export const formatDuration = (value: number, dayLengthInHours: number): string => {
  if (value <= 0) {
    return '0 h 0 min';
  } else {
    const { hours, minutes } = convertToDuration(value, dayLengthInHours, false);

    return `${hours} h ${minutes > 0 ? addLeadingZero(minutes) : minutes} min`;
  }
};

// Date input, often directly from API response, can be undefined or null
// This StringOrUndefined type keeps type safety when input data is definitely string
// and returns type string | undefined when input is string or undefined or null
type StringOrUndefined<T> = T extends string ? string : undefined;
export const DEFAULT_DATE_DISPLAY_FORMAT = 'dd.MM.yyyy';
export const DEFAULT_DATE_WITH_HOURS_DISPLAY_FORMAT = 'dd.MM.yyyy HH:mm';
/** Return a dd.MM.yyyy display format of a date string that contains ISO 8601 (YYYY-MM-DD) format
 * @param dateString ISO 8601 date string
 * @param dateFormat optional date format to use, default is dd.MM.yyyy
 * @returns Display format of the date input or undefined if the date input is not valid
 * @example formatDateString('2019-12-31T11:11:18.482Z') // '31.12.2019'
 * @todo: add support for other date formats
 * @link https://en.wikipedia.org/wiki/Date_and_time_notation_in_Europe
 */
export const formatDate = <T extends string | undefined | null>(
  date: T,
  formatDisplay = DEFAULT_DATE_DISPLAY_FORMAT
): StringOrUndefined<T> =>
  (typeof date === 'string' && date.match(/\d{4}-?\d{2}-?\d{2}/)
    ? format(parseISO(date), formatDisplay)
    : undefined) as StringOrUndefined<T>;

/* If you see the warning in VS code that atob is deprecated, it is only deprecated
  in nodeJS, but atob is a way to go in browser without worrying about webpack + Buffer polyfill
*/
export const formatBase64ToUtf8 = (str: string): string => atob(str);
