/* CAUTION: This is component is used in dashboard only, which is not an official part of the
   app. Hence, no unit tests. Use the code from here with caution */
/*istanbul ignore file*/
import React from 'react';
import { utils, WorkSheet, writeFileXLSX } from 'xlsx';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ActivityDifferentiator, ActualDates, InstallationStatus } from '../../schemas';
import { InstallationAnalyticsData, ProcessSummary } from '../Dashboard';
import {
  DEFAULT_DATE_WITH_HOURS_DISPLAY_FORMAT,
  formatDate,
} from '../../helpers/formating';

type ExportButtonProps = {
  installationsData: InstallationAnalyticsData[];
  descriptionLanguage: string;
};

type BasicInfo = {
  networkNumber: string;
  milestone: string;
  handedOverToSeb: 'yes' | 'no';
  sebApproved: 'yes' | 'no' | 'n/a';
  networkedCompleted: 'yes' | 'no';
  salesOrderDescription: string;
  status: InstallationStatus | null;
  supervisor: string;
  installers: string;
  testers: string;
} & ActualDates;

type WorkflowInfo = { networkNumber: string } & {
  [keys in string]: boolean | string;
};

const getWorkersNameByRole = (
  installationData: InstallationAnalyticsData,
  role: ActivityDifferentiator
) => {
  const koneEmployeeNames = installationData.workers.assignees
    .filter((assignee) => assignee.activityDifferentiator === role)
    .map((assignee) => assignee.name);

  const subcontractorNames = installationData.workers.subcontractors
    .filter((subcontractor) => subcontractor.activityDifferentiator === role)
    .map((subcontractor) => subcontractor.name);

  return [...koneEmployeeNames, ...subcontractorNames].join(', ');
};

const generateBasicInfo = (installationsData: InstallationAnalyticsData): BasicInfo => {
  const actualDates = Object.entries(installationsData.actualDates || {}).reduce(
    (accumulated, [key, value]) => {
      return {
        ...accumulated,
        [key]: formatDate(value, DEFAULT_DATE_WITH_HOURS_DISPLAY_FORMAT) || '',
      };
    },
    {}
  );

  const handedOverToSeb = installationsData.handedToSeb ? 'yes' : 'no';

  const sebApproved =
    installationsData.sebAccepted === 1
      ? 'yes'
      : installationsData.sebAccepted === 0
      ? 'no'
      : 'n/a';

  const networkedCompleted = installationsData.completedState ? 'yes' : 'no';

  return {
    networkNumber: installationsData.networkNumber.toString(),
    milestone: installationsData.milestone,
    supervisor: installationsData.workers.supervisor?.name || '',
    handedOverToSeb,
    sebApproved,
    networkedCompleted,
    salesOrderDescription: installationsData.salesOrderDescription || '',
    status: installationsData.status,
    installers: getWorkersNameByRole(installationsData, ActivityDifferentiator.INST),
    testers: getWorkersNameByRole(installationsData, ActivityDifferentiator.CMSN),
    ...actualDates,
  };
};

const generateWorkflowInfo = (
  installation: InstallationAnalyticsData,
  role: ActivityDifferentiator.INST | ActivityDifferentiator.CMSN,
  descriptionLanguage: string
): WorkflowInfo => {
  const networkNumber = String(installation.networkNumber).padStart(8, '0');

  const processSummary =
    role === ActivityDifferentiator.INST
      ? installation.installerProcessSummary
      : installation.testerProcessSummary;

  const getDescription = (summary: ProcessSummary): string => {
    const description = summary.descriptions.find(
      (description) => description.code === descriptionLanguage
    );
    return description?.text || 'n/a';
  };

  const phasesInfo = processSummary.reduce((accumulated, summary) => {
    return { ...accumulated, [getDescription(summary)]: summary.isCompleted };
  }, {});

  return { networkNumber, ...phasesInfo };
};

const setSheetColumnWidth = (sheet: WorkSheet, width: number) => {
  const totalColumns = Object.keys(sheet).filter((key) => key[0] !== '!').length;

  const columnConfig = { wch: width };
  // Apply the column width to all columns in the worksheet
  sheet['!cols'] = Array(totalColumns).fill(columnConfig);
};

export const ExportButton = (props: ExportButtonProps): JSX.Element => {
  const { installationsData, descriptionLanguage } = props;
  const { t } = useTranslation();

  const handleExport = () => {
    const basicData = installationsData?.map(generateBasicInfo);

    const installationPhase = installationsData.map((data) =>
      generateWorkflowInfo(data, ActivityDifferentiator.INST, descriptionLanguage)
    );

    const testingPhase = installationsData.map((data) =>
      generateWorkflowInfo(data, ActivityDifferentiator.CMSN, descriptionLanguage)
    );
    const basicDataSheet = utils.json_to_sheet(basicData);
    const installationSheet = utils.json_to_sheet(installationPhase);
    const testingSheet = utils.json_to_sheet(testingPhase);

    setSheetColumnWidth(basicDataSheet, 30);
    setSheetColumnWidth(installationSheet, 20);
    setSheetColumnWidth(testingSheet, 20);

    basicDataSheet['!autofilter'] = { ref: 'A1:Z1' };
    installationSheet['!autofilter'] = { ref: 'A1:Z1' };
    testingSheet['!autofilter'] = { ref: 'A1:Z1' };

    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, basicDataSheet, 'Basic Info');
    utils.book_append_sheet(workbook, installationSheet, 'Installation Info');
    utils.book_append_sheet(workbook, testingSheet, 'Quality Review Info');

    writeFileXLSX(workbook, 'IES_Dashboard.xlsx');
  };

  return (
    <Button onClick={handleExport} color="primary" endIcon={<GetAppIcon />}>
      {t('dashboard.exportToExcel')}
    </Button>
  );
};
export default ExportButton;
