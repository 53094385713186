import React, { useState } from 'react';
import {
  createStyles,
  IconButton,
  makeStyles,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import { IconAddMedia, IconDelete, theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';
import { Attachment, QuestionValueType } from '../../schemas';
import {
  SUPPORTED_IMAGE_FILE_TYPES,
  SUPPORTED_VIDEO_FILE_TYPES,
  SUPPORTED_AUDIO_FILE_TYPES,
} from '../../constants';

import FileIcon from '../FileIcon';
import PreviewFile from '../PreviewFile';
import { FileType } from '../../helpers/upload-download';
import { useCheckConnection } from '../../hooks/useCheckConnection';

const SUPPORTED_IMAGE_FORMATS = SUPPORTED_IMAGE_FILE_TYPES.map((type) => `image/${type}`);
const SUPPORTED_VIDEO_FORMATS = SUPPORTED_VIDEO_FILE_TYPES.map((type) => `video/${type}`);
const SUPPORTED_AUDIO_FORMATS = SUPPORTED_AUDIO_FILE_TYPES.map((type) => `audio/${type}`);

const SUPPORTED_MEDIA_FORMATS = [
  ...SUPPORTED_IMAGE_FORMATS,
  ...SUPPORTED_VIDEO_FORMATS,
  ...SUPPORTED_AUDIO_FORMATS,
];

const useStyles = makeStyles((theme) =>
  createStyles({
    attachmentList: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        background: 'rgb(0, 0, 0, 0.1)',
      },
      justifyContent: 'flex-end',
    },
    hrStyle: {
      border: 'none',
      height: '2px',
      backgroundColor: '#000',
      margin: '20px 0',
    },
    deleteButtonQReview: {

      marginLeft: 'auto',

    },
    deleteButton: {
      marginLeft: 'auto',
    },
    deleteIcon: {
      width: 12,
      height: 14,
    },
    mediaInput: {
      display: 'none',
    },
    uploadIconLabel: {
      width: 'fit-content',
    },
    uploadIconButton: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0),
    },
    uploadIcon: {
      width: 40,
      height: 40,
      '& path': {
        fill: '#000000',
      },
    },
    uploadContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    fileTypeError: {
      color: theme.palette.error.dark,
      marginLeft: theme.spacing(1),
    },
  })
);
const UploadButton = (props: {
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disabled: boolean;
  questionSequence?: number;
  questionValueType?: QuestionValueType;
}): JSX.Element => {
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [fileTypeError, setFileTypeError] = useState<boolean>(false);
  const { onChangeInput, label, disabled } = props;

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const fileName = file.name;
      const fileNameSplitedArray = fileName.split('.');
      const fileExtension = fileNameSplitedArray.pop()?.toLowerCase();
      const allowedExtensions = [
        ...SUPPORTED_IMAGE_FILE_TYPES,
        ...SUPPORTED_VIDEO_FILE_TYPES,
        ...SUPPORTED_AUDIO_FILE_TYPES,
      ];
      const isFileUploadAllowed =
        fileExtension && allowedExtensions.includes(fileExtension);

      if (isFileUploadAllowed) {
        setFileTypeError(false);
        onChangeInput(e);
      } else {
        setFileTypeError(true);
      }
    }
    e.target.value = ''; // This will not prevent selecting same file as last file.
  };
  return (
    <>
      {label !== undefined && <p>{label}</p>}
      <input
        onChange={handleInputOnChange}
        className={classes.mediaInput}
        data-testid="upload-attachments-button"
        id={`icon-button-file-${props.questionSequence}`} // Fix: Use props.questionSequence instead of questionSequence
        type="file"
        accept={SUPPORTED_MEDIA_FORMATS.join(',')}
        disabled={disabled}
      />
      <div className={classes.uploadContainer}>

        <label
          htmlFor={`icon-button-file-${props.questionSequence}`}
          style={
            props.questionValueType === QuestionValueType.PICTURE
              ? { marginLeft: '-12.5px' }
              : {}
          }
        >

          <IconButton
            className={classes.uploadIconButton}
            aria-label="upload picture"
            component="span"
            disabled={disabled}
          >
            <IconAddMedia className={classes.uploadIcon} />
          </IconButton>
        </label>
        {fileTypeError && (
          <Typography component="span" className={classes.fileTypeError}>
            {t('uploadWidget.invalidFileFormat')}
          </Typography>
        )}
      </div>
    </>
  );
};

const getFileIcon = (file: File | Attachment): JSX.Element => {
  const fileName: string = (
    (file as File).name || (file as Attachment).filename
  ).toLowerCase();

  return <FileIcon fileName={fileName} />;
};

export type UploadWidgetProps = {
  deleteAttachmentsLocally?: (file: File | Attachment) => void;
  handleUploadButton?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFiles: (File | Attachment)[];
  label?: string;
  fileType?: FileType;
  disabled?: boolean;
  questionValueType?: QuestionValueType;
  questionSequence?: number;

};

const UploadWidget = (props: UploadWidgetProps): JSX.Element => {
  const {
    handleUploadButton,
    selectedFiles,
    deleteAttachmentsLocally,
    label,
    fileType = FileType.ATTACHMENT,
    disabled,
    questionValueType,
    questionSequence,

  } = props;
  const classes = useStyles(theme);
  const [fileToPreview, setFileToPreview] = useState<File | Attachment | undefined>();
  const downloadAndPreview = (file: File | Attachment) => {
    setFileToPreview(file);
  };

  const { t } = useTranslation();
  const [isOnline] = useCheckConnection();

  const isFile = (f: File | Attachment): f is File => 'name' in f;
  const getDeleteButtonClassname = (valueType: QuestionValueType) => {
    if (valueType === QuestionValueType.PICTURE) {
      return classes.deleteButtonQReview;
    } else {
      return classes.deleteButton;
    }
  };
  return (
    <>
      {handleUploadButton && (
        <UploadButton
          onChangeInput={handleUploadButton}
          label={isOnline ? label : t('uploadWidget.offlineAttachment')}
          disabled={disabled || !isOnline}
          questionSequence={questionSequence}
        />
      )}
      {Boolean(fileToPreview) && (
        <Dialog
          onClose={() => setFileToPreview(undefined)}
          data-testid="file-preview-dialog"
          open={true}
        >
          <DialogContent>
            <DialogContentText id="file-preview-description">
              {`${
                (fileToPreview as File).name || (fileToPreview as Attachment).filename
              }`}
            </DialogContentText>
            {fileToPreview && <PreviewFile fileType={fileType} file={fileToPreview} />}
          </DialogContent>
        </Dialog>
      )}
      {selectedFiles.map((file, index) => (
        <Box className={classes.attachmentList} key={`attachments-file-${index}`} p={0.5}>
          {getFileIcon(file)}
          <Box component="span" pl={1} onClick={() => downloadAndPreview(file)}>
            {(file as File).name || (file as Attachment).filename}
          </Box>
          {deleteAttachmentsLocally &&
            (isFile(file) ||
              (file && questionValueType === QuestionValueType.PICTURE)) && (
              <IconButton
                size="small"
                className={getDeleteButtonClassname(
                  questionValueType || QuestionValueType.DEFAULT
                )}
                disabled={selectedFiles.length === 0}
                onClick={() => deleteAttachmentsLocally(file)}
              >
                <IconDelete
                  data-testid={`delete-file-button-${index}`}
                  className={classes.deleteIcon}
                />
              </IconButton>
            )}

        </Box>
      ))}
    </>
  );
};

export default UploadWidget;
