import React from 'react';
import PreviewFile from '../../components/PreviewFile';
import { Attachment as AttachmentType } from '../../schemas';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FileType } from '../../helpers/upload-download';

const Attachment = (): JSX.Element => {
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(useLocation().search);
  const filename = queryParams.get('filename');
  const location = queryParams.get('location');

  if (filename && location) {
    const fileToPreview: AttachmentType = {
      filename,
      location,
    };

    return (
      <div>{<PreviewFile fileType={FileType.ATTACHMENT} file={fileToPreview} />}</div>
    );
  }

  return <div data-testid="no-preview">{t('attachment.noPreview')}</div>;
};

export default Attachment;
