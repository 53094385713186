import React, { useContext, useEffect, useState, useCallback } from 'react';
import { formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { debounce } from 'lodash';

import Context, { InstallationContext } from '../../context';
import NavigationButtons from '../../components/NavigationButtons';
import {
  API_TYPE,
  fetchContacts,
  fetchEmployee,
  fetchSubcontractors,
  get,
  post,
  put,
} from '../../helpers/fetch';
import {
  ActivityDifferentiator,
  Assignment,
  Attachment,
  Contact,
  ContactRole,
  Deviation,
  DeviationStatus,
  DeviationVariation,
  Installation,
  InstallationStatus,
  InstallationSyncData,
  isDeviationOpen,
  SubcontractorRecord,
} from '../../schemas';
import { CONTAINER_HEIGHT } from '../NetworkInstallation';
import {
  generateIndexedDBKey,
  getIndexedDBObject,
  storedIndexedDBObjectType,
  truncateNetworkFromIndexedDB,
  upsertIndexedDBObject,
} from '../../helpers/indexedDB';
import { DeviationToSync, manualSyncFromUI } from '../../helpers/deviationOffline';

import HandoverStatusWithAnimation from '../../components/HandoverStatusWithAnimation';
import HandoverSupervisorSummary from '../../components/HandoverSupervisorSummary';
import NebSupervisorSignoff from '../../components/NebSupervisorSignoff';
import NonSupervisorSignoff from '../../components/NonSupervisorSignoff';
import SebSupervisorSignoff from '../../components/SebSupervisorSignoff';
import { InfoModal } from '@konecorp/ui-library';
import { getHandoverAssigneeUserRole } from '../../helpers/question';
import { uploadAttachments } from '../../helpers/upload-download';
import { useGetUserData } from '../../hooks/useGetUserData';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import NebSupervisorCompletion from '../../components/NebSupervisorCompletion';
import { useIfSEBSupervisorReadOnly } from '../../hooks/useIfSEBSupervisorReadOnly';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import { useGetToken } from '../../hooks/useGetToken';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { useIfReadOnly } from '../../hooks/useIfReadOnly';
import { clearAllSubcontractorRelatedFromLocalStorage } from '../../helpers/subContractorAuthentication';
import HandoverStatusWithSummary from '../../components/HandoverStatusWithSummary';
import { compareStatus, CompareStatusResult } from '../../helpers/getInstallationLists';

enum HandoverStatus {
  ON_TIME_NO_DEVIATIONS = 'onTimeNoDeviations',
  ON_TIME_WITH_DEVIATIONS = 'onTimeWithDeviations',
  LATE_NO_DEVIATIONS = 'lateNoDeviations',
  LATE_WITH_DEVIATIONS = 'lateWithDeviations',
  INVALID = 'invalid',
}

enum HandoverView {
  INSTALLATION_COMPLETE,
  NEB_SUPERVISOR_SIGNOFF,
  NON_SUPERVISOR_SIGNOFF,
  SEB_SUPERVISOR_SIGNOFF,
  STATUS_VIEW,
  SUPERVISOR_STATUS_VIEW,
}

export type SendHandoverParams = {
  comment: string;
  files: File[];
  newRecipientEmails?: string[];
  isSebSupervisorAccepted?: boolean;
};

type FetchedHandover = {
  comment: string | null;
  creator: string | null;
  modifiedAt: string;
  isSebSupervisorAccepted: boolean | null;
  role: string | null;
  attachments?: Attachment[];
};

type IndexedDBHandover = {
  commentText: string;
  //attachment in the future?
};

const findHandoverStatus = (
  deviations?: Deviation[],
  targetDate?: string | null
): HandoverStatus => {
  if (targetDate && deviations) {
    try {
      const parsedTargetDate = new Date(targetDate).setHours(0, 0, 0, 0);
      const currentDate = new Date().setHours(0, 0, 0, 0);

      const onSchedule = currentDate <= parsedTargetDate;
      const hasOpenDeviations = deviations.some(
        (deviation) => deviation.status === DeviationStatus.OPEN
      );

      if (onSchedule && !hasOpenDeviations) return HandoverStatus.ON_TIME_NO_DEVIATIONS;
      if (onSchedule && hasOpenDeviations) return HandoverStatus.ON_TIME_WITH_DEVIATIONS;
      if (!onSchedule && !hasOpenDeviations) return HandoverStatus.LATE_NO_DEVIATIONS;
      if (!onSchedule && hasOpenDeviations) return HandoverStatus.LATE_WITH_DEVIATIONS;
    } catch (e) {
      return HandoverStatus.INVALID;
    }
  }
  return HandoverStatus.INVALID;
};

export type HandoverProps = {
  networkNumber: string;
  questionSequenceNumber: string;
  onNavigateBackward: () => void;
  onNavigateForward?: () => void;
};

const Handover = (props: HandoverProps): JSX.Element => {
  const { t } = useTranslation();
  const noTesterName = t('handover.noTesterName');

  const { networkNumber, onNavigateBackward, onNavigateForward, questionSequenceNumber } =
    props;
  const { updateIsLoading, installationData, updateInstallationData } =
    useContext(Context);
  const { deviations } = useContext(InstallationContext);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [commentText, setCommentText] = useState<string>('');
  const [sebContacts, setSebContacts] = useState<Contact[]>([]);
  const [acceptedBySeb, setAcceptedBySeb] = useState<boolean | undefined>(undefined);
  const [handoverSuccess, setHandoverSuccess] = useState<boolean>(false);
  const [showOfflineModalBeforeHandover, setShowOfflineModalBeforeHandover] =
    useState<boolean>(false);
  const [handoverAssignee, setHandoverAssignee] = useState<string>(noTesterName);
  const [sebRecipientEmails, setSebRecipientEmails] = useState<string[]>([]);
  const [employeeId] = useGetUserData();
  const [userRole] = useGetCurrentUserRole();
  const [isSebSupervisorAccepted, setIsSebSupervisorAccepted] = useState<
    boolean | undefined
  >(undefined);
  const [isHandoverAllowed, setHandoverAllowed] = useState<boolean>(true);
  const [isReadOnlyMode] = useIfReadOnly();

  const [isSebSupervisorViewReadonly] = useIfSEBSupervisorReadOnly();
  const [getTokenFunction] = useGetToken();
  const [isSubcontractor] = useIfSubcontractor();

  useEffect(() => {
    const latestAssigneesSubcontractors = async () => {
      try {
        const accessToken = await getTokenFunction();
        const subcontractors = await fetchSubcontractors(networkNumber, accessToken);
        const latestInstallers = await get(
          `v1/installations/${networkNumber}/?getBy=networkNumber`,
          accessToken
        );
        updateInstallationData({
          ...installationData,
          assignees: latestInstallers.assignees,
          subcontractors: subcontractors,
        } as Installation);
      } catch (error) {
        console.error('Error fetching subcontractor data:', error);
      }
    };
    latestAssigneesSubcontractors();
  }, []);
  const getAssignmentDates = (
    currentEmployeeId: string,
    assignees?: Assignment[]
  ): [string | undefined, string | undefined] => {
    const currentAssignee = assignees?.find(
      (assignee) =>
        assignee.koneResourcePersonalNumber === currentEmployeeId &&
        assignee.activityDifferentiator === ActivityDifferentiator.INST
    );
    const startDate = currentAssignee?.assignmentStartDate;
    const targetDate = currentAssignee?.assignmentEndDate;
    return [startDate, targetDate];
  };

  const getSubcontractorDates = (
    subcontractors: SubcontractorRecord[]
  ): [string | undefined, string | undefined] => {
    const installerSubcontractor = subcontractors.find(
      (subcontractor) =>
        subcontractor.activityDifferentiator === ActivityDifferentiator.INST
    );

    const startDate = installerSubcontractor?.plannedStartDate;
    const targetDate = installerSubcontractor?.plannedEndDate;
    return [startDate, targetDate];
  };

  useEffect(() => {
    (async () => {
      const handoverDetailKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.HANDOVER_DETAIL
      );

      const enteredHandoverDetail = await getIndexedDBObject<IndexedDBHandover>(
        handoverDetailKey
      );

      setCommentText(enteredHandoverDetail?.commentText || '');
    })();

    //TODO: remove this view only logic, this is obsolete already, we don't send email login link for SEB supervisor anymore
    if (isSebSupervisorViewReadonly) {
      (async (): Promise<FetchedHandover | null> => {
        try {
          const accessToken = await getTokenFunction();

          const handoverData = await get(
            `v1/installations/${networkNumber}/handovers`,
            accessToken
          );

          // find last handover done by SPV
          const lastHandoverSPV = handoverData.reduce(
            (acc: FetchedHandover, handover: FetchedHandover) => {
              const isServiceEngineer = handover.role === ActivityDifferentiator.SEEN;
              const isLatest = handover.modifiedAt > acc.modifiedAt;
              if (isServiceEngineer && isLatest) return handover;
              return acc;
            }
          );

          setAcceptedBySeb(
            lastHandoverSPV.isSebSupervisorAccepted !== undefined
              ? lastHandoverSPV.isSebSupervisorAccepted
              : undefined
          );
          setCommentText(lastHandoverSPV.comment ?? '');
          setSelectedFiles(lastHandoverSPV.attachments ?? []);
        } catch (error) {
          console.error('error while fetching handover data', error);
        }
        return null;
      })();
    }
  }, []);

  /** Get assignee name */
  useEffect(() => {
    const getEmployeeName = async (employeeNumber: number): Promise<string> => {
      try {
        updateIsLoading(true);
        const accessToken = await getTokenFunction();

        const employeeData = await fetchEmployee(employeeNumber, accessToken);
        if (employeeData) {
          return `${employeeData.legalFirstName} ${employeeData.legalLastName}`;
        } else return noTesterName;
      } catch (e) {
        console.error('Could not retrieve employee name', e);
        return noTesterName;
      } finally {
        updateIsLoading(false);
      }
    };

    let isSubscribed = true; // prevent memory leak
    const handoverAssigneeUserRole = getHandoverAssigneeUserRole(
      installationData?.scenario,
      userRole
    );

    const handoverAssignee =
      handoverAssigneeUserRole === ActivityDifferentiator.CMSN
        ? installationData?.assignees?.find(
            (assignee) => assignee.activityDifferentiator === handoverAssigneeUserRole
          )
        : undefined;

    const handoverSubcontractor = installationData?.subcontractors.find(
      (subcontractor) => subcontractor.activityDifferentiator === handoverAssigneeUserRole
    );

    const supervisorNumber =
      handoverAssigneeUserRole === ActivityDifferentiator.SPV
        ? installationData?.supervisorNumber
        : undefined;

    if (supervisorNumber) {
      const employeeNumber = parseInt(supervisorNumber);
      const isValidEmployeeNumber = !isNaN(employeeNumber);
      if (isValidEmployeeNumber) {
        (async () => {
          const employeeName = await getEmployeeName(employeeNumber);
          if (isSubscribed) setHandoverAssignee(employeeName);
        })();
      }
    } else if (handoverAssignee) {
      const employeeNumber = parseInt(handoverAssignee.koneResourcePersonalNumber);
      const isValidEmployeeNumber = !isNaN(employeeNumber);

      if (isValidEmployeeNumber) {
        (async () => {
          const employeeName = await getEmployeeName(employeeNumber);
          if (isSubscribed) setHandoverAssignee(employeeName);
        })();
      }
    } else if (handoverSubcontractor) {
      const subcontractorName = handoverSubcontractor.subcontractor.name;
      if (isSubscribed) setHandoverAssignee(subcontractorName);
    }

    return () => {
      isSubscribed = false;
    };
  }, [installationData, t, updateIsLoading]);

  const upsertHandoverCommentDebounce = useCallback(
    debounce(async (newComment: string) => {
      const handoverDetailKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.HANDOVER_DETAIL
      );

      await upsertIndexedDBObject<IndexedDBHandover>(
        { commentText: newComment },
        handoverDetailKey
      );
    }, 500),
    []
  );

  const saveCommentText = (newComment: string): void => {
    setCommentText(newComment);
    upsertHandoverCommentDebounce(newComment);
  };

  const sendHandover = async (params: SendHandoverParams): Promise<void> => {
    const { comment, files, newRecipientEmails, isSebSupervisorAccepted } = params;

    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      const syncDataKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.SYNC_DATA
      );

      const deviationToSyncKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.DEVIATIONS_TO_SYNC
      );

      const syncDataFromIndexedDb = await getIndexedDBObject<InstallationSyncData>(
        syncDataKey
      );

      const deviationSyncDataFromIndexedDB = await getIndexedDBObject<DeviationToSync[]>(
        deviationToSyncKey
      );

      if (syncDataFromIndexedDb) {
        await put(
          `v1/installations/${networkNumber}/sync`,
          accessToken,
          API_TYPE.APPLICATION,
          { ...syncDataFromIndexedDb, userRole }
        );
      }

      if (deviationSyncDataFromIndexedDB?.length) {
        await manualSyncFromUI(networkNumber, accessToken);
      }

      const attachments = files.length
        ? await uploadAttachments({
            files,
            networkNumber,
            questionSequenceNumber,
            questionSetIdParam: 'HANDOVER',
            jwtToken: accessToken,
          })
        : [];
      const userHandoverDate = formatISO(new Date());

      const saveHandover = post(
        `v1/installations/${networkNumber}/handovers`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          comment,
          attachments,
          role: userRole,
          userHandoverDate,
          isSebSupervisorAccepted,
        }
      );

      await Promise.all([truncateNetworkFromIndexedDB(networkNumber), saveHandover]);

      setSebRecipientEmails([
        ...sebContacts.map((contact) => contact.email as string),
        ...(newRecipientEmails || []),
      ]);
      setHandoverSuccess(true);
    } catch (e) {
      console.error(e);
    } finally {
      updateIsLoading(false);
    }
  };

  const createSebContact = async (email: string): Promise<void> => {
    updateIsLoading(true);
    const accessToken = await getTokenFunction();

    put(`v1/installations/${networkNumber}/contacts`, accessToken, API_TYPE.APPLICATION, {
      email,
      role: ContactRole.SEB_SPV,
    });

    updateIsLoading(false);
  };

  const getQuestionsAndAnswers = (
    role: ActivityDifferentiator | undefined,
    installationData: Installation | null
  ) => {
    const isBadRole =
      role === undefined ||
      (role !== ActivityDifferentiator.CMSN && role !== ActivityDifferentiator.INST);

    if (installationData === null || isBadRole)
      return {
        questions: [],
        answers: [],
      };

    const questions =
      role === ActivityDifferentiator.INST
        ? installationData.installerQuestions
        : installationData.testerQuestions;
    const answers =
      role === ActivityDifferentiator.INST
        ? installationData.installerAnswers
        : installationData.testerAnswers;

    return { questions, answers };
  };

  const renderSupervisorStatusView = () => <HandoverSupervisorSummary />;

  const renderHandoverStatusView = (
    handoverStatus: HandoverStatus,
    installationData: Installation,
    plannedTargetDate?: string,
    plannedStartDate?: string
  ): JSX.Element => {
    /*
     * Don't show anything when deviations aren't fetched
     */
    if (deviations === undefined) return <></>;

    const params = {
      actualStartDate: installationData.actualDates?.actualInstallerStartDate,
      actualTargetDate:
        installationData.actualDates?.actualInstallerHandoverDate ||
        new Date(Date.now()).toISOString(),
      plannedStartDate,
      plannedTargetDate,
      deviations,
    };

    return handoverStatus === HandoverStatus.ON_TIME_NO_DEVIATIONS ? (
      <HandoverStatusWithAnimation {...params} />
    ) : (
      <HandoverStatusWithSummary {...params} />
    );
  };

  const renderSebQualityReview = (deviations: Deviation[]): JSX.Element => {
    const rejectionsCount = deviations
      .filter(isDeviationOpen)
      .filter((deviation) => deviation.variation === DeviationVariation.REJECT).length;

    return (
      <SebSupervisorSignoff
        commentText={commentText}
        rejectionsCount={rejectionsCount}
        selectedFiles={selectedFiles}
        setCommentText={saveCommentText}
        setIsSebSupervisorAccepted={setIsSebSupervisorAccepted}
        deleteAttachmentsLocally={deleteAttachmentsLocally}
        handleUploadButton={handleUploadButton}
        acceptedBySeb={acceptedBySeb}
      />
    );
  };

  const renderNebSupervisorCompletion = (
    installationData: Installation,
    isReadOnly: boolean
  ): JSX.Element => {
    const isAccepted = installationData.status === InstallationStatus.SEB_ACCEPTED;

    return (
      <NebSupervisorCompletion
        commentText={commentText}
        deleteAttachmentsLocally={deleteAttachmentsLocally}
        handleUploadButton={handleUploadButton}
        isAccepted={isAccepted}
        selectedFiles={selectedFiles}
        sendHandover={sendHandover}
        setCommentText={saveCommentText}
        isReadOnly={isReadOnly}
      />
    );
  };

  const [installerPlannedStartDate, installerPlannedTargetDate] = isSubcontractor
    ? getSubcontractorDates(installationData?.subcontractors ?? [])
    : getAssignmentDates(employeeId, installationData?.assignees);

  const deleteAttachmentsLocally = (file: File | Attachment) => {
    setSelectedFiles(selectedFiles.filter((f) => f !== file));
  };

  const handleUploadButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  const isSignoffQuestion = !onNavigateForward;

  const renderNonSupervisorSignoffView = (handoverAssignee: string): JSX.Element => {
    return (
      <NonSupervisorSignoff
        installation={installationData}
        commentText={commentText}
        deviations={deviations}
        handoverAssignee={handoverAssignee}
        selectedFiles={selectedFiles}
        deleteAttachmentsLocally={deleteAttachmentsLocally}
        handleUploadButton={handleUploadButton}
        setCommentText={saveCommentText}
        setHandoverAllowed={setHandoverAllowed}
      />
    );
  };

  const renderSupervisorSignoffView = (isReadOnly: boolean): JSX.Element => {
    return (
      <NebSupervisorSignoff
        deviations={deviations}
        questionsAndAnswers={getQuestionsAndAnswers(
          ActivityDifferentiator.CMSN,
          installationData
        )}
        selectedFiles={selectedFiles}
        sebContacts={sebContacts}
        deleteAttachmentsLocally={deleteAttachmentsLocally}
        handleUploadButton={handleUploadButton}
        sendToSeb={sendHandover}
        createSebContact={createSebContact}
        commentText={commentText}
        setCommentText={saveCommentText}
        isReadOnly={isReadOnly}
      />
    );
  };

  /**
   * if onNavigateForward prop is missing then it is last handover question (end of flow).
   * And the last question should always render the signoff question.
   */
  const isSupervisor = userRole === ActivityDifferentiator.SPV;
  const isServiceEngineer = userRole === ActivityDifferentiator.SEEN;
  const isInstallationCompleted =
    compareStatus(
      installationData?.status || InstallationStatus.TO_BE_STARTED,
      InstallationStatus.FOR_SEB_ACCEPTANCE
    ) === CompareStatusResult.AFTER;

  installationData?.status === InstallationStatus.SEB_ACCEPTED ||
    installationData?.status === InstallationStatus.SEB_REJECTED;

  const handoverStatus = findHandoverStatus(deviations, installerPlannedTargetDate);

  const backwardButtonText = t('qdPage.gobackButton');
  const forwardButtonText = isSignoffQuestion
    ? t('handoverSignoffPage.handoverButton')
    : t('qdPage.continueButton');

  const isSupervisorHandover = isSignoffQuestion && isSupervisor;

  const handoverView: HandoverView = isSignoffQuestion
    ? isServiceEngineer
      ? HandoverView.SEB_SUPERVISOR_SIGNOFF
      : isSupervisor
      ? isInstallationCompleted
        ? HandoverView.INSTALLATION_COMPLETE
        : HandoverView.NEB_SUPERVISOR_SIGNOFF
      : HandoverView.NON_SUPERVISOR_SIGNOFF
    : isSupervisor
    ? HandoverView.SUPERVISOR_STATUS_VIEW
    : HandoverView.STATUS_VIEW;

  const isNotSummaryHandoverView =
    handoverView !== HandoverView.SUPERVISOR_STATUS_VIEW &&
    handoverView !== HandoverView.STATUS_VIEW;

  const isMovingForwardDisabled =
    !isHandoverAllowed || (isReadOnlyMode && isNotSummaryHandoverView);

  useEffect(() => {
    const fetchSebContacts = async () => {
      const accessToken = await getTokenFunction();
      const contacts = await fetchContacts(networkNumber, accessToken);
      const sebContacts = contacts.filter(
        (contact) => contact.role === ContactRole.SEB_SPV && contact.email
      );
      setSebContacts(sebContacts);
    };

    (async () => {
      if (isSupervisor) {
        await fetchSebContacts();
      }
    })();
  }, []);

  const [isOnline] = useCheckConnection();

  const onClickForward = isSupervisorHandover
    ? undefined
    : async () => {
        if (onNavigateForward) {
          onNavigateForward();
        } else {
          if (isOnline) {
            if (isServiceEngineer && isSebSupervisorAccepted === undefined) return;
            await sendHandover({
              comment: commentText,
              files: selectedFiles,
              isSebSupervisorAccepted,
            });
          } else {
            setShowOfflineModalBeforeHandover(true);
          }
        }
      };

  const handoverMessage = (isSupervisor: boolean) =>
    isSupervisor
      ? t('nebSupervisorSignoff.handoverComplete', {
          email: sebRecipientEmails.join('\n'),
        })
      : t('handover.complete', { handoverAssignee: handoverAssignee });

  if (!installationData) return <p>Error: No installation data. Please reload.</p>;

  const hideOfflineModal = () => {
    setShowOfflineModalBeforeHandover(false);
  };

  const onHandoverSuccessModalClose = () => {
    if (isSubcontractor) {
      clearAllSubcontractorRelatedFromLocalStorage(networkNumber);
      window.location.replace(`/subcontractor/completed/${networkNumber}`);
    } else window.location.replace('/');
  };

  return (
    <Box height={CONTAINER_HEIGHT} overflow="auto" p={3} data-testid="handover-container">
      <Box height="100%" display="flex" flexDirection="column">
        <Box flexGrow="1">
          {handoverView === HandoverView.NEB_SUPERVISOR_SIGNOFF &&
            renderSupervisorSignoffView(isReadOnlyMode)}
          {handoverView === HandoverView.NON_SUPERVISOR_SIGNOFF &&
            renderNonSupervisorSignoffView(handoverAssignee)}
          {handoverView === HandoverView.SEB_SUPERVISOR_SIGNOFF &&
            renderSebQualityReview(deviations)}
          {handoverView === HandoverView.STATUS_VIEW &&
            renderHandoverStatusView(
              handoverStatus,
              installationData,
              installerPlannedTargetDate,
              installerPlannedStartDate
            )}
          {handoverView === HandoverView.SUPERVISOR_STATUS_VIEW &&
            renderSupervisorStatusView()}
          {handoverView === HandoverView.INSTALLATION_COMPLETE &&
            renderNebSupervisorCompletion(installationData, isReadOnlyMode)}
        </Box>
        <Box display="flex" alignItems="flex-end" mb={5.25} mt={2.25} pb={11}>
          <NavigationButtons
            onClickBackward={onNavigateBackward}
            onClickForward={onClickForward}
            forwardButtonText={forwardButtonText}
            backwardButtonText={backwardButtonText}
            forwardDisabled={isMovingForwardDisabled}
          />
        </Box>
      </Box>

      <InfoModal
        open={showOfflineModalBeforeHandover}
        message={t('connection.offlineHandover')}
        onClose={hideOfflineModal}
        closeButtonText={t('supervisorNewInstallation.OK')}
        isCenteredMessage
      />

      {handoverSuccess && (
        <InfoModal
          open={true}
          message={handoverMessage(isSupervisor)}
          onClose={onHandoverSuccessModalClose}
          closeButtonText={t('supervisorNewInstallation.OK')}
          isCenteredMessage
        />
      )}
    </Box>
  );
};

export default Handover;
