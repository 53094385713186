import { useContext } from 'react';

import { Answer } from '../schemas';
import Context from '../context/index';
import { getAnswerByTag } from '../helpers/question';

type getFloorOrientationFunction = (questionSetId: string) => FloorOrientation;

export enum FloorOrientation {
  TOP_TO_BOTTOM = 'top-to-bottom', //value is from TACO
  BOTTOM_TO_TOP = 'bottom-to-top', //value is from TACO
  UNKNOW = 'unknow',
}

const PLUMBING_QUESTIONSET_ID = 'TACO01001';
const LANDING_DOOR_QUESTIONSET_ID = 'TACO01003';

const PLUMBING_ORIENTATION_QUESTION_TAG = 'tag_gate_plumbing_mrl_0004';
const LANDING_DOOR_ORIENTATION_QUESTION_TAG = 'tag_gate_ldo_mrl_0022';

const getFloorOrientation = (answer: Answer | null): FloorOrientation => {
  switch(answer?.value) {
    case FloorOrientation.TOP_TO_BOTTOM:
       return FloorOrientation.TOP_TO_BOTTOM;

    case FloorOrientation.BOTTOM_TO_TOP:
        return FloorOrientation.BOTTOM_TO_TOP;

    default:
     return FloorOrientation.UNKNOW
  }
};

export const useGetFloorOrientation = (): [getFloorOrientationFunction] => {
  const { installationData } = useContext(Context);
  const { installerAnswers, testerAnswers } = installationData || {};

  const allAnswerSets = [...(installerAnswers ?? []), ...(testerAnswers ?? [])];

  const getFunction = (questionSetId: string): FloorOrientation => {
    switch (questionSetId) {
      case PLUMBING_QUESTIONSET_ID: {
        const plumbingOrientationQuestion = getAnswerByTag(
          PLUMBING_ORIENTATION_QUESTION_TAG,
          allAnswerSets
        );

        return getFloorOrientation(plumbingOrientationQuestion);
      }

      case LANDING_DOOR_QUESTIONSET_ID: {
        const landingOrientationQuestion = getAnswerByTag(
          LANDING_DOOR_ORIENTATION_QUESTION_TAG,
          allAnswerSets
        );

        return getFloorOrientation(landingOrientationQuestion);
      }

      default:
        return FloorOrientation.UNKNOW;
    }
  };

  return [getFunction];
};
