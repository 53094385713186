export const generateTokenLocalStorageKey = (networkNumber: string): string =>
  `${networkNumber}_guid_token`;

export const generateUserRoleLocalStorageKey = (networkNumber: string): string =>
  `${networkNumber}_guid_user_role`;

/* this might look such a simple function, but we are trying to mimic how acquireToken
   works with AzureAD (which include refresh token, redirect to login etc). With our
   subcontractor flow, the checking and redirect if not authorized is hanlde in the useEffect
   of SubcontractorApp containers
*/
export const subcontractorAcquireToken = (networkNumber: string): string | null => {
  const keyToGet = generateTokenLocalStorageKey(networkNumber);
  return window.localStorage.getItem(keyToGet);
};

export const getUserRoleFromStorage = (networkNumber: string): string | null => {
  const keyToGet = generateUserRoleLocalStorageKey(networkNumber);
  return window.localStorage.getItem(keyToGet);
};

export const clearAllSubcontractorRelatedFromLocalStorage = (
  networkNumber: string
): void => {
  const tokenKey = generateTokenLocalStorageKey(networkNumber);
  const roleKey = generateUserRoleLocalStorageKey(networkNumber);

  window.localStorage.removeItem(tokenKey);
  window.localStorage.removeItem(roleKey);
};
