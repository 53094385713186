export const SUPPORTED_IMAGE_FILE_TYPES = [
  'png',
  'bmp',
  'exr',
  'gif',
  'heic',
  'heif',
  'jpg',
  'jpeg',
  'psd',
  'sgi',
  'tiff',
  'tga',
  'pdf',
  'webp',
];
export const SUPPORTED_VIDEO_FILE_TYPES = [
  '3gp',
  'aac',
  'amr',
  'avi',
  'mp4',
  'flac',
  'imy',
  'm2ts',
  'm4a',
  'mid',
  'mov',
  'mkv',
  'mts',
  'mxf',
  'mxmf',
  'ogg',
  'ota',
  'rtttl',
  'rtx',
  'ts',
  'wav',
  'webm',
  'xmf',
];
export const SUPPORTED_AUDIO_FILE_TYPES = ['aaif', 'caf', 'mp3'];

export const getFileTypesRegxString = (fileTypes: string[]): string => {
  return fileTypes.join('|');
};
