import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Grid, Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { InfoModal } from '@konecorp/ui-library';

import Context from '../../context';
import { API_TYPE, fetchEmployeeFullName, get, put } from '../../helpers/fetch';
import {
  ActivityDifferentiator,
  Assignment,
  Handover,
  InstallationStatus,
  SubcontractorRecord,
} from '../../schemas';
import { truncateNetworkFromIndexedDB } from '../../helpers/indexedDB';
import { useGetToken } from '../../hooks/useGetToken';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { useGetUserData } from '../../hooks/useGetUserData';
import InstallationStartingInstaller from '../../components/InstallationStartingInstaller';
import InstallationStartingTester from '../../components/InstallationStartingTester';
import { formatDate } from '../../helpers/formating';

enum InfoModalType {
  NONE,
  IS_OFFLINE,
  CONFIRMATION,
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(5),
      maxWidth: '100vw',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& button': {
        marginTop: theme.spacing(5),
      },
    },
    upperCase: {
      textTransform: 'uppercase',
    },
    divider: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    additionalInfoLinks: {
      marginTop: theme.spacing(3),
      textDecoration: 'underline',
    },
  })
);

const InstallationStarting = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOnline] = useCheckConnection();
  const [getTokenFunction] = useGetToken();
  const [role] = useGetCurrentUserRole();
  const { updateIsLoading, networkNumber, installationData } = useContext(Context);

  const [dialogType, setDialogType] = useState<InfoModalType>(InfoModalType.NONE);
  const [allowStart, setAllowStart] = useState<boolean>(false);
  const [supervisorFullName, setSupervisorFullName] = useState<string>('');
  const [handover, setHandover] = useState<Handover | null>(null);
  const [isSubcontractor] = useIfSubcontractor();
  const [employeeId] = useGetUserData();

  const nextStatus = (() => {
    switch (role) {
      case ActivityDifferentiator.INST:
        return InstallationStatus.INSTALLER_ACCEPTED;
      case ActivityDifferentiator.CMSN:
        return InstallationStatus.TESTER_ACCEPTED;
      default:
        return undefined;
    }
  })();

  const isInstaller = role === ActivityDifferentiator.INST;
  const isTester = role === ActivityDifferentiator.CMSN;

  useEffect(() => {
    const fetchSupervisorFullName = async (accessToken: string): Promise<string> => {
      return installationData?.supervisorNumber
        ? await fetchEmployeeFullName(installationData.supervisorNumber, accessToken)
        : '';
    };

    const fetchHandoverData = async (accessToken: string): Promise<Handover | null> => {
      try {
        return await get(
          `v1/installations/${networkNumber}/handovers/latest`,
          accessToken
        );
      } catch (error) {
        console.error('error while fetching handover data', error);
      }
      return null;
    };

    (async () => {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      if (isInstaller || isTester) {
        const supervisorFullName = await fetchSupervisorFullName(accessToken);
        setSupervisorFullName(supervisorFullName);
      }

      if (isTester) {
        const handover = await fetchHandoverData(accessToken);
        setHandover(handover);
      }

      updateIsLoading(false);
    })();
  }, [installationData]);

  const openInstallationExecution = () => {
    // hard reload the app so when the app go to /execution it has a clean context
    // and trigger the caching again
    if (isSubcontractor) {
      window.location.replace(`/subcontractor/${networkNumber}/execution`);
    } else {
      window.location.replace(`/${networkNumber}/execution`);
    }
  };

  const onStartingInstallation = async () => {
    try {
      if (!isOnline) {
        setDialogType(InfoModalType.IS_OFFLINE);
        return;
      }

      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await put(
        `v1/installations/${networkNumber}/status`,
        accessToken,
        API_TYPE.APPLICATION,
        { status: nextStatus }
      );

      await truncateNetworkFromIndexedDB(networkNumber);

      switch (role) {
        case ActivityDifferentiator.INST:
          setDialogType(InfoModalType.CONFIRMATION);
          break;
        case ActivityDifferentiator.CMSN:
          openInstallationExecution();
          break;
      }
    } catch (e) {
      console.error('Error when trying to start an installation, error: ', e);
    } finally {
      updateIsLoading(false);
    }
  };

  const getDialogMessage = (): string => {
    if (dialogType === InfoModalType.IS_OFFLINE) {
      return t('installationStarting.onlyOnline');
    } else {
      return t('installationStarting.networkStartedMessageWithSupervisorFullName', {
        supervisorFullName: supervisorFullName,
      });
    }
  };

  const onDialogClose = (): void => {
    if (dialogType === InfoModalType.IS_OFFLINE) {
      setDialogType(InfoModalType.NONE);
    } else {
      openInstallationExecution();
    }
  };

  if (!installationData || !role) return <></>;

  const { assignees, subcontractors } = installationData;

  const ContactInfo = () => {
    const { customer } = installationData;

    return (
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('installationStarting.network')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{networkNumber}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('installationStarting.supervisor')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{supervisorFullName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('installationStarting.location')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{customer?.customerName1}</Typography>
          <Typography>{customer?.customerName2}</Typography>
          <Typography>
            {customer?.street} {customer?.houseNumber}
          </Typography>
          <Typography>
            {customer?.cityPostalCode} {customer?.city}, {customer?.countryKey}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getSubcontractorDates = (
    subcontractorRecords: SubcontractorRecord[],
    role: ActivityDifferentiator
  ) => {
    const subcontractor = subcontractorRecords.find(
      (subcontractor) => subcontractor.activityDifferentiator === role
    );

    const startDate = formatDate(subcontractor?.plannedStartDate);
    const targetDate = formatDate(subcontractor?.plannedEndDate);
    return [startDate, targetDate];
  };

  const getAssignmentDates = (
    currentEmployeeId: string,
    role: ActivityDifferentiator,
    assignees?: Assignment[]
  ) => {
    const currentAssignee = assignees?.find(
      (assignee) =>
        assignee.koneResourcePersonalNumber === currentEmployeeId &&
        assignee.activityDifferentiator === role
    );
    const startDate = formatDate(currentAssignee?.assignmentStartDate);
    const targetDate = formatDate(currentAssignee?.assignmentEndDate);
    return [startDate, targetDate];
  };

  const InstallationInfo = () => {
    const [startDate, targetDate] =
      role && isSubcontractor
        ? getSubcontractorDates(subcontractors ?? [], role)
        : getAssignmentDates(employeeId, role, assignees);

    const installerTotalHours = {
      message: t('installationStarting.installationHours'),
      value: Math.ceil(Number(installationData.totalInstallationHours)),
    };

    const testerTotalHours = {
      message: t('installationStarting.testingHours'),
      value: Math.ceil(Number(installationData.testerHours)),
    };

    const totalHours = isInstaller ? installerTotalHours : testerTotalHours;

    return (
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('installationStarting.startDate')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography data-testid="start-date"> {startDate ?? ''}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('installationStarting.targetDate')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography data-testid="target-date"> {targetDate ?? ''}</Typography>
        </Grid>
        {!isSubcontractor && (
          <>
            <Grid item xs={6}>
              <Typography className={classes.upperCase}>{totalHours.message}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid="total-hours">{totalHours.value}</Typography>
            </Grid>
          </>
        )}

        <Grid container className={classes.additionalInfoLinks}>
          <Grid item xs={6}>
            {!isSubcontractor && (
              <Typography>
                <Link
                  href={`${process.env.REACT_APP_TRACE_PDM_URL}/equipment/${installationData.equipmentNumber}`}
                >
                  {t('installationStarting.openTracePDM')}
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className={classes.root}>
      {(isInstaller || isTester) && (
        <>
          <ContactInfo />
          <Divider className={classes.divider} />
          <InstallationInfo />
          <Divider className={classes.divider} />
        </>
      )}

      {isInstaller && <InstallationStartingInstaller setAllowStart={setAllowStart} />}

      {isTester && (
        <InstallationStartingTester handover={handover} setAllowStart={setAllowStart} />
      )}

      <Button
        size="large"
        variant="contained"
        color="primary"
        disabled={!allowStart}
        onClick={onStartingInstallation}
        data-testid="start-button"
      >
        {t('installationStarting.start')}
      </Button>

      <InfoModal
        closeButtonText={t('installationStarting.ok')}
        message={getDialogMessage()}
        open={
          dialogType === InfoModalType.CONFIRMATION ||
          dialogType === InfoModalType.IS_OFFLINE
        }
        onClose={onDialogClose}
        isCenteredMessage
      />
    </Box>
  );
};

export default InstallationStarting;
