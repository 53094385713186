import { i18n } from 'i18next';

import {
  ActivityDifferentiator,
  Answer,
  AnswerSet,
  Deviation,
  DeviationStatus,
  FormId,
  Installation,
  InstallationStatus,
  isDeviationOpen,
  Question,
  QuestionSet,
  QuestionSetInfo,
  QuestionType,
  Scenario,
} from '../schemas';
import { getLocalizedText } from './custom-i18n';

export type QuestionSetSummary = {
  setId: string;
  description: string;
  isStarted: boolean;
  isCompleted: boolean;
  hasDeviations: boolean;
  modifiedAt?: string;
};

const emptyQuestion: Question = {
  quantityRule: '',
  position: null,
  reversedPosition: null,
  translationKey: null,
  actionIfDifferent: [],
  modelCondition: null,
  actualValue: '',
  positiveTolerance: null,
  imageLink: null,
  condition: null,
  valueRule: null,
  valueType: null,
  negativeTolerance: null,
  additionalInfo: [],
  tag: '',
  text: [],
  questionType: null,
};

export const getAnswerByTag = (tag: string, answerSets: AnswerSet[]): Answer | null => {
  for (const answerSet of answerSets) {
    const matchedAnswer = answerSet.answers.find(
      (answer) => answer?.gsi1.split('#')[0] === tag
    );
    if (matchedAnswer) return matchedAnswer;
  }
  return null;
};

export const skipQuestion = (
  condition: string | null,
  answerSet: AnswerSet[]
): boolean => {
  // example of condition string: TYP_PRODUCT_RELEASE=\"5.0\",\"5\" and (answer (tag_gate_plumbing_mrl_0001)=\"A\")
  const regexMatched = condition?.match(/\(answer .*/);
  // turns into ['(answer (tag_gate_plumbing_mrl_0001)=\"A\")']
  if (regexMatched) {
    const tagAndValue = regexMatched[0].replace(/answer|[()"\s]/gi, '');
    // turns into 'tag_gate_plumbing_mrl_0001=A'
    const [tag, value] = tagAndValue.split('=');
    const matchedAnswer = getAnswerByTag(tag, answerSet);
    return matchedAnswer?.value !== value;
  }
  return false;
};

export const getHandoverAssigneeUserRole = (
  scenario?: Scenario,
  activityDifferentiator?: ActivityDifferentiator
): ActivityDifferentiator | undefined => {
  switch (activityDifferentiator) {
    case ActivityDifferentiator.INST:
      return scenario === Scenario.INSTALLER_SUPERVISOR
        ? ActivityDifferentiator.SPV
        : ActivityDifferentiator.CMSN;
    case ActivityDifferentiator.CMSN:
      return ActivityDifferentiator.SPV;
    case ActivityDifferentiator.SPV:
      return ActivityDifferentiator.SEEN;
    case ActivityDifferentiator.SEEN:
      return ActivityDifferentiator.SPV;

    default:
      return; // userRole will only be missing in case of bad data.
  }
};

type QuestionSetId = string;
type QuestionSetDescriptionText = string;

/**
 * Represents a filter function to remove unnecessary answers.
 * @param {string} questionSetId - specific question set Id.
 * @param {QuestionSet[]} questionSetArray - Full questions set.
 * @param {AnswerSet[]} answerSetArray - Full answers set.
 * @description It returns all the answers for non-dummy questions AND
 * skipped the non-laser OR non-wire answers based route answer.
 * Example - If route answer is 'laser' or 'wire', then this function
 * will return all non-wire(lasers) or non-laser(wire) answers respectively, with non-dummy answers.
 * @returns {Answer[] | null[]}
 */
export const getAnswersWithoutUnnecessaryAnswers = (
  questionSetId: QuestionSetId,
  questionSetArray: QuestionSet[],
  answerSetArray: AnswerSet[]
): (Answer | null)[] => {
  const questionSet = questionSetArray.find((set) => set.questionSetId === questionSetId);
  if (questionSet === undefined)
    throw `Question set id ${questionSetId} not found in question set array`;
  const answerSet = answerSetArray.find((set) => set.questionSetId === questionSetId);
  if (answerSet === undefined) return [];

  const answers = questionSet.questions.reduce<(Answer | null)[]>((ans, question, i) => {
    // Remove dummy answers to dummy questions, which are always null.
    const isDummyQuestion = question.questionType === QuestionType.DUMMY;
    // Remove non-laser OR non-wire answers based route answer
    const isSkippedQuestion = skipQuestion(question.condition, answerSetArray);
    return isDummyQuestion || isSkippedQuestion ? ans : [...ans, answerSet.answers[i]];
  }, []) as (Answer | null)[];

  return answers;
};

/** Return true if any question in the question set has a corresponding answer */
export const isQuestionSetStarted = (
  questionSetId: QuestionSetId,
  questionSetArray: QuestionSet[],
  answerSetArray: AnswerSet[]
): boolean => {
  const answers = getAnswersWithoutUnnecessaryAnswers(
    questionSetId,
    questionSetArray,
    answerSetArray
  );
  if (answers.length === 0)
    throw `Answer set id ${questionSetId} not found in answer set array`;

  // A question is answered if:
  // - the answer is not null
  // - the answer.value field is not null
  const isAnyQuestionAnswered = answers.some(
    (answer) => answer !== null && answer.value !== null
  );

  return isAnyQuestionAnswered;
};

/** Return true if each question in the question set has a corresponding answer */
export const isQuestionSetAnswered = (
  questionSetId: QuestionSetId,
  questionSetArray: QuestionSet[],
  answerSetArray: AnswerSet[]
): boolean => {
  const answers = getAnswersWithoutUnnecessaryAnswers(
    questionSetId,
    questionSetArray,
    answerSetArray
  );
  if (answers.length === 0)
    console.warn(`Answer set id ${questionSetId} not found in answer set array`);

  // A question is answered if:
  // - the answer is not null
  // - the answer.value field is not null
  const isEveryQuestionAnswered = answers.every(
    (answer) => answer !== null && answer.value !== null
  );

  return isEveryQuestionAnswered;
};

/** Return a question set description text in the form of a [questionSetId, descriptionText] tuple
 * If the questionSetInfoArray is empty or does not contain the
 * questionSetId then [questionSetId, questionSetId] is returned
 * @param questionSetId the question set id
 * @param questionSetInfoArray array of question set info that contains the question set id
 * @param languageCode two-letter language code of the target description text
 */
export const getQuestionSetDescription = (
  questionSetId: QuestionSetId,
  questionSetInfoArray: QuestionSetInfo[] | null | undefined,
  i18n: i18n
): [QuestionSetId, QuestionSetDescriptionText] => {
  const questionInfo = questionSetInfoArray?.find((set) => set.setId === questionSetId);
  if (!questionInfo) return [questionSetId, questionSetId];
  const { setId, description } = questionInfo;
  const descriptionText = getLocalizedText(i18n, description)?.text ?? questionSetId;
  return [setId, descriptionText];
};

export const getQuestionSetSummary = (
  role: ActivityDifferentiator,
  installation: Installation | null,
  deviations: Deviation[],
  i18n: i18n
): QuestionSetSummary[] => {
  if (!installation) return [];
  if (role !== ActivityDifferentiator.INST && role !== ActivityDifferentiator.CMSN)
    return [];

  const questionSetArray =
    role === ActivityDifferentiator.INST
      ? installation.installerQuestions
      : installation.testerQuestions;
  const answerSetArray =
    role === ActivityDifferentiator.INST
      ? installation.installerAnswers
      : installation.testerAnswers;

  return questionSetArray
    .filter((questionSet: QuestionSet): boolean =>
      questionSet.questions.some(
        // filter out HANDOVER-related question sets
        (question) => question.questionType !== QuestionType.DUMMY
      )
    )
    .map((set) => set.questionSetId)
    .map((questionSetId) =>
      getQuestionSetDescription(questionSetId, installation.questionSetInfo, i18n)
    )
    .map(([questionSetId, description]) => ({
      setId: questionSetId,
      description,
      isStarted: isQuestionSetStarted(questionSetId, questionSetArray, answerSetArray),
      isCompleted: isQuestionSetAnswered(questionSetId, questionSetArray, answerSetArray),
      hasDeviations: hasOpenDeviationsWithQuestionSetId(questionSetId, deviations),
      modifiedAt: getLatestAnswerModifiedAt(
        questionSetId,
        questionSetArray,
        answerSetArray
      ),
    }));
};

/** Add final HANDOVER step and questions to installation data
 * HANDOVER will be the last step. */
export const addHandoverStep = (installationData: Installation): Installation => {
  const {
    installerAnswers,
    installerQuestionSetSequence,
    installerQuestions,
    nebSupervisorQuestionSetSequence,
    nebSupervisorQuestions,
    questionSetInfo,
    sebSupervisorQuestionSetSequence,
    sebSupervisorQuestions,
    status,
    testerAnswers,
    testerQuestionSetSequence,
    testerQuestions,
  } = installationData;

  // Check for invalid data
  const requiredFields = [
    'installerAnswers',
    'installerQuestionSetSequence',
    'installerQuestions',
    'nebSupervisorQuestionSetSequence',
    'sebSupervisorQuestionSetSequence',
    'nebSupervisorQuestions',
    'sebSupervisorQuestions',
    'questionSetInfo',
    'testerAnswers',
    'testerQuestionSetSequence',
    'testerQuestions',
  ];

  Object.entries(installationData).forEach(([key, value]) => {
    const isRequired = requiredFields.includes(key);
    if (isRequired && !value) {
      throw new Error(
        `Bad data: The ${key} field in the network installation data is ${value}`
      );
    }
  });

  const handoverDummyQuestion1: Question = {
    ...emptyQuestion,
    tag: 'tag_handover_dummy_0001',
    questionType: QuestionType.DUMMY,
  };

  const handoverDummyQuestion2: Question = {
    ...emptyQuestion,
    tag: 'tag_handover_dummy_0002',
    questionType: QuestionType.DUMMY,
  };

  const previewHandoverDummyQuestion: Question = {
    ...emptyQuestion,
    tag: 'tag_preview-handover_dummy_0001',
    questionType: QuestionType.DUMMY,
  };

  const handoverQuestionSet: QuestionSet = {
    questionSetId: 'HANDOVER',
    questions: [handoverDummyQuestion2],
  };

  const installerHandoverQuestionSet: QuestionSet = {
    questionSetId: 'HANDOVER',
    questions: [handoverDummyQuestion1, handoverDummyQuestion2],
  };

  const handoverQuestionSetInfo: QuestionSetInfo = {
    setId: 'HANDOVER',
    formId: FormId.QDPLUS,
    description: [
      {
        code: 'en',
        text: 'Handover',
      },
    ],
  };

  const previewHandoverQuestionSetInfo: QuestionSetInfo = {
    setId: 'PREVIEW_HANDOVER',
    formId: FormId.QDPLUS,
    description: [
      {
        code: 'en',
        text: 'Handover to Me',
      },
    ],
  };

  const previewHandoverQuestionSet: QuestionSet = {
    questionSetId: 'PREVIEW_HANDOVER',
    questions: [previewHandoverDummyQuestion],
  };

  /** After SEB handover, NEB needs only one handover question in the
   * set to show the Installation Complete page */
  const isInstallationCompleted =
    status === InstallationStatus.SEB_ACCEPTED ||
    status === InstallationStatus.SEB_REJECTED;

  const nebSupervisorHandoverQuestionSet: QuestionSet = {
    questionSetId: 'HANDOVER',
    questions: isInstallationCompleted
      ? [handoverDummyQuestion1]
      : [handoverDummyQuestion1, handoverDummyQuestion2],
  };

  const sebSupervisorHandoverQuestionSet: QuestionSet = {
    questionSetId: 'HANDOVER',
    questions: [handoverDummyQuestion1],
  };

  const newQuestionSetInfo: QuestionSetInfo[] | null = questionSetInfo
    ? [...questionSetInfo, handoverQuestionSetInfo, previewHandoverQuestionSetInfo]
    : null;

  const modifiedInstallationData: Installation = {
    ...installationData,
    questionSetInfo: newQuestionSetInfo,
    testerQuestionSetSequence: [
      'PREVIEW_HANDOVER',
      ...testerQuestionSetSequence,
      'HANDOVER',
    ],
    installerQuestionSetSequence: [...installerQuestionSetSequence, 'HANDOVER'],
    nebSupervisorQuestionSetSequence: [
      'PREVIEW_HANDOVER',
      ...(nebSupervisorQuestionSetSequence ?? []),
      'HANDOVER',
    ],
    sebSupervisorQuestionSetSequence: [
      'PREVIEW_HANDOVER',
      ...sebSupervisorQuestionSetSequence,
      'HANDOVER',
    ],
    testerQuestions: [
      previewHandoverQuestionSet,
      ...testerQuestions,
      handoverQuestionSet,
    ],
    testerAnswers: [
      { questionSetId: 'PREVIEW_HANDOVER', answers: [null] },
      ...testerAnswers,
      { questionSetId: 'HANDOVER', answers: [null] },
    ],
    installerAnswers: [
      ...installerAnswers,
      { questionSetId: 'HANDOVER', answers: [null, null] },
    ],
    installerQuestions: [...installerQuestions, installerHandoverQuestionSet],
    nebSupervisorQuestions: [
      previewHandoverQuestionSet,
      ...nebSupervisorQuestions,
      nebSupervisorHandoverQuestionSet,
    ],
    sebSupervisorQuestions: [
      previewHandoverQuestionSet,
      ...sebSupervisorQuestions,
      sebSupervisorHandoverQuestionSet,
    ],
  };

  return modifiedInstallationData;
};

export const hasOpenDeviationsWithQuestionSetId = (
  questionSetId: string,
  deviations: Deviation[]
): boolean =>
  deviations
    .filter(isDeviationOpen)
    .some((deviation) => deviation.questionSetId === questionSetId);

export const getOpenDeviationsCount = (deviations?: Deviation[]): number =>
  deviations
    ? deviations.filter((deviation) => deviation.status === DeviationStatus.OPEN).length
    : 0;

export const getUnansweredQuestionsCount = (questionsAndAnswers: {
  questions: QuestionSet[];
  answers: AnswerSet[];
}): number => {
  const { questions, answers } = questionsAndAnswers;
  const unansweredCount = questions
    .flatMap((set) =>
      getAnswersWithoutUnnecessaryAnswers(set.questionSetId, questions, answers)
    )
    .reduce<number>(
      (count, answer) => (!answer || answer.value === null ? count + 1 : count),
      0
    );
  return unansweredCount;
};

export const getLatestAnswerModifiedAt = (
  questionSetId: QuestionSetId,
  questionSetArray: QuestionSet[],
  answerSetArray: AnswerSet[]
): string | undefined => {
  const answers = getAnswersWithoutUnnecessaryAnswers(
    questionSetId,
    questionSetArray,
    answerSetArray
  );

  const sortedAnswers = answers
    .filter((answer): answer is Answer => answer !== null && answer.value !== null)
    .sort((first, second) => second.modifiedAt.localeCompare(first.modifiedAt));

  if (sortedAnswers.length === 0) return;

  return sortedAnswers[0].modifiedAt;
};
