import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useTranslation } from 'react-i18next';

export type CustomizedProgressBarProps = {
  progress: number;
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    border: '0',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4, 4),
    alignItems: 'center',
  },
  progressContainer: {
    height: '10px',
    border: '1px solid',
    borderColor: theme.palette.common.black,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
  },
  bar: {
    backgroundColor: 'primary',
  },
}));

const CustomizedProgressBar = ({ progress }: CustomizedProgressBarProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (progress !== 100) {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-label="progress-bar-modal"
      >
        <Fade in={true}>
          <div className={classes.container}>
            <Typography align="center">
              {t('customizedProgressBar.downloadText')}
            </Typography>
            <br />
            <Typography align="center">
              {t('customizedProgressBar.doNotExitText')}
            </Typography>
            <br />
            <LinearProgress
              variant="determinate"
              value={progress}
              classes={{
                root: classes.progressContainer,
                colorPrimary: classes.colorPrimary,
                bar: classes.bar,
              }}
              aria-label="progress-bar"
            />
          </div>
        </Fade>
      </Modal>
    );
  }

  return <></>;
};

export default CustomizedProgressBar;
