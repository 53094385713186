import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSettings } from '../schemas';
import Context from '../context';
import { useGetToken } from './useGetToken';
import { getSettings } from '../helpers/configurationActions';

export const useSetLanguage = (): void => {
  const { authState } = useContext(Context);
  const [getToken] = useGetToken();
  const { i18n } = useTranslation();

  const employeeId = authState.graph?.profile?.employeeId;

  useEffect(() => {
    if (!employeeId) return;

    (async () => {
      const token = await getToken();
      const settings = await getSettings<UserSettings>(employeeId, token);
      const language = settings?.userLanguage ? (settings.userLanguage as string) : null;

      if (language) {
        i18n.changeLanguage(language);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);
};
