import { API_TYPE, put } from './fetch';
import {
  ActivityDifferentiator,
  InstallationSyncData,
  Syncpoint,
  Installation,
} from '../schemas';
import {
  generateIndexedDBKey,
  storedIndexedDBObjectType,
  getIndexedDBObject,
  upsertIndexedDBObject,
  deleteIndexedDBObject,
} from './indexedDB';

export const hasDataToSync = async (networkNumber: string): Promise<boolean> => {
  const pending = await getIndexedDBObject(
    generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
  );
  return Boolean(pending);
};

export const setHasDataToSync = async (networkNumber: string): Promise<void> => {
  await upsertIndexedDBObject(
    true,
    generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
  );
};

const removeHasDataToSync = async (networkNumber: string): Promise<void> => {
  await deleteIndexedDBObject(
    generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
  );
};

const APIGW_URL = process.env.REACT_APP_APIGW_URL || '';
export const syncWithBackend = async (
  networkNumber: string,
  jwtToken: string
): Promise<void> => {
  try {
    const syncDataKey = generateIndexedDBKey(
      networkNumber,
      storedIndexedDBObjectType.SYNC_DATA
    );
    const syncPointKey = generateIndexedDBKey(
      networkNumber,
      storedIndexedDBObjectType.SYNC_POINT
    );

    const syncDatafromIdxDb = await getIndexedDBObject<InstallationSyncData>(syncDataKey);
    if (syncDatafromIdxDb) {
      /* the syncWithBackend function will be call inside the service worker
          and axios unfortunately doesn't work with service worker. Hence, using
          native fetch over put helper (which leverage axios under the hood)
      */
      const requestOptions = {
        method: 'PUT',
        headers: {
          Authorization: jwtToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...syncDatafromIdxDb }),
      };

      const response = await fetch(
        `${APIGW_URL}/v1/installations/${networkNumber}/sync`,
        requestOptions
      );

      const syncPoint = await response.json();
      if (syncPoint) {
        await upsertIndexedDBObject(syncPoint, syncPointKey);
        await removeHasDataToSync(networkNumber);
      }
    }
  } catch (error) {
    throw new Error(`Error while syncing with backend ${error}`);
  }
};

export const cacheNetworkWithInstallationData = async (
  installationData: Installation,
  networkNumber: string,
  jwtToken: string,
  userRole: ActivityDifferentiator
): Promise<void> => {
  const installationDatakey = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.NETWORK
  );
  await upsertIndexedDBObject(installationData, installationDatakey);
  const syncpointResponse: Syncpoint = await put(
    `v1/installations/${networkNumber}/syncpoint`,
    jwtToken,
    API_TYPE.APPLICATION,
    {
      userRole: userRole,
    }
  );
  return saveSyncpointindexDB(networkNumber, syncpointResponse);
};

const saveSyncpointindexDB = async (
  networkNumber: string,
  syncpoint: Syncpoint
): Promise<void> => {
  const syncpointkey = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.SYNC_POINT
  );
  return await upsertIndexedDBObject(syncpoint, syncpointkey);
};
