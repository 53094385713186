import { InstallationState } from '../context/installation';
import { Deviation } from '../schemas';

export enum InstallationActionName {
  ADD_DEVIATION = 'ADD_DEVIATION',
  EDIT_DEVIATION = 'EDIT_DEVIATION',
  SET_DEVIATIONS = 'SET_DEVIATIONS',
}

export type AddDeviationAction = {
  type: InstallationActionName.ADD_DEVIATION;
  deviation: Deviation;
};

export type EditDeviationAction = {
  type: InstallationActionName.EDIT_DEVIATION;
  deviation: Deviation;
};

export type SetDeviationsAction = {
  type: InstallationActionName.SET_DEVIATIONS;
  deviations: Deviation[];
};

export type InstallationActions =
  | AddDeviationAction
  | EditDeviationAction
  | SetDeviationsAction;

const installationReducer = (
  prevState: InstallationState,
  action: InstallationActions
): InstallationState => {
  switch (action.type) {
    case InstallationActionName.SET_DEVIATIONS:
      return {
        ...prevState,
        deviations: [...action.deviations],
      };

    case InstallationActionName.ADD_DEVIATION:
      return {
        ...prevState,
        deviations: [...prevState.deviations, action.deviation],
      };

    case InstallationActionName.EDIT_DEVIATION:
      return {
        ...prevState,
        deviations: prevState.deviations.map((oldDeviation) =>
          /*
            there could be a situation that Context still holding a old data with guid == "temp_xx", however,
            in the background, synchonization might kick in and the same deviation in indexedDB will have a
            new guid generated from backend
          */
          oldDeviation.guid === action.deviation.guid ||
          oldDeviation.guid === action.deviation.tempGuid
            ? action.deviation
            : oldDeviation
        ),
      };

    default:
      return prevState;
  }
};

export default installationReducer;
