import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogContent, Typography } from '@material-ui/core';

import Context, { InstallationContext } from '../../context';
import { Deviation, isDeviationOpen } from '../../schemas';
import { editDeviation } from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import { isSyncDeviationsInProgress } from '../../helpers/deviationOffline';
import { useGetToken } from '../../hooks/useGetToken';

import DeviationsList, { GroupType, SortType } from '../DeviationsList';
import DeviationForm, { EditDeviationPayload } from '../DeviationForm';

const useStyles = makeStyles((theme) =>
  createStyles({
    headline: {
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
    instructions: {
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    summary: {
      lineHeight: theme.spacing(0.3),
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    list: {
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
  })
);

const HandoverSupervisorSummary = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [getToken] = useGetToken();

  const { updateIsLoading, updateErrorMessage, networkNumber } = useContext(Context);
  const { deviations, dispatch } = useContext(InstallationContext);

  const [selectedDeviation, setSelectedDeviation] = useState<Deviation>();
  const [isDeviationFormOpen, setDeviationFormOpen] = useState<boolean>(false);

  const openDeviationFormDialog = (deviation: Deviation) => {
    setSelectedDeviation(deviation);
    setDeviationFormOpen(true);
  };

  const closeDeviationFormDialog = () => {
    setSelectedDeviation(undefined);
    setDeviationFormOpen(false);
  };

  const waitForDeviationsSync = async () => {
    while (await isSyncDeviationsInProgress(networkNumber)) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  };

  const editDeviationAction = async (deviation: EditDeviationPayload) => {
    updateIsLoading(true);
    try {
      await waitForDeviationsSync();
      const accessToken = await getToken();
      const patchedDeviation = await editDeviation(accessToken, networkNumber, deviation);
      dispatch({
        type: InstallationActionName.EDIT_DEVIATION,
        deviation: patchedDeviation,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('handoverSupervisorSummary.cannotUpdateDeviation'),
        error,
      });
    } finally {
      updateIsLoading(false);
    }
  };

  const unsupportedAction = async () => await Promise.resolve();

  return (
    <>
      <Dialog open={isDeviationFormOpen} fullScreen>
        <DialogContent>
          <DeviationForm
            initialDeviation={selectedDeviation}
            onCreate={() => null}
            onEdit={editDeviationAction}
            onClear={closeDeviationFormDialog}
          />
        </DialogContent>
      </Dialog>
      <Box className={classes.summary}>
        <Typography className={classes.headline} variant="h5">
          {t('handoverSupervisorSummary.title')}
        </Typography>
        <Typography className={classes.instructions} variant="body1">
          {t('handoverSupervisorSummary.instructions')}
        </Typography>
        <ol className={classes.list}>
          <li>{t('handoverSupervisorSummary.step1')}</li>
          <li>{t('handoverSupervisorSummary.step2')}</li>
          <li>{t('handoverSupervisorSummary.step3')}</li>
          <li>{t('handoverSupervisorSummary.step4')}</li>
        </ol>
      </Box>
      {deviations.some(isDeviationOpen) && (
        <DeviationsList
          deviations={deviations}
          filters={{
            groupBy: GroupType.NONE,
            sortBy: SortType.PRIORITY,
            showFixed: false,
          }}
          showControls={false}
          deviationOpenClick={openDeviationFormDialog}
          deviationCloseClick={unsupportedAction}
          deviationsEditAction={unsupportedAction}
          deviationNotification={unsupportedAction}
        />
      )}
    </>
  );
};

export default HandoverSupervisorSummary;
