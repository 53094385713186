import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { groupBy, startCase, uniqBy } from 'lodash';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import {
  Check,
  CheckBoxOutlined,
  FilterList,
  MailOutline,
  Search,
} from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CircleCancel, theme } from '@konecorp/ui-library';

import Context from '../../context';
import {
  ActivityDifferentiator,
  Deviation,
  DeviationLocation,
  DeviationStatus,
  DeviationVariation,
  SpecialUserIds,
  isDeviationOpen,
} from '../../schemas';
import { EditDeviationPayload } from '../DeviationForm';
import { getEmployeesDataFromInstallation } from '../../helpers/fetch';
import { useGetToken } from '../../hooks/useGetToken';
import { getSubcontractorId, useGetUserData } from '../../hooks/useGetUserData';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';

import DeviationIcon from '../DeviationIcon';
import DeviationsListFilters from '../DeviationsListFilters';
import DeviationsListUpdate, { UpdateDeviationPayload } from '../DeviationsListUpdate';
import DeviationsListNotification from '../DeviationsListNotification';
import SearchDebounced from '../SearchDebounced';
import Empty from '../Empty';
import { formatDate } from '../../helpers/formating';

type DeviationHook = (deviation: Deviation) => void;

type DeviationsListProps = {
  deviations: Deviation[];
  filters?: Partial<Filters>;
  showControls: boolean;
  deviationOpenClick: DeviationHook;
  deviationCloseClick: DeviationHook;
  deviationsEditAction: (deviations: EditDeviationPayload[]) => Promise<void>;
  deviationNotification: (employeeId: string) => Promise<void>;
};

enum DialogType {
  FILTERS,
  UPDATE,
  NOTIFICATION,
  NONE,
}

export enum GroupType {
  PRIORITY = 'PRIORITY',
  LOCATION = 'LOCATION',
  ASSIGNEE = 'ASSIGNEE',
  NONE = 'NONE',
}

export enum SortType {
  CREATED = 'CREATED',
  PRIORITY = 'PRIORITY',
}

export type Filters = {
  assignedTo: string;
  createdBy: string;
  groupBy: GroupType;
  sortBy: SortType;
  showFixed: boolean;
  showDeviations: boolean;
  showQrIssues: boolean;
};

type DeviationGroups = {
  [name: string]: Deviation[];
};

enum PriorityGroups {
  COMPLIANCE = 'compliance',
  BLOCKER = 'blocker',
  MINOR = 'minor',
}

enum AssigneeGroups {
  ASSIGNED_TO_YOU = 'assignedToYou',
  CREATED_BY_YOU = 'createdByYou',
  CONCERNING_YOU = 'concerningYou',
  OTHER = 'other',
}

export type WorkerData = {
  employeeId: string;
  activityDifferentiator: ActivityDifferentiator;
  displayName: string;
};

export const defaultFilters: Filters = {
  assignedTo: '',
  createdBy: '',
  groupBy: GroupType.PRIORITY,
  sortBy: SortType.CREATED,
  showFixed: true,
  showDeviations: true,
  showQrIssues: true,
};

const useStyles = makeStyles(() =>
  createStyles({
    headingContainer: {
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    subheader: {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
      fontSize: '120%',
      textTransform: 'uppercase',
    },
    subHeaderRow: {
      '& .MuiTableCell-root': {
        paddingTop: theme.spacing(5),
      },
    },
    itemCheckbox: {
      padding: 0,
    },
    itemText: {
      width: '50vw',
    },
    deviationCloseButton: {
      borderRadius: 0,
    },
    closeIcon: {
      width: 30,
      height: 30,
      position: 'absolute',
      right: '10px',
    },
    multiselectInfo: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: theme.spacing(1),
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
    },
  })
);

const DeviationsList = (props: DeviationsListProps): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [getAccessToken] = useGetToken();
  const { installationData } = useContext(Context);
  const [employeeId] = useGetUserData();

  const [userRole] = useGetCurrentUserRole();

  const [filters, setFilters] = useState<Filters>({
    ...defaultFilters,
    ...props.filters,
  });

  const [workers, setWorkers] = useState<WorkerData[]>([]);
  const [searchText, setSearchText] = useState('');
  const [openedDialog, setOpenedDialog] = useState<DialogType>(DialogType.NONE);
  const [openSearch, setOpenSearch] = useState(false);
  const [enableMultiselect, setEnableMultiselect] = useState<boolean>(false);
  const [selectedDeviations, setSelectedDeviations] = useState<Deviation[]>([]);
  const [deviationToClose, setDeviationToClose] = useState<Deviation | undefined>(
    undefined
  );

  const isLargeScreen = useMediaQuery('(min-width:767px)', { noSsr: true });

  const deviationCheckboxClick = (selected: Deviation) =>
    setSelectedDeviations(
      selectedDeviations.includes(selected)
        ? selectedDeviations.filter((deviation) => deviation.guid !== selected.guid)
        : [...selectedDeviations, selected]
    );

  const updateSelectedDeviations = async (payload: UpdateDeviationPayload) => {
    const editDeviationPayloads: EditDeviationPayload[] = selectedDeviations.map(
      (deviation) => ({
        ...deviation,
        ...payload,
        source: 'IES',
        files: [],
      })
    );

    await props.deviationsEditAction(editDeviationPayloads);
  };

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessToken();

      const employees = installationData
        ? await getEmployeesDataFromInstallation(accessToken, installationData)
        : [];

      const getEmployeeName = (employeeId: string): string => {
        const employee = employees.find(
          (employee) => employee?.employeeId === employeeId
        );
        return employee
          ? `${employee.legalFirstName} ${employee.legalLastName}`
          : employeeId;
      };

      const assignees = (installationData?.assignees || []).map<WorkerData>(
        (assignee) => ({
          employeeId: assignee.koneResourcePersonalNumber,
          activityDifferentiator:
            assignee.activityDifferentiator as ActivityDifferentiator,
          displayName: getEmployeeName(assignee.koneResourcePersonalNumber),
        })
      );

      const subcontractors = uniqBy(
        installationData?.subcontractors || [],
        'activityDifferentiator'
      ).map<WorkerData>((subcontractor) => ({
        employeeId: getSubcontractorId(subcontractor.activityDifferentiator),
        activityDifferentiator: subcontractor.activityDifferentiator,
        displayName: subcontractor.subcontractor.name,
      }));

      const supervisor: WorkerData = {
        employeeId: installationData?.supervisorNumber || '',
        activityDifferentiator: ActivityDifferentiator.SPV,
        displayName: t('deviationsList.assignee.supervisor'),
      };

      const builder: WorkerData = {
        employeeId: SpecialUserIds.BUILDER,
        activityDifferentiator: ActivityDifferentiator.OTHER,
        displayName: t('deviationsList.assignee.builder'),
      };

      const workers = uniqBy(
        [...assignees, ...subcontractors, supervisor, builder],
        'employeeId'
      );
      setWorkers(workers);
    })();
  }, [installationData]);

  const fixedStatuses: DeviationStatus[] = [
    DeviationStatus.CLOSED,
    DeviationStatus.CANCELLED,
  ];
  const deviationVariations: DeviationVariation[] = [DeviationVariation.DEVIATION];
  const qrIssueVariations: DeviationVariation[] = [
    DeviationVariation.REJECT,
    DeviationVariation.ACCEPT_WITH_ACTIONS,
  ];

  const includeAssignedTo = (deviation: Deviation) => {
    if (filters.assignedTo) return deviation.assignee === filters.assignedTo;
    return true;
  };
  const includeCreatedBy = (deviation: Deviation) => {
    if (filters.createdBy) return deviation.createdBy === filters.createdBy;
    return true;
  };
  const includeFixed = (deviation: Deviation) => {
    if (fixedStatuses.includes(deviation.status)) return filters.showFixed;
    return true;
  };
  const includeDeviations = (deviation: Deviation) => {
    if (deviationVariations.includes(deviation.variation)) return filters.showDeviations;
    return true;
  };
  const includeQrIssues = (deviation: Deviation) => {
    if (qrIssueVariations.includes(deviation.variation)) return filters.showQrIssues;
    return true;
  };
  const includeSearch = (deviation: Deviation) =>
    !searchText ||
    deviation.description.toLowerCase().includes(searchText.toLowerCase()) ||
    deviation.history?.some((history) =>
      history.userComment?.toLowerCase().includes(searchText.toLowerCase())
    );

  const byCreatedAt = (d1: Deviation, d2: Deviation) =>
    new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime();

  const byStatus = (d1: Deviation, d2: Deviation) =>
    Object.keys(DeviationStatus).indexOf(d1.status) -
    Object.keys(DeviationStatus).indexOf(d2.status);

  const byPriority = (d1: Deviation, d2: Deviation) => {
    if (d1.blocker && !d2.blocker) return -1;
    if (d1.compliance && !d2.compliance) return -1;
    return 0;
  };

  const sortBy = (() => {
    switch (filters.sortBy) {
      case SortType.CREATED:
        return byCreatedAt;
      case SortType.PRIORITY:
        return byPriority;
    }
  })();

  const deviations = props.deviations
    .filter(includeAssignedTo)
    .filter(includeCreatedBy)
    .filter(includeFixed)
    .filter(includeDeviations)
    .filter(includeQrIssues)
    .filter(includeSearch)
    .sort(sortBy)
    .sort(byStatus);

  const priorityGroups = (deviation: Deviation) => {
    if (deviation.compliance) return PriorityGroups.COMPLIANCE;
    else if (deviation.blocker) return PriorityGroups.BLOCKER;
    return PriorityGroups.MINOR;
  };
  const locationGroups = (deviation: Deviation) => deviation.location || 'other';
  const assigneeGroups = (deviation: Deviation) => {
    if (deviation.assignee === employeeId) return AssigneeGroups.ASSIGNED_TO_YOU;
    if (deviation.createdBy === employeeId) return AssigneeGroups.CREATED_BY_YOU;
    if (deviation.history?.some((entry) => entry.employeeId === employeeId))
      return AssigneeGroups.CONCERNING_YOU;
    return AssigneeGroups.OTHER;
  };

  const orderGroups = (unordered: DeviationGroups, sortKeys: string[]): DeviationGroups =>
    sortKeys.reduce(
      (ordered, key) =>
        unordered[key] ? { ...ordered, [key]: unordered[key] } : ordered,
      {} as DeviationGroups
    );

  const groups: DeviationGroups = (() => {
    let unordered: DeviationGroups = {};

    switch (filters.groupBy) {
      case GroupType.PRIORITY:
        unordered = groupBy(deviations, priorityGroups);
        return orderGroups(unordered, Object.values(PriorityGroups));
      case GroupType.LOCATION:
        unordered = groupBy(deviations, locationGroups);
        return orderGroups(unordered, Object.values(DeviationLocation));
      case GroupType.ASSIGNEE:
        unordered = groupBy(deviations, assigneeGroups);
        return orderGroups(unordered, Object.values(AssigneeGroups));
      default:
        return { deviations };
    }
  })();

  const translateGroupName = (key: string) =>
    [GroupType.PRIORITY, GroupType.ASSIGNEE].includes(filters.groupBy)
      ? t(`deviationsList.groups.${key}`)
      : startCase(key);

  const filtersCloseAction = (reset?: boolean) => {
    if (reset) setFilters(defaultFilters);
    setOpenedDialog(DialogType.NONE);
  };

  const isAllowedToQuickClose = (deviation: Deviation) =>
    isDeviationOpen(deviation) &&
    (userRole === ActivityDifferentiator.CMSN ||
      (userRole === ActivityDifferentiator.SPV && !deviation.compliance));

  const DeviationRow = (props: {
    deviation: Deviation;
    showCheckbox: boolean;
    showControls: boolean;
    checked: boolean;
    deviationCheckboxClick: DeviationHook;
    deviationOpenClick: DeviationHook;
    deviationCloseClick: DeviationHook;
  }): JSX.Element => {
    const { deviation, showCheckbox, showControls, checked } = props;

    const isPriorityDeviation =
      (deviation.blocker || deviation.compliance) && isDeviationOpen(deviation);

    const openQuickClose =
      showControls && !enableMultiselect && deviation === deviationToClose;

    return (
      <TableRow>
        <TableCell style={{ display: 'flex', padding: 0 }}>
          <Slide
            in={openQuickClose}
            direction="right"
            timeout={400}
            mountOnEnter
            unmountOnExit
          >
            <Button
              className={styles.deviationCloseButton}
              startIcon={<Check />}
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                setDeviationToClose(undefined);
                props.deviationCloseClick(deviation);
              }}
            >
              {t('deviationsList.close')}
            </Button>
          </Slide>

          <ListItem button>
            {showCheckbox && (
              <ListItemIcon>
                <Checkbox
                  data-testid={`checkbox-${deviation.guid}`}
                  checked={checked}
                  className={styles.itemCheckbox}
                  onChange={() => props.deviationCheckboxClick(deviation)}
                  disabled={!isDeviationOpen(deviation)}
                />
              </ListItemIcon>
            )}
            <ListItemIcon
              data-testid={`icon-${deviation.guid}`}
              onClick={() => {
                if (isAllowedToQuickClose(deviation)) {
                  setDeviationToClose(
                    deviationToClose !== deviation ? deviation : undefined
                  );
                } else {
                  setDeviationToClose(undefined);
                }
              }}
            >
              <DeviationIcon deviation={deviation} />
            </ListItemIcon>

            <ListItemText
              className={styles.itemText}
              style={{ color: isPriorityDeviation ? 'red' : 'inherit' }}
              onClick={() => {
                if (deviationToClose) {
                  setDeviationToClose(undefined);
                } else {
                  props.deviationOpenClick(deviation);
                }
              }}
            >
              {deviation.description}
            </ListItemText>
          </ListItem>
        </TableCell>
        {isLargeScreen && (
          <>
            <TableCell>
              {workers.find((w) => w.employeeId === deviation.createdBy)?.displayName}
            </TableCell>
            <TableCell>{formatDate(deviation.createdAt)}</TableCell>
          </>
        )}
      </TableRow>
    );
  };

  const SelectAllDeviationsItem = (props: {
    groups: DeviationGroups;
    selectedDeviations: Deviation[];
  }): JSX.Element => {
    const { groups } = props;

    const deviations = Object.values(groups).flat().filter(isDeviationOpen);

    return (
      <ListItem>
        <ListItemIcon>
          <Checkbox
            checked={selectedDeviations.length === deviations.length}
            indeterminate={
              selectedDeviations.length > 0 &&
              selectedDeviations.length !== deviations.length
            }
            className={styles.itemCheckbox}
            data-testid="checkbox-select-all"
            onChange={(_, checked) => setSelectedDeviations(checked ? deviations : [])}
          />
        </ListItemIcon>
        <ListItemText>{t('deviationsList.selectAll')}</ListItemText>
      </ListItem>
    );
  };

  const DeviationsTable = (props: {
    groups: DeviationGroups;
    enableMultiselect: boolean;
    selectedDeviations: Deviation[];
    showControls: boolean;
    deviationCheckboxClick: DeviationHook;
    deviationOpenClick: DeviationHook;
    deviationCloseClick: DeviationHook;
  }): JSX.Element => {
    const { groups, enableMultiselect, selectedDeviations, showControls } = props;

    return (
      <TableContainer>
        {enableMultiselect && (
          <SelectAllDeviationsItem
            groups={groups}
            selectedDeviations={selectedDeviations}
          />
        )}
        <Table size="small">
          {Object.entries(groups).map(([group, deviations]) => [
            group !== 'deviations' && (
              <TableHead key={`header-${group}`}>
                <TableRow className={styles.subHeaderRow}>
                  <TableCell colSpan={isLargeScreen ? 1 : 3}>
                    <Typography className={styles.subheader}>
                      {translateGroupName(group)}
                    </Typography>
                  </TableCell>
                  {isLargeScreen && (
                    <>
                      <TableCell>
                        <Typography>{t('deviationsList.createdBy')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{t('deviationsList.creationDate')}</Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
            ),
            <TableBody key={`rows-${group}`}>
              {deviations.map((deviation) => (
                <DeviationRow
                  deviation={deviation}
                  showCheckbox={enableMultiselect}
                  checked={selectedDeviations.includes(deviation)}
                  showControls={showControls}
                  deviationCheckboxClick={props.deviationCheckboxClick}
                  deviationOpenClick={props.deviationOpenClick}
                  deviationCloseClick={props.deviationCloseClick}
                  key={deviation.guid}
                />
              ))}
            </TableBody>,
          ])}
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Dialog open={openedDialog !== DialogType.NONE} fullWidth maxWidth="sm">
        <DialogContent>
          <CircleCancel
            data-testid="dialog-close-icon"
            className={styles.closeIcon}
            onClick={() => setOpenedDialog(DialogType.NONE)}
          />
          <Box mt={5}>
            {openedDialog === DialogType.FILTERS && (
              <DeviationsListFilters
                workers={workers}
                filters={filters}
                setFilters={setFilters}
                onClose={filtersCloseAction}
              />
            )}
            {openedDialog === DialogType.UPDATE && (
              <DeviationsListUpdate
                selectedDeviations={selectedDeviations}
                workers={workers}
                onUpdate={async (payload) => {
                  await updateSelectedDeviations(payload);
                  setOpenedDialog(DialogType.NONE);
                  setEnableMultiselect(false);
                  setSelectedDeviations([]);
                }}
                onCancel={() => setOpenedDialog(DialogType.NONE)}
              />
            )}
            {openedDialog === DialogType.NOTIFICATION && (
              <DeviationsListNotification
                workers={workers}
                onSend={async (employeeId) => {
                  await props.deviationNotification(employeeId);
                  setOpenedDialog(DialogType.NONE);
                }}
                onCancel={() => setOpenedDialog(DialogType.NONE)}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {props.showControls && (
        <Grid container className={styles.headingContainer}>
          <Grid item xs={8}>
            <Typography variant={'h5'}>{t('deviationsList.deviations')}</Typography>
          </Grid>
          <Grid item xs={1}>
            <CheckBoxOutlined
              data-testid="icon-checkbox"
              onClick={() => setEnableMultiselect(!enableMultiselect)}
            />
          </Grid>
          <Grid item xs={1}>
            <MailOutline
              data-testid="icon-notification"
              onClick={() => setOpenedDialog(DialogType.NOTIFICATION)}
            />
          </Grid>
          <Grid item xs={1}>
            <FilterList
              data-testid="icon-filters"
              onClick={() => setOpenedDialog(DialogType.FILTERS)}
            />
          </Grid>
          <Grid item xs={1}>
            <Search
              data-testid="icon-search"
              onClick={() => setOpenSearch((openSearch) => !openSearch)}
            />
          </Grid>
        </Grid>
      )}

      {enableMultiselect && (
        <Box className={styles.multiselectInfo}>
          <Typography>{`${selectedDeviations.length} ${t(
            'deviationsList.deviationsSelected'
          )}`}</Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedDeviations.length === 0}
            onClick={() => setOpenedDialog(DialogType.UPDATE)}
          >
            {t('deviationsList.done')}
          </Button>
        </Box>
      )}
      {openSearch && (
        <SearchDebounced
          onChange={(value) => setSearchText(value)}
          onClose={() => {
            setSearchText('');
            setOpenSearch(false);
          }}
          waitTime={500}
        />
      )}

      {deviations.length > 0 ? (
        <DeviationsTable
          groups={groups}
          enableMultiselect={enableMultiselect}
          selectedDeviations={selectedDeviations}
          showControls={props.showControls}
          deviationCheckboxClick={deviationCheckboxClick}
          deviationOpenClick={props.deviationOpenClick}
          deviationCloseClick={props.deviationCloseClick}
        />
      ) : (
        <Empty message={t('deviationsList.noDeviationsFound')} />
      )}
    </>
  );
};
export default DeviationsList;
