import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { theme } from '@konecorp/ui-library';

import RaccoonImage from './assets/raccoon.png';
import FireworksImage from './assets/fireworks.png';
import { useTranslation } from 'react-i18next';

const HandoverAnimation = (): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      marginBottom: theme.spacing(1),
    },
    animationContainer: {
      display: 'flex',
    },
    textContainer: {
      flexDirection: 'column',
      zIndex: 2,
      textAlign: 'center',
      position: 'relative',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      border: `1px solid ${theme.palette.text.secondary}`,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.secondary,
      '& h6': {
        fontWeight: 'bold',
      },
    },
    imageRaccoon: {
      width: '100%',
      maxWidth: theme.spacing(50),
      margin: '0 auto',
      opacity: 0,
      zIndex: 1,
      transform: 'translateY(60%) rotate(-30deg)',
    },
    animateImageRaccoon: {
      animation: `$slideUpRaccoon 1s ${theme.transitions.easing.easeInOut}`,
      opacity: 1,
      transform: 'translateY(15%) rotate(0deg)',
    },
    '@keyframes slideUpRaccoon': {
      '0%': {
        opacity: 0,
        transform: 'translateY(60%) rotate(-30deg)',
      },
      '50%': {
        opacity: 1,
        transform: 'translateY(15%) rotate(0deg)',
      },
      '60%': { transform: 'translateY(30%) rotate(-5deg)' },
      '80%': { transform: 'translateY(10%) rotate(2deg)' },
      '90%': { transform: 'translateY(20%) rotate(5deg)' },
      '100%': { transform: 'translateY(15%) rotate(0deg)' },
    },
    imageFireworks: {
      position: 'absolute',
      left: 0,
      width: '90%',
      opacity: 0,
    },
    animateImageFireworks: {
      animation: `$fireworkAnimation 3s 1.5s ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes fireworkAnimation': {
      '0%': {
        opacity: 1,
        transform: 'scale(0.5)',
      },
      '50%': {
        transform: 'scale(1.5)',
      },
      '100%': {
        opacity: 0,
        transform: 'scale(0) translate(0%, 100%)',
      },
    },
  }));

  const [triggerTransition, setTriggerTransition] = useState(false);
  const { t } = useTranslation();

  const classes = useStyles(theme);

  useEffect(() => {
    if (!triggerTransition) {
      const timer = setTimeout(() => {
        setTriggerTransition(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.animationContainer}>
        <img
          src={RaccoonImage}
          className={
            classes.imageRaccoon +
            ` ${triggerTransition ? classes.animateImageRaccoon : ''}`
          }
          alt="image-raccoon"
        />
        <img
          src={FireworksImage}
          className={
            classes.imageFireworks +
            ` ${triggerTransition ? classes.animateImageFireworks : ''}`
          }
          alt="imageFireworks"
        />
      </div>
      <div className={classes.textContainer}>
        <Typography variant="h6">{t('handover.congratulations')}</Typography>
        <Typography component="div" variant="caption">
          {t('handover.smoothInstallation')}
        </Typography>
        <Typography component="div" variant="caption">
          {t('handover.noOpenDeviations')}
        </Typography>
      </div>
    </div>
  );
};
export default HandoverAnimation;
