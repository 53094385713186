import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Context from '../../context';
import { Contact, ContactRole, UserSettings, VersionInfo } from '../../schemas';
import { emailValidator } from '../../helpers/validators';
import { VersionInfoContent } from '../VersionInfo';
import { useGetToken } from '../../hooks/useGetToken';
import { get } from '../../helpers/fetch';
import { locales } from '../../localization';

export type SubcontractorPersonalInfoFormProps = {
  dbInfo?: Contact;
  contactRole: ContactRole;
  onContinue: () => void;
  upsertContact: (param: Contact) => Promise<void>;
};

const SubcontractorPersonalInfoForm = (
  props: SubcontractorPersonalInfoFormProps
): JSX.Element => {
  const { dbInfo, upsertContact, onContinue, contactRole } = props;
  const { t, i18n } = useTranslation();

  const [settings, setSettings] = useState<UserSettings>({ userLanguage: i18n.language });

  const [versionInfo, setVersionInfo] = useState<VersionInfo | undefined>();

  const { updateIsLoading } = useContext(Context);
  const [getToken] = useGetToken();

  useEffect(() => {
    if (!versionInfo) {
      (async () => {
        updateIsLoading(true);
        const token = await getToken();
        const versionInfo: VersionInfo | undefined = await get(
          `v1/applications/ies-app/versions/${i18n.language}`,
          token
        );
        setVersionInfo(versionInfo);
        updateIsLoading(false);
      })();
    }
  }, []);

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .trim()
      .required(t('subcontractorPersonalInfoForm.requiredHelperText')),
    lastName: yup
      .string()
      .trim()
      .required(t('subcontractorPersonalInfoForm.requiredHelperText')),
    phone: yup
      .string()
      .trim()
      .required(t('subcontractorPersonalInfoForm.requiredHelperText'))
      .test(
        'is-valid-phone',
        t('subcontractorPersonalInfoForm.invalidPhoneHelperText'),
        (phone) => !!phone && isValidPhoneNumber(phone)
      ),
    email: yup
      .string()
      .trim()
      .test(
        'is-valid-email',
        t('subcontractorPersonalInfoForm.invalidEmailHelperText'),
        (email) => (email ? emailValidator(email) : true)
      ),
  });

  const formik = useFormik({
    initialValues: {
      firstName: dbInfo?.firstName || '',
      lastName: dbInfo?.lastName || '',
      phone: dbInfo?.phone || '',
      email: dbInfo?.email || '',
      guid: dbInfo?.guid || '',
      role: dbInfo?.role || contactRole,
    },
    validationSchema,
    onSubmit: async (value: Contact) => {
      const isInfoChanged =
        dbInfo?.firstName !== value.firstName ||
        dbInfo?.lastName !== value.lastName ||
        dbInfo?.phone !== value.phone ||
        dbInfo?.email !== value.email;

      if (isInfoChanged) {
        const { email, ...otherFields } = value;
        await upsertContact({
          ...otherFields,
          ...(email ? { email } : undefined),
        });
      }

      i18n.changeLanguage(settings.userLanguage as string);
      onContinue();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Box pt={3} pb={3}>
        <Typography>
          {t('subcontractorPersonalInfoForm.enterContactInfoBelow')}
        </Typography>

        <Box pt={2} pb={2}>
          <TextField
            name="firstName"
            id="firstName"
            margin="normal"
            variant="outlined"
            fullWidth
            required
            label={t('subcontractorPersonalInfoForm.firstName')}
            inputProps={{
              'data-testid': 'subcontractor-info-form-first-name-input',
            }}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />

          <TextField
            name="lastName"
            id="lastName"
            margin="normal"
            variant="outlined"
            fullWidth
            required
            label={t('subcontractorPersonalInfoForm.lastName')}
            inputProps={{
              'data-testid': 'subcontractor-info-form-last-name-input',
            }}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />

          <TextField
            name="phone"
            id="phone"
            margin="normal"
            variant="outlined"
            fullWidth
            required
            label={t('subcontractorPersonalInfoForm.phoneNumber')}
            inputProps={{
              'data-testid': 'subcontractor-info-form-phone-input',
            }}
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />

          <TextField
            name="email"
            id="email"
            margin="normal"
            variant="outlined"
            fullWidth
            label={t('subcontractorPersonalInfoForm.email')}
            inputProps={{
              'data-testid': 'subcontractor-info-form-email-input',
            }}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Box>

        <Box pb={3}>
          <TextField
            name="language"
            label={t('settings.language')}
            variant="outlined"
            value={settings.userLanguage}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSettings({ ...settings, userLanguage: event.target.value })
            }
            inputProps={{ 'data-testid': 'language-input' }}
            fullWidth
            select
          >
            {locales.map((locale) => (
              <MenuItem value={locale.tag} key={locale.tag}>
                {locale.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box pb={2}>
          <Accordion disabled={!versionInfo}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>{t('versionInfo.title')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <VersionInfoContent versionInfo={versionInfo} />
            </AccordionDetails>
          </Accordion>
        </Box>

        <Typography variant="subtitle2">
          {t('subcontractorPersonalInfoForm.note')}
        </Typography>

        <Box pt={5}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={dbInfo ? !formik.isValid : !(formik.isValid && formik.dirty)}
            data-testid="subcontractor-info-continue-button"
          >
            {t('subcontractorPersonalInfoForm.continue')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default SubcontractorPersonalInfoForm;
