import React, { useState } from 'react';

import { TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { AnswerValue, PutAnswerPayload, Question } from '../../schemas';

export interface QuestionNumericValueProps {
  question: Question;
  answer?: AnswerValue;
  onAnswer: (newAnswer: PutAnswerPayload) => Promise<void>;
  isReadOnly?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      backgroundColor: theme.palette.background.default,
      marginRight: theme.spacing(0.5),
      width: '97px',
      [theme.breakpoints.up('sm')]: {
        width: '113px',
      },
    },
  })
);

const QuestionNumericValue = (props: QuestionNumericValueProps): JSX.Element => {
  const { question, answer = '', onAnswer, isReadOnly } = props;

  const classes = useStyles();
  const [value, setValue] = useState<string>(answer as string);
  const [isValid, setValid] = useState<boolean>(true);

  const isValidNumber = (value: string): boolean => !!value && !isNaN(Number(value));

  const saveAnswer = async (): Promise<void> => {
    if (!value || !isValid || Number(value) === answer) return;

    const newAnswer: PutAnswerPayload = {
      value: Number(value),
      tag: question?.tag || '',
      position: question?.position || '',
      timestamp: Date.now(),
    };
    await onAnswer(newAnswer);
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      inputProps={{ 'data-testid': 'question-numeric-input' }}
      className={classes.textField}
      value={value}
      error={!isValid}
      onChange={(event) => {
        setValid(isValidNumber(event.target.value));
        setValue(event.target.value);
      }}
      onBlur={saveAnswer}
      disabled={isReadOnly}
    />
  );
};

export default QuestionNumericValue;
