import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { theme } from '@konecorp/ui-library';

import RaccoonSign from './assets/raccoon_sign.png';

type WarningProps = {
  message: string;
};

const Warning = (props: WarningProps): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) => ({
    textContainer: {
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      border: `1px solid ${theme.palette.text.secondary}`,
      color: theme.palette.text.secondary,
      '& h6': {
        fontWeight: 'bold',
      },
    },
    imageRaccoonWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    imageRaccoon: {
      maxWidth: '200px',
      width: '40%',
    },
  }));

  const classes = useStyles(theme);

  return (
    <div>
      <div className={classes.imageRaccoonWrapper}>
        <img src={RaccoonSign} className={classes.imageRaccoon} alt="image-raccoon" />
      </div>
      <div className={classes.textContainer}>
        <Typography variant="h6">{props.message}</Typography>
      </div>
    </div>
  );
};

export default Warning;
