import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { flatten, uniq } from 'lodash';

import { Collapse, Grid, Paper, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { theme } from '@konecorp/ui-library';

import {
  ActivityDifferentiator,
  DeviationHistory,
  DeviationHistoryChange,
  Employee,
  SpecialUserIds,
  SubcontractorRecord,
} from '../../schemas';
import {
  DEFAULT_DATE_WITH_HOURS_DISPLAY_FORMAT,
  formatDate,
} from '../../helpers/formating';
import { fetchEmployee } from '../../helpers/fetch';
import { useGetToken } from '../../hooks/useGetToken';

import FileIcon from '../FileIcon';

type DeviationChangeHistoryProps = {
  history: DeviationHistory[];
  subcontractors: SubcontractorRecord[];
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'whitesmoke',
      width: '100%',
      padding: theme.spacing(1),
    },
    element: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(0.5),
    },
    wrapIcon: {
      display: 'inline-flex',
      verticalAlign: 'middle',
    },
  })
);

const DeviationChangeHistory = (props: DeviationChangeHistoryProps): JSX.Element => {
  const styles = useStyles();
  const [getToken] = useGetToken();
  const { t } = useTranslation();

  const { history, subcontractors } = props;

  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);

  useEffect(() => {
    // fetch once only on open
    if (!open || employees.length > 0) return;

    (async () => {
      const token = await getToken();

      const employeeIds = uniq([
        ...history.map((entry) => entry.employeeId),
        ...flatten(
          history.map((entry) =>
            entry.changes
              .filter((change) => change.key === 'assignee')
              .map((change) => change.newValue as string)
          )
        ),
      ]);

      const promises = employeeIds.map((employeeId) => fetchEmployee(employeeId, token));
      const employees = (await Promise.allSettled(promises))
        .filter((result) => result.status === 'fulfilled')
        .map((result) => result as PromiseFulfilledResult<Employee>)
        .map((result) => result.value)
        .filter(Boolean);
      setEmployees(employees);
    })();
  }, [open]);

  const getEmployeeName = (employeeId: string): string => {
    const employee = employees.find((employee) => employee.employeeId === employeeId);
    return employee
      ? `${employee.legalLastName}, ${employee.legalFirstName}`
      : employeeId;
  };

  const getWorkerName = (workerId: string) => {
    const findSubcontractor = (role: ActivityDifferentiator) =>
      subcontractors.find(
        (subcontractor) => subcontractor.activityDifferentiator === role
      )?.subcontractor;

    switch (workerId) {
      case SpecialUserIds.BUILDER: {
        return t('deviationChangeHistory.builder');
      }

      case SpecialUserIds.SUBCONTRACTOR_INSTALLER: {
        const subcontractor = findSubcontractor(ActivityDifferentiator.INST);
        return subcontractor?.name || t('deviationChangeHistory.subcontractor');
      }

      case SpecialUserIds.SUBCONTRACTOR_TESTER: {
        const subcontractor = findSubcontractor(ActivityDifferentiator.CMSN);
        return subcontractor?.name || t('deviationChangeHistory.subcontractor');
      }

      default:
        return getEmployeeName(workerId);
    }
  };

  const HistoryChangeElement = (props: {
    change: DeviationHistoryChange;
  }): JSX.Element => {
    const { change } = props;

    const keyName = t(`deviation.${change.key}`);
    const changedTo = t('deviationChangeHistory.changedTo');

    const value = (() => {
      switch (change.key) {
        case 'assignee':
          return getWorkerName(change.newValue as string);
        case 'blocker':
        case 'compliance':
          return change.newValue
            ? t('deviationChangeHistory.enabled')
            : t('deviationChangeHistory.disabled');
        default:
          return change.newValue?.toString() || '-';
      }
    })();

    const message = `${keyName} ${changedTo} ${value}`;

    return (
      <Typography key={change.key}>
        <i>{message}</i>
      </Typography>
    );
  };

  const HistoryElement = (props: {
    entry: DeviationHistory;
    last: boolean;
  }): JSX.Element => {
    const { entry, last } = props;

    return (
      <Paper variant="outlined" className={styles.element}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>
              {formatDate(entry.modifiedAt, DEFAULT_DATE_WITH_HOURS_DISPLAY_FORMAT)}
            </Typography>
          </Grid>
          <Grid item>
            {last ? (
              <Typography>
                {t('deviationChangeHistory.createdBy')} {getWorkerName(entry.employeeId)}
              </Typography>
            ) : (
              <Typography>{getWorkerName(entry.employeeId)}</Typography>
            )}
          </Grid>
        </Grid>
        <Typography>{entry.userComment}</Typography>
        {entry.changes.map((change) => (
          <HistoryChangeElement change={change} key={change.key} />
        ))}
        {entry.files &&
          entry.files.map((file) => (
            <Typography className={styles.wrapIcon} key={file.filename}>
              <FileIcon fileName={file.filename} />
              {file.filename}
            </Typography>
          ))}
      </Paper>
    );
  };

  return (
    <Paper variant="outlined" className={styles.root}>
      <Grid container alignItems="center" onClick={() => setOpen(!open)}>
        <Grid item xs={11} style={{ textTransform: 'uppercase' }}>
          {t('deviationChangeHistory.title')}
        </Grid>
        <Grid item xs={1}>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Grid>
      </Grid>
      <Collapse in={open} unmountOnExit>
        {history.map((entry, index) => (
          <HistoryElement
            entry={entry}
            last={index === history.length - 1}
            key={`history-${entry.modifiedAt}`}
          />
        ))}
      </Collapse>
    </Paper>
  );
};

export default DeviationChangeHistory;
