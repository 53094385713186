import { theme as koneTheme } from '@konecorp/ui-library';
import { CircularProgress, Modal } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { CALCULATED_IMAGE_CONTAINER_HEIGHT } from '../../containers/QDQuestion';
import { get } from '../../helpers/fetch';
import { useGetToken } from '../../hooks/useGetToken';

export interface QDQuestionImageProps {
  imageLink: string | null;
}

const QDQuestionImage = (props: QDQuestionImageProps): JSX.Element => {
  const useStyles = makeStyles((koneTheme) =>
    createStyles({
      loading: {
        height: '50vmin', // reserve some space even while image is loading
      },
      image: {
        width: '100%',
        maxWidth: '600px',
        // Keeping some reserved space for image to load and prevent jumping of buttons
        height: `calc(${CALCULATED_IMAGE_CONTAINER_HEIGHT} - ${koneTheme.spacing(1)}px)`,
      },
      imagePopup: {
        margin: koneTheme.spacing(1),
        width: '100%',
        maxHeight: '90%',
        backgroundColor: koneTheme.palette.background.default,
        borderRadius: koneTheme.shape.borderRadius * 4,
      },
      modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
    })
  );

  const classes = useStyles(koneTheme);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageLinks, setimageLinks] = useState<string[]>([]);
  const [openSecondImageModal, setOpenSecondImageModal] = useState<boolean>(false);
  const [getTokenFunction] = useGetToken();

  useEffect(() => {
    let isComponentMounted = true;
    if (props.imageLink) {
      const fetchInstallationImages = async () => {
        setIsLoading(true);
        const accessToken = await getTokenFunction();
        const imageLinks: string[] | null = await get(
          `v1/installationimages/${props.imageLink}`,
          accessToken
        );

        if (isComponentMounted) {
          if (Array.isArray(imageLinks)) setimageLinks(imageLinks);
          setIsLoading(false);
        }
      };
      setOpenSecondImageModal(false);
      fetchInstallationImages();
    }
    return () => {
      setimageLinks([]);
      isComponentMounted = false;
    };
  }, [props.imageLink]);

  const handlePictureOnClick = () => {
    if (imageLinks.length > 1) setOpenSecondImageModal(true);
  };

  if (!props.imageLink) {
    return <></>;
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div>
      <img
        onClick={handlePictureOnClick}
        className={classes.image}
        data-testid="question-image"
        src={imageLinks && imageLinks[0]}
      />
      {imageLinks.length > 1 && (
        <Modal
          className={classes.modal}
          open={openSecondImageModal}
          onClose={() => {
            setOpenSecondImageModal(false);
          }}
          onClick={() => {
            setOpenSecondImageModal(false);
          }}
        >
          <img
            className={classes.imagePopup}
            src={imageLinks[1]}
            data-testid="second-question-image"
          />
        </Modal>
      )}
    </div>
  );
};

export default QDQuestionImage;
