import { useContext } from 'react';
import Context from '../context/index';
import { ActivityDifferentiator, InstallationStatus } from '../schemas';
import { useGetCurrentUserRole } from './useGetCurrentUserRole';

export const useIfSEBSupervisorReadOnly = (): [boolean] => {
  const { installationData } = useContext(Context);

  const [userRole] = useGetCurrentUserRole();

  const isSebAcceptedOrRejected =
    installationData?.status === InstallationStatus.SEB_ACCEPTED ||
    installationData?.status === InstallationStatus.SEB_REJECTED ||
    installationData?.status === InstallationStatus.INSTALLATION_COMPLETE ||
    installationData?.status === InstallationStatus.INSTALLATION_ARCHIVED;

  return [userRole === ActivityDifferentiator.SEEN && isSebAcceptedOrRejected];
};
