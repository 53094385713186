import React, { useEffect, useState, useCallback } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loading from '../../components/Loading';
import {
  getByObjId,
  getBySelectionAndValue,
  ResultFilterTypes,
} from '../../helpers/installationTasks';
import Empty from '../Empty';
import WorkFlowTasks, { WorkFlowTaskProps } from '../WorkFlowTasks';
import { getLocale, getFallbackLocale } from '../../localization';
import { useGetToken } from '../../hooks/useGetToken';

export interface TacoConfigurationDescriptionProps {
  code: string;
  text: string;
}

export type InstallationTaskAttributeValues = {
  description: string;
  eqCity: string;
  eqCustname: string;
  eqPostcode: string;
  eqStreet: string;
  sapOrderId: string;
  sapOrderPosition: string;
  tacoConfigurationId: string;
  tacoConfigurationDescription: TacoConfigurationDescriptionProps[];
  tacoManningType: 'team' | 'single' | 'flex';
  tacoProjectNumber: string;
  tacoSupervisor: string;
  tacoWorkdayLength: string;
  typFlNetwork: string;
};

export interface InstallationTask {
  attributeValues: InstallationTaskAttributeValues;
  characteristics: object[];
  forms: object[];
  koneEqNum: string;
  objId: string;
  structure: {
    workFlowTasks: WorkFlowTaskProps[];
    endObjectAttributeValues: {
      tacoPlannedHours: string;
    };
  };
}

interface QueryParams {
  networkNumber: string;
}

interface TacoDataParams {
  showMyPlan: boolean | undefined;
}

const InstallationTaskView = (props: TacoDataParams): JSX.Element => {
  const { t } = useTranslation();
  const [getTokenFunction] = useGetToken();
  const { networkNumber } = useParams() as QueryParams;
  const [data, setData] = useState<InstallationTask>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchDataBySelection = useCallback(async (value: string): Promise<void> => {
    try {
      setIsLoading(true);
      const accessToken = await getTokenFunction();

      const objIds = await getBySelectionAndValue(
        ResultFilterTypes.TypFlNetwork,
        value,
        accessToken
      );
      const response = await Promise.all(
        objIds.map(async (objId: string) => await getByObjId(objId, accessToken))
      );

      if (response.length > 0) {
        setData(response[0]);
      }
    } catch (error) {
      console.error('fetchDataBySelection', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (networkNumber && !data) {
      fetchDataBySelection(networkNumber);
    }
  }, [networkNumber, data, fetchDataBySelection]);

  if (isLoading) {
    return <Loading />;
  }

  const renderMainContent = (installationTask: InstallationTask) => {
    let localizedDescription =
      installationTask.attributeValues.tacoConfigurationDescription.find(
        (item: TacoConfigurationDescriptionProps) => item.code === getLocale().short
      );

    if (!localizedDescription) {
      localizedDescription =
        installationTask.attributeValues.tacoConfigurationDescription.find(
          (item: TacoConfigurationDescriptionProps) =>
            item.code === getFallbackLocale().short
        );
    }

    const dayLengthInHours = installationTask.attributeValues.tacoWorkdayLength
      ? Number(installationTask.attributeValues.tacoWorkdayLength) / 60
      : 8;

    return (
      <Paper elevation={0}>
        <Box p={2}>
          <Typography>
            {t('myDay.manningType.name')}
            {': '}
            {t(`myDay.manningType.${installationTask.attributeValues.tacoManningType}`)}
          </Typography>
        </Box>
        <WorkFlowTasks
          data={installationTask.structure.workFlowTasks}
          dayLengthInHours={dayLengthInHours}
        />
      </Paper>
    );
  };
  const showMyPlan = props.showMyPlan;
  return (
    <Box mt="1rem">
      {showMyPlan ? (
        <Empty displayIcon={false} message={t('myDay.noTacoData')} />
      ) : data ? (
        renderMainContent(data)
      ) : (
        <Empty displayIcon={false} message={t('myDay.noNetwork')} />
      )}
    </Box>
  );
};

export default InstallationTaskView;
