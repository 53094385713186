import { useContext } from 'react';
import Context from '../context/index';
import { useParams } from 'react-router-dom';

import { useIfSubcontractor } from './useIfSubcontractor';
import { subcontractorAcquireToken } from '../helpers/subContractorAuthentication';

export type QueryParams = {
  networkNumber: string;
};

type GetTokenFunction = () => Promise<string>;

// NOTE to remember, this hook is mocked in jest.setup.ts for all the test suits
// otherwise in every components that require token, they will need to be wrapped within the <Router>
// execpt we are not mocking this hook useGetToken.test.txs ( which we actually test this hook)
export const useGetToken = (): [GetTokenFunction] => {
  const [isSubcontractor] = useIfSubcontractor();
  const { networkNumber } = useParams<QueryParams>();

  const { acquireToken } = useContext(Context);

  const getAccessToken = async () => {
    if (isSubcontractor) {
      if (networkNumber) {
        const token = subcontractorAcquireToken(networkNumber);
        return token ? `GUID ${networkNumber} ${token}` : '';
      } else {
        console.error(
          'Cannot find network number from the params while trying to get token for subcontractor'
        );
        return Promise.resolve('');
      }
    }

    if (acquireToken) {
      const response = await acquireToken(true);
      const token = response ? `Bearer ${response.accessToken}` : '';
      return token;
    } else {
      console.error(
        'There is no acquireToken in context while trying to get token for KONE employee'
      );
      return Promise.resolve('');
    }
  };

  return [getAccessToken];
};
