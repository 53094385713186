import React, { useContext, useEffect, useState } from 'react';
import FileDownloadIcon from '@material-ui/icons/GetApp';
import { Box, CardMedia, createStyles, Link, makeStyles } from '@material-ui/core';
import Context from '../../context';
import {
  FileType,
  getDownloadSignedUrlForAttachment,
  getDownloadSignedUrlForDeviationsAttachment,
} from '../../helpers/upload-download';
import { useTranslation } from 'react-i18next';
import { Attachment } from '../../schemas';
import { isAudioFile, isImageFile, isVideoFile } from '../../helpers/allowedFileTypes';
import { useGetToken } from '../../hooks/useGetToken';

const useStyles = makeStyles((theme) =>
  createStyles({
    previewImage: {
      width: '100%',
      height: '100%',
    },
    downloadLink: {
      margin: theme.spacing(1),
    },
  })
);

const PreviewFile = (props: {
  file: File | Attachment;
  fileType: FileType;
}): JSX.Element => {
  const { file, fileType } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { updateIsLoading } = useContext(Context);
  const [fileToShow, setFileToShow] = useState<string | undefined>();
  const [getTokenFunction] = useGetToken();

  useEffect(() => {
    if ((file as Attachment).location) {
      (async () => {
        try {
          updateIsLoading(true);
          const accessToken = await getTokenFunction();

          const downloadSignedUrls =
            fileType === FileType.ATTACHMENT
              ? await getDownloadSignedUrlForAttachment({
                  jwtToken: accessToken,
                  files: [file as Attachment],
                })
              : await getDownloadSignedUrlForDeviationsAttachment({
                  jwtToken: accessToken,
                  files: [file as Attachment],
                });
          if (downloadSignedUrls && downloadSignedUrls.length > 0) {
            setFileToShow(downloadSignedUrls[0].signedUrl);
          }
        } catch (e) {
          console.error('Error while downloading file', e);
        } finally {
          updateIsLoading(false);
        }
      })();
    } else {
      const fileUrl = URL.createObjectURL(file as File);
      setFileToShow(fileUrl);
    }
  }, []);

  const fileName: string = (
    (file as File).name || (file as Attachment).filename
  ).toLowerCase();

  const renderContent = (fileName: string) => {
    if (isImageFile(fileName))
      return (
        <img
          className={classes.previewImage}
          src={fileToShow}
          alt=""
          data-testid="preview-file"
        />
      );
    if (isVideoFile(fileName))
      return (
        <CardMedia
          component="video"
          image={fileToShow}
          autoPlay
          data-testid="preview-file"
        />
      );
    if (isAudioFile(fileName))
      return (
        <CardMedia
          component="audio"
          image={fileToShow}
          autoPlay
          data-testid="preview-file"
        />
      );

    return t('uploadWidget.noPreview');
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="end">
      {renderContent(fileName)}
      <Link
        className={classes.downloadLink}
        download={fileName}
        href={fileToShow}
        color="primary"
        aria-label="download-file"
        data-testid="download-link"
      >
        <FileDownloadIcon />
      </Link>
    </Box>
  );
};

export default PreviewFile;
