import React from 'react';
import { Box, createStyles, Typography } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import makeStyles from '@material-ui/styles/makeStyles';
import { theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

import { useCheckConnection } from '../../hooks/useCheckConnection';
import { useCheckDataSync } from '../../hooks/useCheckDataSync';

const useStyles = makeStyles(() =>
  createStyles({
    connectionBanner: {
      background: 'white',
      width: '100%',
      height: '10px',
      fontSize: 0,
      color: theme.palette.background.paper,
      textAlign: 'center',
      paddingTop: theme.spacing(2.5),
    },
    offline: {
      height: 'fit-content',
      visibility: 'visible',
      fontSize: '20px',
    },
    redText: {
      color: theme.palette.error.dark,
    },
  })
);

const ConnectStatusBanner = (): JSX.Element => {
  const { t } = useTranslation();
  const [isOnline] = useCheckConnection();
  const { hasToSync } = useCheckDataSync();

  const classes = useStyles(theme);
  if (isOnline) {
    return <></>;
  }

  return (
    <Grow in={!isOnline} {...(!isOnline ? { timeout: 1000 } : {})}>
      <Box
        role="alert"
        data-testid="connection-banner"
        className={`${classes.connectionBanner} ${classes.offline}`}
      >
        <Typography className={classes.redText}>
          {hasToSync
            ? t('connection.offlineModeDataToSync')
            : t('connection.offlineModeNoDataToSync')}
        </Typography>
      </Box>
    </Grow>
  );
};

export default ConnectStatusBanner;
