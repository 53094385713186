import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { NaviClose } from '@konecorp/ui-library';

import Context from '../../context';
import { VersionInfo as VersionInfoSchema } from '../../schemas';
import { useGetToken } from '../../hooks/useGetToken';
import { get } from '../../helpers/fetch';
import { formatDate } from '../../helpers/formating';

const useStyles = makeStyles(() =>
  createStyles({
    closeIcon: {
      position: 'absolute',
      right: '10px',
      width: 40,
      height: 40,
    },
    dialogPaper: {
      height: '80%',
    },
    releaseNoteBox: {
      border: '1px solid',
      background: 'rgb(244,244,244)',
      padding: '15px',
      borderColor: 'rgb(240,240,240)',
    },
  })
);

export type VersionInfoContentProps = {
  versionInfo: VersionInfoSchema | undefined;
};

export type SettingsProps = {
  open: boolean;
  onClose: () => void;
};

export const VersionInfoContent = ({
  versionInfo,
}: VersionInfoContentProps): React.ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!versionInfo) return <></>;

  return (
    <Box>
      <Typography variant="body1">
        {t('versionInfo.iesVersion', { version: versionInfo?.version })}
      </Typography>
      <Typography variant="body1">
        {t('versionInfo.releaseDate', {
          releaseDate: formatDate(versionInfo?.releaseDate),
        })}
      </Typography>

      <Box pt={3}>
        <Typography variant="body1">{t('versionInfo.releaseNote')}</Typography>

        {versionInfo && (
          <Box className={classes.releaseNoteBox}>
            {versionInfo.newFeatures.map((newFeature, index) => (
              <Box key={`${index}`} marginBottom={4}>
                <Typography>{`${newFeature.title}:`}</Typography>

                {newFeature.description.map((detail, index) => (
                  <Typography
                    key={`${newFeature.title}-detail-${index}`}
                  >{`* ${detail}`}</Typography>
                ))}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const VersionInfo = (props: SettingsProps): JSX.Element => {
  const { open, onClose } = props;
  const classes = useStyles();

  const [versionInfo, setVersionInfo] = useState<VersionInfoSchema | undefined>(
    undefined
  );
  const { updateIsLoading } = useContext(Context);
  const { t, i18n } = useTranslation();
  const [getToken] = useGetToken();

  useEffect(() => {
    (async () => {
      updateIsLoading(true);
      if (window.navigator.serviceWorker?.controller) {
        const registration = await window.navigator.serviceWorker.ready;

        // manually trigger to tell browser to check for new FE version
        await registration.update();
      }

      const token = await getToken();
      const versionInfo = (await get(
        `v1/applications/ies-app/versions/${i18n.language}`, //ex: en-US
        token
      )) as VersionInfoSchema;

      setVersionInfo(versionInfo);

      updateIsLoading(false);
    })();
  }, []);

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }} fullWidth>
      <DialogTitle>
        {t('versionInfo.title')}
        <NaviClose className={classes.closeIcon} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <VersionInfoContent versionInfo={versionInfo} />
      </DialogContent>
      <DialogActions>
        <Box width="100%" p={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            data-testid="save-button"
            onClick={onClose}
          >
            {t('versionInfo.ok')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default VersionInfo;
