import { i18n } from 'i18next';
import { LocalizedText } from '../schemas';

export const getDefaultLanguageCode = (i18n: i18n): string => i18n.languages?.[0] ?? 'en';

// LocalizedText is a data structure from TACO
export const findLocalizedText = (
  languageCode: string,
  localizedTextArray?: LocalizedText[] | null
): LocalizedText | undefined =>
  localizedTextArray?.find((text) => text.code === languageCode);

export const getLocalizedText = (
  i18n: i18n,
  localizedTextArray?: LocalizedText[] | null
): LocalizedText | undefined => {
  const languageCode = getDefaultLanguageCode(i18n);
  const [shortCode, longCode] = languageCode.includes('-')
    ? [languageCode.slice(0, 2), languageCode]
    : [languageCode, undefined];

  return (
    (longCode ? findLocalizedText(longCode, localizedTextArray) : undefined) ??
    findLocalizedText(shortCode, localizedTextArray) ??
    findLocalizedText('en', localizedTextArray)
  );
};
export type I18n = { [key: string]: string | I18n | undefined };

/** Return true if a given key exists in language translation json */
export const validateI18nKey = (key: string, json: I18n | undefined): boolean => {
  if (json === undefined) return false;

  const firstKey = key.split('.')[0];
  const value = json[firstKey];

  if (typeof value === 'string') return true;
  const restKey = key.slice(firstKey.length + 1);

  return validateI18nKey(restKey, value);
};
