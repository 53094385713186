import React, { useContext, useState } from 'react';
import { Fab, Badge, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Sync } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { InfoModal, theme } from '@konecorp/ui-library';
import Context from '../../context';
import { useCheckDataSync } from '../../hooks/useCheckDataSync';
import { useGetToken } from '../../hooks/useGetToken';
import { syncWithBackend as manualSyncAnswers } from '../../helpers/syncHelper';
import { manualSyncFromUI as manualSyncDeviation } from '../../helpers/deviationOffline';
import { useCheckConnection } from '../../hooks/useCheckConnection';

const FloatingSyncButton = (): JSX.Element => {
  const [showOfflineModal, setShowOfflineModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const { isLoading, updateIsLoading, networkNumber } = useContext(Context);
  const { hasToSync, setPending } = useCheckDataSync();
  const [getTokenFunction] = useGetToken();
  const [isOnline] = useCheckConnection();

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        position: 'fixed',
        left: 'calc(100vw - 75px)',
        [theme.breakpoints.up('md')]: {
          left: 'calc(100vw - 95px)',
        },
        //bottom menu has a fixed position 0 0 and has a height of 84px so we want the button float a bit higher than the bottom menu
        bottom: '95px',
        zIndex: 10, //this should be higher than most stuff but lower than the mui modal
      },
      fabButton: {
        backgroundColor: 'white',
      },
    })
  );

  const handleSync = async () => {
    if (!isOnline) {
      setShowOfflineModal(true);
      return;
    }

    try {
      const accessToken = await getTokenFunction();

      if (hasToSync) {
        updateIsLoading(true);

        await Promise.all([
          manualSyncAnswers(networkNumber, accessToken),
          manualSyncDeviation(networkNumber, accessToken),
        ]);

        setPending(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      updateIsLoading(false);
    }
  };

  const classes = useStyles(theme);

  if (isLoading || !hasToSync) {
    return <></>;
  }

  return (
    <>
      <Box
        className={classes.root}
        onClick={handleSync}
        data-testid="floating-sync-button"
      >
        <Badge color="primary" badgeContent="sync" variant="standard">
          <Fab size="large" className={classes.fabButton}>
            <Sync />
          </Fab>
        </Badge>
      </Box>

      {showOfflineModal && (
        <InfoModal
          open={true}
          message={t('connection.offlineAlert')}
          onClose={(): void => setShowOfflineModal(false)}
          closeButtonText={t('connection.ok')}
          isCenteredMessage
        />
      )}
    </>
  );
};

export default FloatingSyncButton;
