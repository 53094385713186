import React from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { formatDate } from '../../helpers/formating';
import { theme } from '@konecorp/ui-library';
import i18next from 'i18next';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';

export interface NetworkSummaryProps {
  targetHours: number;
  assignmentEndDate: string;
  openDeviationCount: number;
}

const useStyles = makeStyles((t) =>
  createStyles({
    root: {
      textAlign: 'center',
      border: `1px solid ${t.palette.grey[500]}`,
      borderRadius: t.shape.borderRadius,
      '& > div': {
        padding: t.spacing(1),
        '&:not(:last-child)': {
          borderBottom: `1px solid ${t.palette.grey[500]}`,
        },
      },
    },
    targetHoursGridItem: {
      borderRight: `1px solid ${t.palette.grey[500]}`,
    },
    label: {
      fontSize: theme.typography.fontSize * 0.9,
      color: t.palette.grey[500],
    },
  })
);

const NetworkSummary = (props: NetworkSummaryProps): JSX.Element => {
  const { targetHours, assignmentEndDate, openDeviationCount } = props;
  const classes = useStyles(theme);
  const endDate = formatDate(assignmentEndDate);
  const [isSubcontractor] = useIfSubcontractor();

  return (
    <Container maxWidth={false} data-testid="network-summary">
      <Grid container className={classes.root} justify="center">
        {!isSubcontractor && (
          <Grid
            item
            xs={6}
            className={classes.targetHoursGridItem}
            aria-label="target-hours-gridItem"
          >
            <Typography variant="subtitle1" className={classes.label}>
              {i18next.t('networkSummary.targetHoursLabel')}
            </Typography>
            <Typography variant="h5">{targetHours}</Typography>
          </Grid>
        )}
        <Grid item xs={isSubcontractor ? 12 : 6}>
          <Typography variant="subtitle1" className={classes.label}>
            {i18next.t('networkSummary.assignmentEndDateLabel')}
          </Typography>
          <Typography variant="h5">{endDate ?? '—'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.label}>
            {i18next.t('networkSummary.openDeviationCountLabel')}
          </Typography>
          <Typography variant="h5">{openDeviationCount}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NetworkSummary;
