import { Attachment, Deviation, DeviationStatus } from '../schemas';
import { API_TYPE, get, patch, post } from './fetch';
import { FileType, uploadAttachments } from './upload-download';
import {
  CreateDeviationPayload,
  DeviationFormData,
  EditDeviationPayload,
} from '../components/DeviationForm';

export const closeDeviation = async (
  accessToken: string,
  networkNumber: string,
  deviation: Deviation
): Promise<Deviation> =>
  await patch(
    `v1/deviations/${networkNumber}/${deviation.guid}`,
    accessToken,
    API_TYPE.APPLICATION,
    { status: DeviationStatus.CLOSED }
  );

const isFile = (f: File | Attachment): f is File => 'name' in f;

const uploadFiles = async (
  accessToken: string,
  networkNumber: string,
  deviation: DeviationFormData
) => {
  const filesToBeUploaded = deviation.files.filter(isFile);

  return await uploadAttachments(
    {
      networkNumber,
      jwtToken: accessToken,
      files: filesToBeUploaded,
    },
    FileType.DEVIATION
  );
};

export const createDeviation = async (
  accessToken: string,
  networkNumber: string,
  deviation: CreateDeviationPayload
): Promise<Deviation> => {
  const uploadedAttachments =
    deviation.files.length === 0
      ? []
      : await uploadFiles(accessToken, networkNumber, deviation);

  const postBody = {
    assignee: deviation.assignee,
    blocker: deviation.blocker,
    compliance: deviation.compliance,
    delay: deviation.delay,
    ken: deviation.ken,
    phase: deviation.phase,
    location: deviation.location,
    questionSetId: deviation.questionSetId,
    questionSequence: deviation.questionSequence,
    installationWorkflowStatus: deviation.installationWorkflowStatus,
    source: deviation.source,
    variation: deviation.variation,
    status: deviation.status,
    type: deviation.type,
    description: deviation.description,
    userComment: deviation.userComment,
    createdBy: deviation.createdBy,
    files: uploadedAttachments,
  };

  return await post(
    `v1/deviations/${networkNumber}`,
    accessToken,
    API_TYPE.APPLICATION,
    postBody
  );
};

export const editDeviation = async (
  accessToken: string,
  networkNumber: string,
  deviation: EditDeviationPayload
): Promise<Deviation> => {
  const uploadedAttachments =
    deviation.files.length === 0
      ? []
      : await uploadFiles(accessToken, networkNumber, deviation);

  const patchBody = {
    description: deviation.description,
    assignee: deviation.assignee,
    blocker: deviation.blocker,
    compliance: deviation.compliance,
    delay: deviation.delay,
    status: deviation.status,
    userComment: deviation.userComment,
    closedAt:
      deviation.status === DeviationStatus.CLOSED
        ? new Date(Date.now()).toISOString()
        : '',
    closedBy: deviation.closedBy,
    type: deviation.type,
    files: uploadedAttachments,
  };

  return await patch(
    `v1/deviations/${networkNumber}/${deviation.guid}`,
    accessToken,
    API_TYPE.APPLICATION,
    patchBody
  );
};

/**
 * A helper function
 * @param accessToken
 * @param networkNumber
 * @returns All deviations data for a network
 */
export const getDeviationsData = async (
  accessToken: string,
  networkNumber: string
): Promise<Deviation[]> => {
  return await get(
    `v1/deviations?networkNumber=${networkNumber}`,
    accessToken,
    API_TYPE.APPLICATION
  );
};
