import React, { PropsWithChildren } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '@konecorp/ui-library';

import { useTranslation } from 'react-i18next';
import { Answer, PlumbingMeasurementCategory, Question } from '../../schemas';
import {
  useGetFloorOrientation,
  FloorOrientation,
} from '../../hooks/useGetFloorOrientation';

const PLUMBING_QUESTIONSET_ID = 'TACO01001';

export const StyledTableHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  root: {
    padding: theme.spacing(1),
  },
}))(TableCell);

// Caution! Do not change. This is always in this order,
export const PLUMBING_MEASUREMENTS = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6'];

export const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    borderBottom: 0,
    color: theme.palette.primary.dark,
  },
}))(TableCell);

const StyledTableErrorCell = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    border: `2px solid ${theme.palette.error.dark}`,
    color: theme.palette.primary.dark,
  },
}))(TableCell);

const StyledTableWarningCell = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    border: `2px solid ${theme.palette.warning.main}`,
    color: theme.palette.primary.dark,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[200],
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const usePlumbingSummaryTableStyles = makeStyles((theme) => ({
  table: {
    maxWidth: '100%',
  },
  tableContainer: {
    maxWidth: '100%',
    padding: theme.spacing(0.2),
  },
  tableCellFirstColumn: {
    color: theme.palette.secondary.main,
    width: theme.spacing(10),
    borderBottom: theme.spacing(0),
  },
}));

enum CellType {
  ERROR = 'error',
  WARNING = 'warning',
}

/**
 *
 * @param answer
 * @returns true when value is out of the range
 */
const checkIsErrorOrWarning = (answer: PlumbingSummaryAnswer): CellType | undefined => {
  if (answer) {
    // 3mm plus/minus range #TASK 124695
    const WARNING_THRESHOLD = 3;
    const { value, actualValue, negativeTolerance, positiveTolerance } = answer;
    const referenceValue = actualValue ?? '';
    const answerValue = value ? value : 0;
    const minPossibleValue: number =
      parseFloat(referenceValue) - parseFloat(negativeTolerance || '');
    const maxPossibleValue: number =
      parseFloat(referenceValue) + parseFloat(positiveTolerance || '');

    const isValueOutOfRange =
      answerValue < minPossibleValue || answerValue > maxPossibleValue;

    const isValueAboutToGoOutOfRange =
      !isValueOutOfRange &&
      (answerValue < minPossibleValue + WARNING_THRESHOLD ||
        answerValue > maxPossibleValue - WARNING_THRESHOLD);

    // when answer/value/referenceValue is not available.
    const isError: boolean = isValueOutOfRange || !value || !referenceValue;
    const isWarning: boolean = isValueAboutToGoOutOfRange;

    if (isError) return CellType.ERROR;
    else if (isWarning) return CellType.WARNING;
    else return;
  }
  return;
};

/**
 * Below function will contain the logic to check for miscalculations and
 * @returns style specific component
 */
const renderTableCell = (answer: PlumbingSummaryAnswer, index: number): JSX.Element => {
  const { value, gsi1, actualValue } = answer;
  const displayValue =
    (value && typeof value === 'number' ? value : 0) - parseFloat(actualValue || '0');
  const isErrorOrWarning = checkIsErrorOrWarning(answer);
  if (isErrorOrWarning) {
    const HighlightedCell =
      isErrorOrWarning === CellType.WARNING
        ? StyledTableWarningCell
        : StyledTableErrorCell;
    return (
      <HighlightedCell
        data-testid={`plumbing-summary-table-cell-${gsi1}`}
        key={`summary-table-cell-${index}-${index + 1}`}
        align="center"
      >
        {value ? displayValue : '—'}
      </HighlightedCell>
    );
  }
  return (
    <StyledTableCell
      data-testid={`plumbing-summary-table-cell-${gsi1}`}
      key={`summary-table-cell-${index}-${index + 1}`}
      align="center"
    >
      {displayValue}
    </StyledTableCell>
  );
};

// Special type to contain both answers and question related fields.
export interface PlumbingSummaryAnswer
  extends Answer,
    Partial<Omit<Question, 'position' | 'modifiedAt'>> {}

export type PlumbingSummaryTableProps = {
  summaryTableData: PlumbingSummaryAnswer[][];
  plumbingMeasurementPoints: PlumbingMeasurementCategory;
};

const PlumbingSummaryTable = (
  props: PropsWithChildren<PlumbingSummaryTableProps>
): JSX.Element => {
  /**
   * For now we are having hardcoded values for the Summary table column headers (1 + 6 columns)
   * Later when we have this data available, we can make this dynamic
   */
  const { t } = useTranslation();
  const [getFloorOrientation] = useGetFloorOrientation();

  const { summaryTableData, plumbingMeasurementPoints } = props;
  const tableHeaders = [t('plumbingSummary.tableHeader'), ...plumbingMeasurementPoints];
  const classes = usePlumbingSummaryTableStyles(theme);

  const floorOrientation = getFloorOrientation(PLUMBING_QUESTIONSET_ID);

  return (
    <TableContainer
      className={classes.tableContainer}
      data-testid="plumbing-summary-table"
    >
      <Table className={classes.table} aria-label="summary table">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <StyledTableHeadCell key={`summary-table-th-${index}`} align="center">
                {header}
              </StyledTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryTableData.map((floor, index, summaryData) => {
            return (
              <StyledTableRow key={`plumbing-row-${index}`}>
                <>
                  <StyledTableCell
                    key={`summary-table-cell-${index}-0`}
                    data-testid={`summary-table-floor-info-cell-${index}`}
                    className={classes.tableCellFirstColumn}
                    align="center"
                  >
                    {floorOrientation === FloorOrientation.TOP_TO_BOTTOM
                      ? summaryData.length - index
                      : index + 1}
                  </StyledTableCell>
                  {floor.map((answer, floorIndex) =>
                    renderTableCell(answer as Answer, floorIndex)
                  )}
                </>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlumbingSummaryTable;
