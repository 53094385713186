import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Collapse, Grid, IconButton, Link, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from '@material-ui/icons';

import { theme } from '@konecorp/ui-library';

const useStyles = makeStyles(() =>
  createStyles({
    'row-level-1': {
      backgroundColor: '#005A93',
      color: '#FFFFFF',
      '& p': {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
      '& .MuiIconButton-root': {
        paddingTop: theme.spacing(1),
        color: '#FFFFFF',
      },
      height: theme.typography.fontSize * 3,
    },
    'row-odd': {
      backgroundColor: '#F5F6F7',
      minHeight: theme.typography.fontSize * 3,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    'row-even': {
      backgroundColor: '#FFFFFF',
      minHeight: theme.typography.fontSize * 3,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
  })
);

type OtherToolsProps = {
  equipmentNumber: string;
};

type LinkData = { name: string; url: string };

const getLinks = (equipmentNumber: string): LinkData[] => [
  ...(process.env.REACT_APP_TRACE_PDM_URL
    ? [
        {
          name: 'TracePDM',
          url: `${process.env.REACT_APP_TRACE_PDM_URL}/equipment/${equipmentNumber}`,
        },
      ]
    : []),
  ...(process.env.REACT_APP_LINK_TO_TIP
    ? [{ name: 'TIP', url: process.env.REACT_APP_LINK_TO_TIP }]
    : []),
  ...(process.env.REACT_APP_LINK_TO_QFB
    ? [{ name: 'QFB', url: process.env.REACT_APP_LINK_TO_QFB }]
    : []),
  ...(process.env.REACT_APP_LINK_TO_QIM
    ? [{ name: 'QIM', url: process.env.REACT_APP_LINK_TO_QIM }]
    : []),
  ...(process.env.REACT_APP_LINK_TO_TACO
    ? [{ name: 'TACO', url: process.env.REACT_APP_LINK_TO_TACO }]
    : []),
  ...(process.env.REACT_APP_LINK_TO_WORKDAY
    ? [{ name: 'Workday', url: process.env.REACT_APP_LINK_TO_WORKDAY }]
    : []),
];

const OtherTools = (props: OtherToolsProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { equipmentNumber } = props;

  const [open, setOpen] = useState(false);
  const [links, setLinks] = useState<LinkData[]>([]);

  const chevron = open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

  useEffect(() => {
    setLinks(getLinks(equipmentNumber));
  }, [equipmentNumber]);

  return (
    <>
      <Grid
        data-testid="other-tools-header"
        className={classes['row-level-1']}
        container
        onClick={() => setOpen(!open)}
      >
        <Grid item xs={11}>
          <Typography>{t('otherTools.header')}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="expand row" size="small">
            {chevron}
          </IconButton>
        </Grid>
      </Grid>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {links.map((link, i) => (
          <Grid
            container
            className={classes[i % 2 ? 'row-even' : 'row-odd']}
            key={`link-${i}`}
          >
            <Grid item xs={12}>
              <Link href={link.url} target={'_blank'}>
                <Box display={'flex'}>
                  <Typography>{link.name}</Typography>
                  <OpenInNew />
                </Box>
              </Link>
            </Grid>
          </Grid>
        ))}
      </Collapse>
    </>
  );
};

export default OtherTools;
