import { useContext } from 'react';

import Context from '../context';
import { ActivityDifferentiator, SpecialUserIds } from '../schemas';
import { getSubcontractorUserRole } from '../helpers/getUserRole';

export const useGetUserData = (): [string] => {
  const { authState, networkNumber } = useContext(Context);

  const employeeId = authState.graph?.profile?.employeeId;

  if (employeeId) {
    return [employeeId];
  } else {
    const role = getSubcontractorUserRole(networkNumber);
    const subcontractorId = role ? getSubcontractorId(role) : '';
    return [subcontractorId];
  }
};

export const getSubcontractorId = (role: ActivityDifferentiator): SpecialUserIds => {
  switch (role) {
    case ActivityDifferentiator.INST:
      return SpecialUserIds.SUBCONTRACTOR_INSTALLER;
    case ActivityDifferentiator.CMSN:
      return SpecialUserIds.SUBCONTRACTOR_TESTER;
    default:
      throw new Error(`Unsupported subcontractor role: ${role}`);
  }
};
