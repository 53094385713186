import React, { PropsWithChildren } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Inbox } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

export interface EmptyProps {
  message?: string;
  displayIcon?: boolean;
  ariaLabel?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '40vh',
    },
    icon: {
      fontSize: 60,
    },
  })
);

const Empty = ({
  message,
  displayIcon = true,
  ariaLabel = 'empty-component',
}: PropsWithChildren<EmptyProps>): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      aria-label={ariaLabel}
    >
      {displayIcon && (
        <Grid item>
          <Inbox color="secondary" className={classes.icon} />
        </Grid>
      )}
      <Grid item>
        <Typography align="center" variant="h6" color="secondary">
          {message || t('notifications.noData')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Empty;
