import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CONTAINER_HEIGHT } from '../../containers/NetworkInstallation';
import NavigationButtons from '../NavigationButtons';
import { get } from '../../helpers/fetch';
import Context from '../../context';
import HandoverSummaryPreview from '../HandoverSummaryPreview';
import { Handover } from '../../schemas';
import { useGetToken } from '../../hooks/useGetToken';

export type PreviewHandoverProps = {
  onNavigateForward?: () => void;
};

const PreviewHandover = (props: PreviewHandoverProps): JSX.Element => {
  const { t } = useTranslation();
  const { onNavigateForward } = props;
  const { networkNumber, updateIsLoading } = useContext(Context);
  const [handover, setHandover] = useState<Handover | null>(null);
  const [getTokenFunction] = useGetToken();

  useEffect(() => {
    (async (): Promise<Handover | null> => {
      try {
        updateIsLoading(true);
        const accessToken = await getTokenFunction();
        const handoverData = await get(
          `v1/installations/${networkNumber}/handovers/latest`,
          accessToken
        );
        setHandover(handoverData);
      } catch (error) {
        console.error('error while fetching handover data', error);
      } finally {
        updateIsLoading(false);
      }
      return null;
    })();
  }, []);

  return (
    <Box
      height={CONTAINER_HEIGHT}
      overflow="auto"
      p={3}
      data-testid="preview-handover-container"
    >
      <Box height="95%" display="flex" flexDirection="column">
        <Box width="100%" m="auto">
          <HandoverSummaryPreview handover={handover} />
        </Box>
        <Box display="flex" alignItems="flex-end" mb={5.25} mt={2}>
          <NavigationButtons
            onClickForward={onNavigateForward}
            forwardButtonText={t('qdPage.continueButton')}
            backwardButtonText={t('qdPage.gobackButton')}
            /* This backwardButtonText doesn't show in the UI but is required to
             * render the button's size correctly
             */
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewHandover;
