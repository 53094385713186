import { theme } from '@konecorp/ui-library';
import {
  Box,
  Button,
  ButtonGroup,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { CachedRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { random, times } from 'lodash';
import { emailValidator } from '../../helpers/validators';
import { SubcontractorRecord, Subcontractor, Vendor } from '../../schemas';
import { formatDate } from '../../helpers/formating';
import { DatePicker } from '../DatePicker';
import CustomAutocomplete, { CustomAutocompleteData } from '../CustomAutocomplete';

export type SubcontractorFormProps = {
  showSubcontractorModal: boolean;
  setShowSubcontractorModal: (isOpen: boolean) => void;
  subcontractor: SubcontractorRecord | null;
  vendors: Vendor[];
  onSave: (
    subcontractor: Subcontractor,
    pincode: string,
    plannedStartDate: string,
    plannedEndDate: string
  ) => Promise<void>;
  onRemove: (activityDifferentiator: string) => Promise<void>;
  removeSubcontractorPopup: boolean;
  setRemoveSubcontractorPopup: (isOpen: boolean) => void;
};

const misleadingCharactersReplacements = new Map([
  ['O', '0'],
  ['1', 'L'],
  ['7', 'L'],
  ['5', 'S'],
  ['8', 'B'],
]);

const useStyles = makeStyles(() =>
  createStyles({
    buttonGroup: {
      width: '100%',
      boxShadow: 'none',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    resetIcon: {
      width: 30,
      height: 30,
      right: theme.spacing(1.5),
      top: theme.spacing(1.5),
    },
    resetButton: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      cursor: 'pointer',
      '& span': {
        textTransform: 'uppercase',
        fontSize: '12px',
      },
    },
    dateContainer: {
      display: '-webkit-flex',
    },
    invalidPlannedDateError: {
      fontSize: '0.75rem',
      paddingTop: theme.spacing(1),
    },
  })
);

export const SubcontractorForm = (props: SubcontractorFormProps): JSX.Element => {
  const {
    showSubcontractorModal,
    setShowSubcontractorModal,
    subcontractor,
    vendors,
    onSave,
    onRemove,
    setRemoveSubcontractorPopup,
    removeSubcontractorPopup,
  } = props;

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const setDefaultFormData = (subcontractor: SubcontractorRecord | null) => {
    return {
      vendorNumber: subcontractor?.subcontractor.vendorNumber || '',
      subcontractorName: subcontractor?.subcontractor.name || '',
      subcontractorEmail: subcontractor?.subcontractor.email || '',
      subcontractorPhone: subcontractor?.subcontractor.mobile || '',
      subcontractorKey: '',
      plannedStartDate:
        subcontractor?.plannedStartDate || new Date(Date.now()).toISOString(),
      plannedEndDate: subcontractor?.plannedEndDate || new Date(Date.now()).toISOString(),
    };
  };
  const [formData, setFormData] = useState(setDefaultFormData(subcontractor));

  useEffect(() => {
    subcontractor ? setIsDisabled(true) : setIsDisabled(false);
    return () => {
      setFormData({
        vendorNumber: '',
        subcontractorName: '',
        subcontractorEmail: '',
        subcontractorPhone: '',
        subcontractorKey: '',
        plannedStartDate: '',
        plannedEndDate: '',
      });
    };
  }, []);

  enum errorEnum {
    NONE,
    EMPTY,
    INVALID,
    PASTDATE,
  }

  const [subcontractorEmailError, setSubcontractorEmailError] = useState<errorEnum>(
    errorEnum.NONE
  );
  const [subcontractorPhoneError, setSubcontractorPhoneError] = useState<errorEnum>(
    errorEnum.NONE
  );
  const [plannedDateError, setPlannedDateError] = useState<errorEnum>(errorEnum.NONE);
  const { t } = useTranslation();
  const classes = useStyles(theme);
  const renderButtons = (): JSX.Element => {
    return (
      <>
        {subcontractor ? (
          <Box display="flex" alignItems="center" pl={4} pr={4} pb={2} pt={2}>
            <Grid container>
              <Button
                color="primary"
                data-testid="remove-button"
                variant="contained"
                fullWidth
                onClick={() => setRemoveSubcontractorPopup(true)}
              >
                <Typography>{t('subContractorForm.remove')}</Typography>
              </Button>
            </Grid>{' '}
          </Box>
        ) : null}
        <Box pl={1} pr={1}>
          <Typography component="h6" align="center">
            {t('subContractorForm.subcontractorFormInfo')}
          </Typography>
        </Box>
        <ButtonGroup
          className={classes.buttonGroup}
          color="primary"
          fullWidth
          orientation="horizontal"
          variant="contained"
        >
          <Button
            aria-label="save-sucontractor-button"
            type="submit"
            disabled={isButtonDisabled()}
            onClick={handleSubmit}
          >
            {t('subContractorForm.subcontractorSave')}
          </Button>
          <Button
            color="secondary"
            aria-label="cancel-button"
            onClick={() => {
              setShowSubcontractorModal(false);
            }}
          >
            {t('subContractorForm.subcontractorCancel')}
          </Button>
        </ButtonGroup>
      </>
    );
  };

  const isButtonDisabled = (): boolean => {
    return (
      !formData.subcontractorName.trim() ||
      formData.subcontractorEmail === '' ||
      formData.subcontractorPhone === '' ||
      formData.subcontractorKey === '' ||
      formData.plannedStartDate === '' ||
      formData.plannedEndDate === '' ||
      subcontractorEmailError !== errorEnum.NONE ||
      subcontractorPhoneError !== errorEnum.NONE ||
      plannedDateError !== errorEnum.NONE
    );
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const {
      vendorNumber,
      subcontractorName,
      subcontractorEmail,
      subcontractorPhone,
      subcontractorKey,
      plannedStartDate,
      plannedEndDate,
    } = formData;

    const subcontractor: Subcontractor = {
      vendorNumber,
      name: subcontractorName,
      email: subcontractorEmail,
      mobile: subcontractorPhone,
    };

    await onSave(subcontractor, subcontractorKey, plannedStartDate, plannedEndDate);
  };

  const generateSubcontractorKey = (): void => {
    if (!isDisabled) {
      const generatedPincode = times(7, () => random(35).toString(36));

      const validatedPincode = generatedPincode
        .map((c) => c.toLocaleUpperCase())
        .map((c) => misleadingCharactersReplacements.get(c) || c)
        .join('');
      setFormData({ ...formData, subcontractorKey: validatedPincode });
    }
  };

  const ifPhoneError = subcontractorPhoneError !== errorEnum.NONE;

  const ifEmailError = subcontractorEmailError !== errorEnum.NONE;
  const removeSubcontractor = async () => {
    if (subcontractor?.activityDifferentiator)
      await onRemove(subcontractor.activityDifferentiator);
  };

  const handlePhoneNumberOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNo = event.target.value;
    setFormData({ ...formData, subcontractorPhone: phoneNo });
    if (phoneNo === '') {
      setSubcontractorPhoneError(errorEnum.EMPTY);
      return;
    }
    if (!isValidPhoneNumber(phoneNo)) {
      setSubcontractorPhoneError(errorEnum.INVALID);
      return;
    }
    setSubcontractorPhoneError(errorEnum.NONE);
  };

  const handleEmailOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value.toLowerCase();
    setFormData({ ...formData, subcontractorEmail: email });
    if (email === '') {
      setSubcontractorEmailError(errorEnum.EMPTY);
      return;
    }
    if (!emailValidator(email)) {
      setSubcontractorEmailError(errorEnum.EMPTY);
      return;
    }
    setSubcontractorEmailError(errorEnum.NONE);
  };

  const handleNameOnChange = (value: CustomAutocompleteData) => {
    const vendor = vendors.find(({ vendorNumber }) => vendorNumber === value.id);
    if (!vendor) return;

    setFormData({
      ...formData,
      vendorNumber: value.id,
      subcontractorName: vendor.vendorName1,
    });
  };

  const handleChangeStartDate = (selectedDate: string): void => {
    setFormData({
      ...formData,
      plannedStartDate: selectedDate,
    });
  };

  const handleChangeEndDate = (selectedDate: string): void => {
    setFormData({
      ...formData,
      plannedEndDate: selectedDate,
    });
  };

  useEffect(() => {
    const { plannedStartDate, plannedEndDate } = formData;

    const today = new Date(Date.now());
    today.setUTCHours(0, 0, 0, 0);

    const todayISOString = today.toISOString();

    if (plannedStartDate < todayISOString || plannedEndDate < todayISOString) {
      setPlannedDateError(errorEnum.PASTDATE);
      return;
    }
    if (plannedEndDate < plannedStartDate) {
      setPlannedDateError(errorEnum.INVALID);
    } else {
      setPlannedDateError(errorEnum.NONE);
    }
  }, [formData.plannedStartDate, formData.plannedEndDate]);

  if (showSubcontractorModal)
    return (
      <Box pr={1} pl={1}>
        <Box pt={2} borderBottom="1px solid #ccc" pb={1}>
          <Typography variant="h6" align="center">
            {t('subContractorForm.addSubcontractor')}
          </Typography>
        </Box>
        <Box p={2} borderBottom="1px solid #ccc">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Typography component="h6">{t('subContractorForm.startDate')}</Typography>
            </Grid>
            <Grid item xs={7} className={classes.dateContainer}>
              <Typography
                aria-label="formatted-planned-start-date"
                component="p"
                color={isDisabled ? 'textSecondary' : 'textPrimary'}
              >
                {formatDate(formData.plannedStartDate)}
              </Typography>
              <Box pl={2}>
                <DatePicker
                  name="planned-start-date"
                  dateTime={formData.plannedStartDate}
                  onDateSelected={handleChangeStartDate}
                  disabled={isDisabled}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Typography component="h6">{t('subContractorForm.endDate')}</Typography>
            </Grid>
            <Grid item xs={7} className={classes.dateContainer}>
              <Typography
                aria-label="formatted-planned-end-date"
                component="p"
                color={isDisabled ? 'textSecondary' : 'textPrimary'}
              >
                {formatDate(formData.plannedEndDate)}
              </Typography>
              <Box pl={2}>
                <DatePicker
                  name="planned-end-date"
                  dateTime={formData.plannedEndDate}
                  onDateSelected={handleChangeEndDate}
                  disabled={isDisabled}
                />
              </Box>
            </Grid>
          </Grid>

          {(plannedDateError === errorEnum.INVALID ||
            plannedDateError === errorEnum.PASTDATE) && (
            <Typography
              className={classes.invalidPlannedDateError}
              aria-label="invalid-planned-date"
              color="error"
            >
              {!isDisabled && plannedDateError === errorEnum.PASTDATE && (
                <>{t('subContractorForm.pastDateError')}</>
              )}
              {plannedDateError === errorEnum.INVALID && (
                <>{t('subContractorForm.invalidPlannedDate')}</>
              )}
            </Typography>
          )}
        </Box>

        <Box pt={2} pl={2} pr={2} borderBottom="1px solid #ccc">
          <Grid container alignItems="center">
            <Typography component="h6">
              {t('subContractorForm.subcontractorContact')}
            </Typography>
            <Box p={2} marginBottom={1}>
              <Grid item xs={12}>
                <CustomAutocomplete
                  data={vendors.map((vendor) => ({
                    id: vendor.vendorNumber,
                    label: `${vendor.vendorName1} (${vendor.vendorNumber})`,
                  }))}
                  id="subcontractor-name"
                  label={t('subContractorForm.name')}
                  size="small"
                  disabled={isDisabled}
                  selectedValue={formData.vendorNumber}
                  onChange={handleNameOnChange}
                  required
                />
                <TextField
                  fullWidth
                  type="text"
                  label={t('subContractorForm.email')}
                  variant="outlined"
                  margin="dense"
                  required
                  disabled={isDisabled}
                  value={formData.subcontractorEmail}
                  error={ifEmailError}
                  helperText={
                    subcontractorEmailError !== errorEnum.NONE &&
                    t('subContractorForm.invalidEmailError')
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEmailOnchange(e)
                  }
                  inputProps={{
                    'aria-label': 'subcontractor-email',
                  }}
                />
                <TextField
                  fullWidth
                  type="text"
                  label={t('subContractorForm.phoneNumber')}
                  variant="outlined"
                  margin="dense"
                  required
                  disabled={isDisabled}
                  value={formData.subcontractorPhone}
                  error={ifPhoneError}
                  helperText={
                    subcontractorPhoneError !== errorEnum.NONE &&
                    t('subContractorForm.invalidPhoneError')
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePhoneNumberOnchange(e)
                  }
                  inputProps={{
                    'aria-label': 'subcontractor-phone',
                  }}
                />
              </Grid>
            </Box>
            <Typography component="h6">
              {t('subContractorForm.subcontractorKey')}
            </Typography>
            <Box pl={2} pr={2} pb={1}>
              <Grid container>
                <Grid item xs={8}>
                  <TextField
                    type="text"
                    variant="outlined"
                    disabled
                    margin="dense"
                    value={formData.subcontractorKey}
                    inputProps={{
                      'aria-label': 'subcontractor-key',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.resetButton}>
                    <CachedRounded
                      className={classes.resetIcon}
                      onClick={generateSubcontractorKey}
                      aria-label="generate-key"
                    ></CachedRounded>
                    <Typography component="span">
                      {t('subContractorForm.createNew')}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
        {renderButtons()}
        <Dialog
          open={removeSubcontractorPopup}
          onClose={() => setRemoveSubcontractorPopup(false)}
          aria-labelledby="alert-dialog-title"
        >
          <DialogContent style={{ justifyContent: 'center' }}>
            <Box alignItems="justifyContent" pt="25px" pb="5px">
              <Typography align="center" style={{ fontWeight: 'bold' }}>
                {t('subContractorForm.removeConfirmation', {
                  name: formData.subcontractorName,
                })}
                <br></br> <br></br>
                {t('subContractorForm.confirmationQuestion')}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-evenly' }}>
            <Box pb="15px">
              <Button
                style={{ minWidth: '120px' }}
                fullWidth
                variant="contained"
                color="primary"
                onClick={removeSubcontractor}
              >
                {t('subContractorForm.removeTitle')}
              </Button>
            </Box>
            <Box pb={1}>
              <Button
                fullWidth
                style={{ minWidth: '120px' }}
                variant="contained"
                color="primary"
                data-testid="reload-confirm"
                onClick={() => setRemoveSubcontractorPopup(false)}
                autoFocus
              >
                {t('subContractorForm.subcontractorCancel')}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    );
  return <></>;
};
