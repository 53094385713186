import { useState, useEffect, useContext } from 'react';
import { hasDataToSync } from '../helpers/syncHelper';
import { getDeviationsToSync } from '../helpers/deviationOffline';
import { ServiceWorkerEventMessageType } from '../schemas';
import Context from '../context';

export const useCheckDataSync = () => {
  const { networkNumber } = useContext(Context);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    const updateSyncState = async () => {
      if (window.indexedDB) {
        const deviationToSync = await getDeviationsToSync(networkNumber);
        const hasAnswersToSync = await hasDataToSync(networkNumber);
        const hasDeviationToSync = deviationToSync.length > 0;

        setPending(hasAnswersToSync || hasDeviationToSync);
      }
    };

    const onMessageListener = async (event: MessageEvent) => {
      const message = event.data;
      if (!pending && message === ServiceWorkerEventMessageType.NEW_DATA_TO_SYNC) {
        setPending(() => true);
      }

      if (message === ServiceWorkerEventMessageType.SYNCED_WITH_BACKEND) {
        setPending(() => false);
      }
    };

    // this will listen messages send from client.postMessage()
    // see https://developer.mozilla.org/en-US/docs/Web/API/Client/postMessage
    navigator?.serviceWorker?.addEventListener('message', onMessageListener);

    updateSyncState();
  }, [networkNumber, pending]);

  return { hasToSync: pending, setPending };
};
