import React, { useState, useContext } from 'react';
import { InfoModal, theme } from '@konecorp/ui-library';
import { Button, ButtonGroup, Dialog, DialogContent } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import DeviationForm, { CreateDeviationPayload } from '../DeviationForm';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import Context, { InstallationContext } from '../../context';
import { useGetToken } from '../../hooks/useGetToken';
import { createDeviation } from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import { isInstallationCompleted } from '../../helpers/getInstallationLists';

export type SendToDeviationsButtonProps = {
  className?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      lineHeight: '18px',
      border: '2px solid',
    },
    DialogContent: {
      padding: 0,
    },
  })
);

const SendToDeviationsButton = (props: SendToDeviationsButtonProps): JSX.Element => {
  const { className } = props;
  const classes = useStyles(theme);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [offlineDeviation, setOfflineDeviation] = useState<boolean>(false);
  const [isOnline] = useCheckConnection();
  const openDialog = () => {
    isOnline ? setIsDialogOpen(true) : setOfflineDeviation(true);
  };

  const { t } = useTranslation();
  const { updateIsLoading, networkNumber, installationData, updateErrorMessage } =
    useContext(Context);
  const { dispatch } = useContext(InstallationContext);
  const [getToken] = useGetToken();

  const createDeviationAction = async (
    deviation: CreateDeviationPayload
  ): Promise<void> => {
    try {
      updateIsLoading(true);
      const accessToken = await getToken();
      const newDeviation = await createDeviation(accessToken, networkNumber, deviation);
      dispatch({ type: InstallationActionName.ADD_DEVIATION, deviation: newDeviation });
    } catch (error) {
      updateErrorMessage({
        message: t('qdPage.cannotCreateDeviation'),
        error,
      });
    } finally {
      updateIsLoading(false);
      setIsDialogOpen(false);
    }
  };

  return (
    <>
      <Dialog open={isDialogOpen} fullScreen={true}>
        <DialogContent className={classes.DialogContent}>
          <DeviationForm
            onCreate={createDeviationAction}
            onClear={() => setIsDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
      <ButtonGroup className={className}>
        <Button
          data-testid="create-deviation-button"
          color="primary"
          onClick={() => openDialog()}
          size="small"
          className={classes.button}
          disabled={isInstallationCompleted(installationData?.status)}
        >
          {t('qdPage.createDeviation')}
        </Button>
      </ButtonGroup>
      {offlineDeviation && (
        <InfoModal
          open={true}
          message={t('connection.offlineAlert')}
          onClose={(): void => setOfflineDeviation(false)}
          closeButtonText={t('connection.ok')}
          isCenteredMessage
        />
      )}
    </>
  );
};

export default SendToDeviationsButton;
