import React, { useEffect, useState } from 'react';

import { Modal } from '@material-ui/core';

import HandoverStatusWithSummary, {
  HandoverStatusWithSummaryProps,
} from '../HandoverStatusWithSummary';
import HandoverAnimation from '../HandoverAnimation';

export type HandoverStatusWithAnimationProps = HandoverStatusWithSummaryProps;

const HandoverStatusWithAnimation = (
  props: HandoverStatusWithAnimationProps
): JSX.Element => {
  const [showAnimation, setShowAnimation] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowAnimation(false), 6000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Modal open={showAnimation} onClick={() => setShowAnimation(false)}>
        <>
          <HandoverAnimation />
        </>
      </Modal>
      <HandoverStatusWithSummary {...props} />
    </>
  );
};

export default HandoverStatusWithAnimation;
