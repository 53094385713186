import React, { useState } from 'react';
import { Collapse, IconButton, Typography, Grid } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

export type TechnicalInformationnProps = {
  networkNumber?: string;
  equipmentNumber?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    'row-level-1': {
      backgroundColor: '#005A93',
      color: '#FFFFFF',
      '& p': {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
      '& .MuiIconButton-root': {
        paddingTop: theme.spacing(1),
        color: '#FFFFFF',
      },
      height: theme.typography.fontSize * 3,
    },
    'row-odd': {
      backgroundColor: '#F5F6F7',
      minHeight: theme.typography.fontSize * 3,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    'row-even': {
      backgroundColor: '#FFFFFF',
      minHeight: theme.typography.fontSize * 3,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
  })
);

const TechnicalInformation = (props: TechnicalInformationnProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const chevron = open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

  return (
    <>
      <Grid
        data-testid="contact-technical-info-header"
        className={classes['row-level-1']}
        container
        onClick={() => setOpen(!open)}
      >
        <Grid item xs={11}>
          <Typography>{t('technicalInformation.technicalInformation')}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="expand row" size="small">
            {chevron}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container className={classes['row-odd']}>
          <Grid item xs={7}>
            <Typography>{t('technicalInformation.equipmentNumber')}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{props.equipmentNumber}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes['row-even']}>
          <Grid item xs={7}>
            <Typography>{t('technicalInformation.networkNumber')}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{props.networkNumber}</Typography>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default TechnicalInformation;
