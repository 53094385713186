import { useContext } from 'react';
import Context from '../context/index';
import { get } from '../helpers/fetch';
import { useGetToken } from './useGetToken';

type updateAnswerInContextFunction = () => Promise<void>;

export const useUpdateInstallationListsInContext = (): [
  updateAnswerInContextFunction
] => {
  const {
    updateOngoingInstallations,
    updateToBeStartedInstallations,
    updateCompletedInstallations,
    authState: { graph },
  } = useContext(Context);

  const [getTokenFunction] = useGetToken();

  const updateInstallationListsInContext = async () => {
    const accessToken = await getTokenFunction();

    const employeeNetworks: {
      toBeStarted: string[];
      ongoing: string[];
      completed: string[];
    } = await get(`v1/employees/${graph.profile.employeeId}/networks`, accessToken);

    updateOngoingInstallations(employeeNetworks.ongoing);
    updateToBeStartedInstallations(employeeNetworks.toBeStarted);
    updateCompletedInstallations(employeeNetworks.completed);
  };

  return [updateInstallationListsInContext];
};
