import { Installation, ActivityDifferentiator } from '../schemas';
import { getUserRoleFromStorage } from './subContractorAuthentication';

type GetUserRoleParams = {
  installationData?: Installation;
  employeeId?: string;
};

export const getKoneEmployeeUserRole = (
  params: GetUserRoleParams
): ActivityDifferentiator | undefined => {
  const { installationData, employeeId } = params;

  if (installationData && employeeId) {
    const { supervisorNumber, delegatedSupervisorNumber, assignees } = installationData;

    const isSupervisor =
      supervisorNumber === employeeId || delegatedSupervisorNumber === employeeId;

    const isServiceEngineer = assignees?.some(
      (assignee) =>
        assignee.activityDifferentiator === ActivityDifferentiator.SEEN &&
        assignee.koneResourcePersonalNumber === employeeId
    );

    const isTester = assignees?.some(
      (assignee) =>
        assignee.activityDifferentiator === ActivityDifferentiator.CMSN &&
        assignee.koneResourcePersonalNumber === employeeId
    );

    const isInstaller = assignees?.some(
      (assignee) =>
        assignee.activityDifferentiator === ActivityDifferentiator.INST &&
        assignee.koneResourcePersonalNumber === employeeId
    );

    // Do not change the return order
    // More information can be found here https://dev.azure.com/kone-cloud/kc-app-InstallationExecutionSystem/_workitems/edit/95781
    if (isSupervisor) return ActivityDifferentiator.SPV;
    if (isServiceEngineer) return ActivityDifferentiator.SEEN;
    if (isTester) return ActivityDifferentiator.CMSN;
    if (isInstaller) return ActivityDifferentiator.INST;
  }

  return undefined;
};

//Supcontractor can only be either INST or CMSN which can be detech by using installation's status
export const getSubcontractorUserRole = (
  networkNumber: string
): ActivityDifferentiator | undefined => {
  const roleAsString = getUserRoleFromStorage(networkNumber);

  switch (roleAsString) {
    case ActivityDifferentiator.CMSN:
      return ActivityDifferentiator.CMSN;
    case ActivityDifferentiator.INST:
      return ActivityDifferentiator.INST;
    default:
      return undefined;
  }
};
