import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SubHeader from '../../components/SubHeader';
import { Box, IconButton, Slide, Snackbar, Typography } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { theme } from '@konecorp/ui-library';

import UpdateNetworkStatusForm, {
  PutNetworkStatusFormPayload,
} from '../../components/UpdateNetworkStatusForm';
import { API_TYPE, put } from '../../helpers/fetch';
import { useGetToken } from '../../hooks/useGetToken';
import { useTranslation } from 'react-i18next';

const DISPLAY_MESSAGE_DURATION = 10000;

const AdminPage = (): JSX.Element => {
  const history = useHistory();
  const [getTokenFunction] = useGetToken();
  const [displayAlert, setDisplayAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [displayMessage, setDisplayMessage] = useState<string>();
  const { t } = useTranslation();

  const setAlertMessage = (msg: string, isError = false) => {
    setIsError(isError);
    setDisplayMessage(msg);
    setDisplayAlert(true);
  };

  const onHideAlert = () => {
    setDisplayMessage('');
    setDisplayAlert(false);
  };

  const setErrorAlertMessage = (msg: string) => setAlertMessage(msg, true);

  const onNetworkStatusFormSubmit = async (
    data: PutNetworkStatusFormPayload
  ): Promise<void> => {
    const accessToken = await getTokenFunction();

    try {
      await put('v1/admin/status', accessToken, API_TYPE.APPLICATION, data);
      setAlertMessage(t('adminPage.successMassage'));
    } catch (error) {
      setErrorAlertMessage(t('adminPage.generalErrorMassage'));
    }
  };

  const fontColor = {
    color: isError ? theme.palette.error.main : theme.palette.success.main,
  };

  return (
    <Box data-testid="admin-page">
      <SubHeader title="Admin Utilities" handleGoBackClick={() => history.push('/')} />
      <Box m={5}>
        <Typography variant="h4">{t('updateNetworkStatusForm.title')}</Typography>
        <UpdateNetworkStatusForm onSubmit={onNetworkStatusFormSubmit} />
        <Snackbar
          open={displayAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          ContentProps={{
            style: {
              backgroundColor: fontColor.color,
            },
          }}
          onClose={() => onHideAlert()}
          autoHideDuration={DISPLAY_MESSAGE_DURATION}
          message={
            <div style={{ display: 'flex' }}>
              <Typography variant="h6">
                <strong>&nbsp; {displayMessage}</strong>
                <IconButton onClick={() => setDisplayAlert(false)} aria-label="close">
                  <Cancel style={{ color: 'white' }} />
                </IconButton>
              </Typography>
            </div>
          }
          TransitionComponent={Slide}
        />
      </Box>
    </Box>
  );
};

export default AdminPage;
