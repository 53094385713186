import React from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { theme as koneTheme } from '@konecorp/ui-library';

export type IconButtonGroupType = {
  className?: string;
  color?: 'primary' | 'secondary';
  onClick?: () => void;
  icon: JSX.Element | null;
  dataTestId: string;
};

const useStyles = makeStyles((theme) => ({
  button: {
    padding: `0 ${theme.spacing(0.5)}px`,
  },
}));

export type IconButtonGroupProps = {
  buttons: IconButtonGroupType[];
  className?: string;
};

const IconButtonGroup = (props: IconButtonGroupProps): JSX.Element => {
  const { className, buttons } = props;
  const classes = useStyles(koneTheme);
  return (
    <Box
      data-testid="icon-button-group"
      display="flex"
      flexDirection="row"
      className={className}
    >
      {buttons.map((button: IconButtonGroupType, index: number) => {
        const { icon, className, onClick, color, dataTestId } = button;
        const key = `icon-button-group--icon-button-${index}`;
        return (
          <IconButton
            key={key}
            data-testid={dataTestId}
            aria-label="icon button"
            className={`${classes.button} ${className}`}
            color={color || 'primary'}
            onClick={onClick}
            disabled={!onClick}
          >
            {icon}
          </IconButton>
        );
      })}
    </Box>
  );
};

export default IconButtonGroup;
