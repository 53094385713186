import React, { ReactNode, useReducer } from 'react';

import { InstallationContext } from '.';
import installationReducer, { InstallationActions } from '../reducers/installation';
import { Deviation } from '../schemas';

type ReactProps = {
  children: ReactNode;
};

export type InstallationState = {
  deviations: Deviation[];
};

export type InstallationContextState = InstallationState & {
  dispatch: React.Dispatch<InstallationActions>;
};

export default ({ children }: ReactProps): JSX.Element => {
  const [state, dispatch] = useReducer(installationReducer, {
    deviations: [],
  } as InstallationState);

  return (
    <InstallationContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </InstallationContext.Provider>
  );
};
