import { useContext, useEffect } from 'react';
import Context from '../context/index';

import {
  ServiceWorkerEventMessagePayload,
  ServiceWorkerEventMessageType,
} from '../schemas';
import { useIfSubcontractor } from './useIfSubcontractor';
import { useGetToken } from './useGetToken';

export const useSetServiceWorkerMessageInterval = (): void => {
  const { authState } = useContext(Context);
  const { isAuthenticated } = authState || {};
  const [isSubcontractor] = useIfSubcontractor();
  const [getTokenFunction] = useGetToken();

  const SYNC_INTERVAL = process.env.REACT_APP_DATA_SYNC_INTERVAL || '3';

  useEffect(() => {
    //when user login as subcontractor, the isAuthenticated state is not updated, it is only updated with KONE employee
    if (isAuthenticated || isSubcontractor) {
      const intervalId = setInterval(async () => {
        try {
          const message: ServiceWorkerEventMessagePayload = {
            messageType: ServiceWorkerEventMessageType.SYNC_WITH_BACKEND,
            accessToken: await getTokenFunction(),
          };
          if (document.visibilityState === 'visible') {
            //Only send a message if the tab is in the foreground
            navigator.serviceWorker.controller?.postMessage(message);
          }
        } catch (error) {
          console.error(
            'error while trying to send a sync message to service worker. Error: ',
            error
          );
        }
      }, Number(SYNC_INTERVAL) * 60 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isAuthenticated, isSubcontractor]);
};
