import React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    notificationBox: {
      border: '1px solid',
      borderColor: theme.palette.error.main,
      padding: theme.spacing(2),
    },
  })
);

export type AlertBoxProps = {
  children?: React.ReactNode;
  className?: string;
};

const AlertBox = (props: AlertBoxProps): JSX.Element => {
  const classes = useStyles();

  const { children, className = '' } = props;

  if (!children) {
    return <></>;
  }

  return (
    <Box className={`${classes.notificationBox} ${className}`} role="alert">
      {children}
    </Box>
  );
};

export default AlertBox;
