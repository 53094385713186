import React, { useEffect, useContext, useMemo } from 'react';
import { Typography, CssBaseline, Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { theme, Navigation } from '@konecorp/ui-library';
import { Route, Switch, useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from '../../logo.svg';
import { useSetServiceWorkerMessageInterval } from '../../hooks/useSetServiceWorkerMessageInterval';
import SubcontractorLogin from '../SubcontractorLogin';
import NetworkInstallation, {
  SubcontractorNetworkInstallationPaths,
} from '../NetworkInstallation';
import { subcontractorAcquireToken } from '../../helpers/subContractorAuthentication';
import { betweenStatus } from '../../helpers/getInstallationLists';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { ActivityDifferentiator, InstallationStatus } from '../../schemas';
import getMenus from '../../menus';
import Loading from '../../components/Loading';
import Context from '../../context';
import ConnectStatusBanner from '../../components/ConnectStatusBanner';
import InstallationState from '../../context/installation';
import ErrorMessageDialog from '../../components/ErrorMessageDialog';

export type QueryParams = {
  networkNumber: string;
};

const useStyles = makeStyles((t: Theme) =>
  createStyles({
    loadingBackdrop: {
      zIndex: 1500,
    },
    // necessary for content to be below app bar
    toolbar: t.mixins.toolbar,
    content: {
      flexGrow: 1,
    },
    footer: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      '& span.Mui-selected': {
        fontSize: 12,
      },
    },
  })
);

const NAVIGATION_HEIGHT = theme.typography.fontSize * 6;

enum SubcontractorPublicPageType {
  Unauthorize,
  Completed,
}
type SubcontractorPublicInfoPageProps = {
  pageType: SubcontractorPublicPageType;
};

export const getAllowToViewDeviationListOnly = (
  userRole?: ActivityDifferentiator,
  status?: InstallationStatus | null
): Boolean => {
  const deviationListOnlyForInstaller =
    userRole === ActivityDifferentiator.INST &&
    status &&
    betweenStatus(
      status,
      InstallationStatus.FOR_INSTALLATION_INSPECTION,
      InstallationStatus.INSTALLATION_COMPLETE
    );

  const deviationListOnlyForTester =
    userRole === ActivityDifferentiator.CMSN &&
    status &&
    betweenStatus(
      status,
      InstallationStatus.FOR_FINAL_INSPECTION,
      InstallationStatus.INSTALLATION_COMPLETE
    );

  if (deviationListOnlyForInstaller || deviationListOnlyForTester) return true;
  else return false;
};

const SubcontractorPublicPage = (props: SubcontractorPublicInfoPageProps) => {
  const { pageType } = props;
  const { networkNumber } = useParams<QueryParams>();
  const { t } = useTranslation();

  const title =
    pageType === SubcontractorPublicPageType.Unauthorize
      ? t('subcontractorUnauthorize.unauthorize', {
          networkNumber: networkNumber,
        })
      : t('subcontractorNetworkCompleted.completed');

  const message =
    pageType === SubcontractorPublicPageType.Unauthorize
      ? t('subcontractorUnauthorize.useURLToLogin')
      : t('subcontractorNetworkCompleted.canNowCloseTab', {
          networkNumber: networkNumber,
        });

  return (
    <Box display="flex" pl={3} pr={3} alignItems="center" height="100vh">
      <Box>
        <img src={logo} />
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h6">{message}</Typography>
      </Box>
    </Box>
  );
};

const PublicSubcontractorPages = () => {
  return (
    <Switch>
      <Route path="/subcontractor/login/:networkNumber/:guid/:hash/:role">
        <SubcontractorLogin />
      </Route>
      <Route exact path="/subcontractor/unauthorize/:networkNumber">
        <SubcontractorPublicPage pageType={SubcontractorPublicPageType.Unauthorize} />
      </Route>
      <Route exact path="/subcontractor/completed/:networkNumber">
        <SubcontractorPublicPage pageType={SubcontractorPublicPageType.Completed} />
      </Route>
    </Switch>
  );
};

const PrivateSubcontractorPages = () => {
  //Seting up interval to send message to service worker every 5 mins to sync with backend
  useSetServiceWorkerMessageInterval();

  const { networkNumber } = useParams() as QueryParams;
  const { installationData } = useContext(Context);

  const [userRole] = useGetCurrentUserRole();
  const history = useHistory();
  const classes = useStyles(theme);

  const getCurrentMenuPageIndex = (url: string): number => {
    if (url.includes('execution')) return 1;
    if (url.includes('starting')) return 1;
    if (url.includes('deviations')) return 2;
    return 0;
  };

  const isDeviationOnly = useMemo(() => {
    return getAllowToViewDeviationListOnly(userRole, installationData?.status);
  }, [userRole, installationData]);

  const initialSelectedMenuIndex = getCurrentMenuPageIndex(location.pathname);
  let showMyPlan = false;
  if (installationData) {
    showMyPlan =
      (!installationData.isModelData && !installationData.isTacoDataQuest) ||
      installationData.installerQuestionSetSequence.length === 2;
  }
  const selectedMenus = () =>
    getMenus(
      networkNumber,
      installationData?.status || InstallationStatus.TO_BE_STARTED,
      userRole,
      true,
      showMyPlan
    );

  const handleMenuChange = (menuTitle: string): void => {
    if (!menuTitle) {
      return;
    }

    const selectedMenu = selectedMenus().find(
      (item) => item.title === menuTitle.trimEnd()
    );

    if (selectedMenu?.url === location.pathname) {
      return;
    }

    history.push(selectedMenu?.url || '/');
  };

  useEffect(() => {
    const key = subcontractorAcquireToken(networkNumber);

    if (!key) {
      history.replace(`/subcontractor/unauthorize/${networkNumber}`);
    }
  }, []);

  return (
    <>
      <div className={classes.toolbar} />
      <ConnectStatusBanner />

      <Route exact path={Object.values(SubcontractorNetworkInstallationPaths)}>
        <InstallationState>
          <NetworkInstallation />
        </InstallationState>
        {/* TODO: is there a way to share Menu between KONE APP and Subcontracor app ? */}
        {!isDeviationOnly && (
          <footer data-testid="footer" className={classes.footer}>
            <Navigation
              data={selectedMenus()}
              handleActionButtonClick={handleMenuChange}
              height={NAVIGATION_HEIGHT}
              initalSelectedMenuIndex={initialSelectedMenuIndex}
              shadow
            />
          </footer>
        )}
      </Route>
    </>
  );
};

const SubcontractorApp = (): JSX.Element => {
  const classes = useStyles(theme);
  const location = useLocation();
  const { isLoading } = useContext(Context);

  const publicSubcontractorEndpoint = ['/login', '/unauthorize', '/completed'];

  const isPublicSubcontractorPage = useMemo(() => {
    return publicSubcontractorEndpoint.some((endpoint) =>
      location.pathname.includes(endpoint)
    );
  }, [location.pathname]);

  return (
    <>
      {isLoading && <Loading backdropClassName={classes.loadingBackdrop} />}
      <ErrorMessageDialog />

      <Box display="flex" pb={11}>
        <CssBaseline />
        <main className={classes.content}>
          {isPublicSubcontractorPage ? (
            <PublicSubcontractorPages />
          ) : (
            <Route path="/subcontractor/:networkNumber">
              {/* having nested route here to access :networkNumber in useEffect of PrivateSubcontractorPage component */}
              <PrivateSubcontractorPages />
            </Route>
          )}
        </main>
      </Box>
    </>
  );
};
export default SubcontractorApp;
