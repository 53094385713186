import React, { PropsWithChildren } from 'react';
import { ArrowLeft, ArrowRight } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  OutlinedInputProps,
  TextField,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { theme as koneTheme } from '@konecorp/ui-library';

const useStyles = makeStyles((theme) => ({
  validInput: {
    maxWidth: theme.spacing(6),
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '6px 0',
    },
  },
  invalidInput: {
    maxWidth: theme.spacing(6),
    '& .MuiOutlinedInput-inputMarginDense': {
      borderColor: theme.palette.secondary.main,
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.error.light,
      padding: '6px 0',
    },
  },
  cssOutlinedInput: {
    '&$notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: theme.palette.primary.main,
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    padding: '6px 0',
    border: '2px solid',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.primary.light,
    '&.Mui-disabled': {
      border: '1px solid',
      borderColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled path': {
      fill: theme.palette.secondary.light,
    },
  },
  container: {
    padding: '0 16px',
  },
  placeholders: {
    lineHeight: '0.9',
    display: 'inline-block',
  },
  resetButton: {
    border: '2px solid',
    cursor: 'pointer',
    lineHeight: '16px',
    maxWidth: '100%',
  },
  rotate90: {
    transform: 'rotate(90deg)',
  },
}));

export type PlumbingXYAdjustmentsProps = {
  adjustments: AdjustmentsState[];
  onChangeAdjustments: (param: AdjustmentsState) => void;
  onResetCoordinates: () => void;
  readOnly?: boolean;
};

type PlusMinusIconButtonProps = {
  onIncrement: () => void;
  onDecrement: () => void;
  disabled: boolean;
  dataTestKey?: string;
  incrementIcon: JSX.Element;
  decrementIcon: JSX.Element;
};

export enum PlumbingAdjustmentAction {
  INCREMENT,
  DECREMENT,
}

export enum AdjustmentCoordinates {
  X = 'X',
  Y = 'Y',
}

export const defaultXYAdjustmentsState: AdjustmentsState[] = [
  {
    error: false,
    value: 0,
    key: AdjustmentCoordinates.X,
  },
  {
    error: false,
    value: 0,
    key: AdjustmentCoordinates.Y,
  },
];

export type AdjustmentsState = {
  key: AdjustmentCoordinates;
  value: number;
  error?: boolean;
  thresholdText?: string;
  actionType?: PlumbingAdjustmentAction;
};

const PlusMinusIconButton = (props: PropsWithChildren<PlusMinusIconButtonProps>) => {
  const classes = useStyles(koneTheme);
  const {
    onIncrement,
    onDecrement,
    disabled,
    dataTestKey,
    incrementIcon,
    decrementIcon,
  } = props;
  return (
    <>
      <IconButton
        onClick={onIncrement}
        data-testid={`plumbing-${dataTestKey}-increment-button`}
        disabled={disabled}
        aria-label={`${dataTestKey}-value-increment-button`}
        size="small"
        color={'primary'}
        className={classes.iconButton}
      >
        {incrementIcon}
      </IconButton>
      <IconButton
        onClick={onDecrement}
        disabled={disabled}
        data-testid={`plumbing-${dataTestKey}-decrement-button`}
        size="small"
        aria-label={`${dataTestKey}-value-decrement-button`}
        color={'primary'}
        className={classes.iconButton}
      >
        {decrementIcon}
      </IconButton>
    </>
  );
};

const PlumbingXYAdjustments = (
  props: PropsWithChildren<PlumbingXYAdjustmentsProps>
): JSX.Element => {
  const classes = useStyles(koneTheme);
  const { t } = useTranslation();
  const InputProps = {
    classes: {
      root: classes.cssOutlinedInput,
      notchedOutline: classes.notchedOutline,
    },
    inputMode: 'numeric',
  } as Partial<OutlinedInputProps>;
  const { adjustments, onChangeAdjustments, onResetCoordinates, readOnly } = props;

  const getIcons = (key: AdjustmentCoordinates, type: PlumbingAdjustmentAction) => {
    const xIncrementIcon = <ArrowLeft />;
    const xDecrementIcon = <ArrowRight />;
    const yIncrementIcon = <ArrowLeft className={classes.rotate90} />;
    const yDecrementIcon = <ArrowRight className={classes.rotate90} />;
    if (key === AdjustmentCoordinates.X && type === PlumbingAdjustmentAction.INCREMENT) {
      return xIncrementIcon;
    } else if (
      key === AdjustmentCoordinates.X &&
      type === PlumbingAdjustmentAction.DECREMENT
    ) {
      return xDecrementIcon;
    } else if (
      key === AdjustmentCoordinates.Y &&
      type === PlumbingAdjustmentAction.INCREMENT
    ) {
      return yIncrementIcon;
    } else {
      return yDecrementIcon;
    }
  };
  return (
    <Grid container className={classes.container}>
      <Grid item xs={9} sm={9}>
        <Box mt={2}>
          {adjustments.map(({ key, value, error = false, thresholdText }, index) => {
            const plusMinusButtonProps: PlusMinusIconButtonProps = {
              onIncrement: () =>
                onChangeAdjustments({
                  key,
                  value,
                  actionType: PlumbingAdjustmentAction.INCREMENT,
                }),
              incrementIcon: getIcons(key, PlumbingAdjustmentAction.INCREMENT),
              onDecrement: () =>
                onChangeAdjustments({
                  key,
                  value,
                  actionType: PlumbingAdjustmentAction.DECREMENT,
                }),
              decrementIcon: getIcons(key, PlumbingAdjustmentAction.DECREMENT),
              disabled: error || !!readOnly,
              dataTestKey: key,
            };
            return (
              <Box
                p={0.5}
                key={`plumbing-summary-${key}-${index}`}
                display="flex"
                justifyContent="in-line"
              >
                <Typography color="textSecondary" variant="h6" display="inline">
                  {key}&nbsp;
                </Typography>
                <TextField
                  classes={{ root: error ? 'error' : 'default-input' }}
                  className={error ? classes.invalidInput : classes.validInput}
                  margin="none"
                  size="small"
                  variant="outlined"
                  disabled={true}
                  InputProps={InputProps}
                  type="number"
                  value={value.toString()}
                  inputProps={{
                    'data-testid': `summary-${key}-input`,
                    style: { textAlign: 'center' },
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                />
                <PlusMinusIconButton {...plusMinusButtonProps} />

                <Box p={0.5}>
                  {error ? (
                    <Typography
                      data-testid={`${key}-adjustment-text-placeholder`}
                      className={classes.placeholders}
                      color="error"
                    >
                      {t('plumbingSummary.errorAdjustments')}
                    </Typography>
                  ) : (
                    <Typography className={classes.placeholders} color="secondary">
                      {thresholdText}
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid item xs={3} sm={3}>
        <Box
          height={koneTheme.spacing(12)}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Button
            onClick={onResetCoordinates}
            size="large"
            className={classes.resetButton}
            variant="outlined"
            color="primary"
            data-testid="plumbing-previous-button"
          >
            {t('plumbingSummary.resetValues')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PlumbingXYAdjustments;
