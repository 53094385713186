import React, { PropsWithChildren } from 'react';
import { kebabCase } from 'lodash';

import { Box, ButtonBase, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { theme, ArrowRight } from '@konecorp/ui-library';

import backgroundImage from './background.jpg';

export interface WidgetButtonProps {
  icon: JSX.Element;
  title: string;
  countLabel?: number;
  action?: () => void;
}

export interface WidgetGroupProps {
  name: string;
  buttons: WidgetButtonProps[];
}

export interface WidgetProps {
  widgetGroups: WidgetGroupProps[];
}

const useStyles = makeStyles(() =>
  createStyles({
    actionIcon: {
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      marginLeft: theme.spacing(10),
      '& svg': {
        fill: theme.palette.text.secondary,
      },
      padding: 3,
    },
    amount: {
      display: 'flex',
      marginLeft: 'auto',
      paddingRight: 3,
    },
    button: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      border: '1px solid grey',
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      width: '100%',
    },
    group: {
      paddingBottom: theme.spacing(1),
    },
    icon: {
      '& path': {
        fill: theme.palette.common.white,
      },
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    itemsPlaceholder: {
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    root: {
      padding: theme.spacing(3),
      width: '100%',
      color: theme.palette.primary.contrastText,
      textShadow: `1px 1px ${theme.palette.text.secondary}`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '100% 16%',
      backgroundImage: `linear-gradient(
        rgba(0, 89, 148, 0.6),
        rgba(0, 89, 148, 0.6)
      ),url(${backgroundImage})`,
    },
    title: {
      color: theme.palette.common.white,
    },
  })
);

const Widget = (props: PropsWithChildren<WidgetProps>): JSX.Element => {
  const classes = useStyles(theme);

  const Button = (props: { button: WidgetButtonProps }): JSX.Element => {
    const { button } = props;
    const id = kebabCase(button.title);

    return (
      <ButtonBase
        className={classes.button}
        data-testid={`widget-button-${id}`}
        onClick={button.action}
      >
        <Typography component="div" className={classes.itemsPlaceholder}>
          <div className={classes.icon}>{button.icon}</div>
          <div className={classes.title}>{button.title}</div>
          <div className={classes.amount} aria-label={`widget-amount-${id}`}>
            {button.countLabel}
          </div>
          {button.action && (
            <div className={classes.actionIcon}>
              <ArrowRight />
            </div>
          )}
        </Typography>
      </ButtonBase>
    );
  };

  return (
    <Typography component="div" className={classes.root} paragraph gutterBottom>
      {props.widgetGroups.map((group) => (
        <Box className={classes.group} key={kebabCase(group.name)}>
          <Typography variant="h6" component="h6">
            {group.name}
          </Typography>
          {group.buttons.map((button, index) => (
            <Button
              button={button}
              key={`widget-button-${kebabCase(button.title)}-${index}}`}
            />
          ))}
        </Box>
      ))}
    </Typography>
  );
};

export default Widget;
