import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { theme } from '@konecorp/ui-library';

import { Step, StepButton, StepConnector, Stepper, withStyles } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import {
  AnswerSet,
  DeviationStatus,
  DeviationVariation,
  QuestionSet,
} from '../../schemas';
import { InstallationContext } from '../../context';
import { isQuestionSetAnswered, isQuestionSetStarted } from '../../helpers/question';

const useStyles = makeStyles((theme) => {
  const getIconContainerStyle = (color?: string) => ({
    '& .MuiStepLabel-iconContainer': {
      border: '1px solid',
      borderRadius: '50%',
      borderColor: color ?? theme.palette.primary.main,
      backgroundColor: color ?? theme.palette.background.default,
    },
  });
  const defaultStepIconStyle = {
    '& .MuiStepIcon-text': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      height: theme.spacing(1.5),
      width: theme.spacing(1.5),
      '& path': {
        display: 'none',
      },
    },
    ...getIconContainerStyle(),
    '& .MuiStepLabel-label': {
      width: theme.spacing(12),
      marginTop: theme.spacing(1),
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: 1,
    },
  };
  return {
    root: {
      width: '100%',
      position: 'absolute',
      overflowX: 'auto',
      overflowY: 'hidden',
      marginTop: theme.spacing(1),
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      zIndex: 1,
      height: theme.spacing(10.5), // combination of background, height and mask gives the opacity effect over images
      boxShadow: '0 1px 5px rgba(0,0,0,.3)',
    },
    stepButtonDefault: {
      ...defaultStepIconStyle,
      '& circle': {
        fill: theme.palette.background.default,
      },
    },
    stepButtonActive: {
      ...defaultStepIconStyle,
      ...getIconContainerStyle(theme.palette.primary.main),
      '& circle': {
        fill: theme.palette.primary.main,
      },
    },
    stepButtonCompleted: {
      ...defaultStepIconStyle,
      '& .MuiStepIcon-completed': {
        color: green[500],
      },
      '& circle': {
        fill: green[500],
      },
      '& .MuiStepLabel-iconContainer': {
        border: 'none',
      },
      '& .MuiSvgIcon-root': {
        height: theme.spacing(2),
        width: theme.spacing(2),
      },
    },
    stepButtonCompletedWithRejections: {
      ...defaultStepIconStyle,
      '& .MuiStepIcon-completed': {
        color: yellow[700],
      },
      '& .MuiStepLabel-iconContainer': {
        border: 'none',
      },
      '& .MuiSvgIcon-root': {
        height: theme.spacing(2),
        width: theme.spacing(2),
      },
    },
    stepButtonIncomplete: {
      ...defaultStepIconStyle,
      ...getIconContainerStyle(red[500]),
      '& circle': {
        fill: red[500],
      },
    },
  };
});

enum NavigationStepperState {
  NOT_STARTED = 'Default',
  CURRENT = 'Active',
  COMPLETED = 'Completed',
  COMPLETED_WITH_REJECTIONS = 'CompletedWithRejections',
  INCOMPLETE = 'Incomplete',
}

export type NavigationStep = {
  displayText?: string;
  id: string;
};

export type NavigationStepperProps = {
  navigationSteps: NavigationStep[];
  activeStep: number; // index on the navigationSteps array
  questions?: QuestionSet[];
  answers?: AnswerSet[];
  onClickStep: (selectedStep: NavigationStep) => void;
};

const CustomStepperConnectorLine = withStyles({
  alternativeLabel: {
    top: 5,
    left: 'calc(-50% + 6px)',
    right: 'calc(50% + 6px)',
  },
  active: {
    '& $line': {
      borderTopWidth: '0.3em',
      height: '0.4em',
    },
  },
  completed: {
    '& $line': {
      borderTopWidth: '0.3em',
      height: '0.4em',
    },
  },
  line: {
    borderColor: theme.palette.primary.main,
    borderTopWidth: 1,
    borderRadius: 1,
  },
})(StepConnector);

const NavigationStepper = (
  props: PropsWithChildren<NavigationStepperProps>
): JSX.Element => {
  const classes = useStyles();
  const { navigationSteps, activeStep, questions, answers, onClickStep } = props;
  const { deviations } = useContext(InstallationContext);

  const stepRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const getStepKey = (stepIndex: number): string => {
    if (navigationSteps[stepIndex]) {
      const { id } = navigationSteps[stepIndex];
      return `questionset-step-${id}`;
    }
    return '';
  };

  const onChangeStep = (step: NavigationStep) => {
    scrollNavigationStepperToActiveStep();
    onClickStep(step);
  };

  const scrollNavigationStepperToActiveStep = () => {
    if (stepRef && stepRef.current && navigationSteps) {
      const uniqueQuestionsetId = getStepKey(activeStep);
      const activeStepElement = stepRef.current.querySelector(
        `div[data-testid="${uniqueQuestionsetId}"]`
      );
      if (activeStepElement && activeStepElement.scrollIntoView)
        /* there is a bug with chromium implementation, ex: Google Chrome for scrollIntoView
           wrapping it in a setTimeout is a walk around
           https://github.com/facebook/react/issues/23396#issuecomment-1557694564
        */
        setTimeout(() => {
          activeStepElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }, 200);
    }
  };
  useEffect(scrollNavigationStepperToActiveStep, [activeStep]);

  const getNavigationStepperState = (setId: string, index: number) => {
    if (index === activeStep) return NavigationStepperState.CURRENT;

    if (setId === 'PREVIEW_HANDOVER') return NavigationStepperState.COMPLETED;

    if (setId === 'HANDOVER' || !questions || !answers)
      return NavigationStepperState.NOT_STARTED;

    if (isQuestionSetAnswered(setId, questions, answers)) {
      const openRejectedDeviations = deviations.filter(
        ({ questionSetId, status, variation }) =>
          questionSetId === setId &&
          status === DeviationStatus.OPEN &&
          variation === DeviationVariation.REJECT
      );

      if (openRejectedDeviations.length > 0) {
        return NavigationStepperState.COMPLETED_WITH_REJECTIONS;
      }
      return NavigationStepperState.COMPLETED;
    }

    if (isQuestionSetStarted(setId, questions, answers)) {
      return NavigationStepperState.INCOMPLETE;
    }

    return NavigationStepperState.NOT_STARTED;
  };

  return (
    <>
      <div data-testid="navigation-stepper" ref={stepRef} className={classes.root}>
        <Stepper
          nonLinear
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomStepperConnectorLine />}
        >
          {navigationSteps.map((step: NavigationStep, index: number) => {
            const state = getNavigationStepperState(step.id, index);
            const className = `stepButton${state.valueOf()}` as keyof typeof classes;

            const completedStates = [
              NavigationStepperState.COMPLETED,
              NavigationStepperState.COMPLETED_WITH_REJECTIONS,
            ];
            const isComplete = completedStates.includes(state);

            const scrollKey = getStepKey(index);
            const { displayText, id } = step;
            return (
              <Step
                completed={index < activeStep}
                key={scrollKey}
                data-testid={scrollKey}
              >
                <StepButton
                  data-testid={`${scrollKey}-button`}
                  className={classes[className]}
                  onClick={() => onChangeStep(step)}
                  completed={isComplete}
                >
                  {displayText || id}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </>
  );
};

NavigationStepper.defaultProps = {
  navigationSteps: [],
  activeStep: 0,
};

export default NavigationStepper;
