import React from 'react';
import { useTranslation } from 'react-i18next';

import { kebabCase } from 'lodash';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { theme } from '@konecorp/ui-library';

import { WorkerData, Filters, GroupType } from '../DeviationsList';

export type DeviationsListFiltersProps = {
  workers: WorkerData[];
  filters: Filters;
  setFilters: (filters: Filters) => void;
  onClose: (reset?: boolean) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const DeviationsListFilters = (props: DeviationsListFiltersProps): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { workers, filters, setFilters, onClose } = props;

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFilters({ ...filters, [event.target.name]: event.target.value });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFilters({ ...filters, [event.target.name]: event.target.checked });

  return (
    <>
      <FormGroup>
        <TextField
          name="assignedTo"
          label={t('deviationsList.assignedTo')}
          variant="outlined"
          className={styles.select}
          defaultValue=""
          value={filters.assignedTo}
          onChange={handleSelectChange}
          inputProps={{ 'data-testid': 'assignedTo-input' }}
          fullWidth
          select
        >
          <MenuItem value="">{t('deviationsList.assignee.anyone')}</MenuItem>
          {workers.map((worker) => (
            <MenuItem value={worker.employeeId} key={kebabCase(worker.displayName)}>
              {worker.displayName}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="createdBy"
          label={t('deviationsList.createdBy')}
          variant="outlined"
          className={styles.select}
          defaultValue=""
          value={filters.createdBy}
          onChange={handleSelectChange}
          inputProps={{ 'data-testid': 'createdBy-input' }}
          fullWidth
          select
        >
          <MenuItem value="">{t('deviationsList.assignee.anyone')}</MenuItem>
          {workers.map((worker) => (
            <MenuItem value={worker.employeeId} key={kebabCase(worker.displayName)}>
              {worker.displayName}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="groupBy"
          label={t('deviationsList.groupBy.header')}
          variant="outlined"
          className={styles.select}
          defaultValue={GroupType.PRIORITY}
          value={filters.groupBy}
          onChange={handleSelectChange}
          inputProps={{ 'data-testid': 'groupBy-input' }}
          fullWidth
          select
        >
          <MenuItem value={GroupType.PRIORITY}>
            {t('deviationsList.groupBy.priority')}
          </MenuItem>
          <MenuItem value={GroupType.LOCATION}>
            {t('deviationsList.groupBy.location')}
          </MenuItem>
          <MenuItem value={GroupType.ASSIGNEE}>
            {t('deviationsList.groupBy.assignee')}
          </MenuItem>
        </TextField>
        <FormLabel>{t('deviationsList.including')}</FormLabel>
        <FormControl>
          <FormControlLabel
            name="showFixed"
            control={
              <Checkbox
                checked={filters.showFixed}
                color="primary"
                onChange={handleCheckboxChange}
              />
            }
            label={t('deviationsList.showFixed')}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            name="showDeviations"
            control={
              <Checkbox
                checked={filters.showDeviations}
                color="primary"
                onChange={handleCheckboxChange}
              />
            }
            label={t('deviationsList.showDeviations')}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            name="showQrIssues"
            control={
              <Checkbox
                checked={filters.showQrIssues}
                color="primary"
                onChange={handleCheckboxChange}
              />
            }
            label={t('deviationsList.showQrIssues')}
          />
        </FormControl>
      </FormGroup>
      <Button
        fullWidth
        className={styles.button}
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => onClose()}
      >
        {t('deviationsList.applyButton')}
      </Button>
      <Button
        fullWidth
        className={styles.button}
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => onClose(true)}
      >
        {t('deviationsList.resetButton')}
      </Button>
    </>
  );
};

export default DeviationsListFilters;
