import React, { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

import {
  ActivityDifferentiator,
  Installation,
  InstallationStatus,
  isAssignment,
  Worker,
} from '../../schemas';
import InstallationProgress from '../../components/InstallationProgress';
import TeamList from '../../components/TeamList';
import HandoverStatusWithSummary from '../../components/HandoverStatusWithSummary';
import { InstallationContext } from '../../context';
import InstallationActions from '../../components/InstallationActions';

export type ProgressViewProps = {
  installation: Installation | null;
  networkNumber: string;
};

const useStyles = makeStyles<Theme>(() => ({
  root: {
    width: '100%',
    marginTop: '1rem',
    // fix the jumping issue while toggling
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: theme.palette.common.white,
    },
  },
  details: {
    padding: theme.spacing(2),
    width: '100%',
  },
  summary: {
    backgroundColor: '#005A93',
    color: theme.palette.common.white,
  },
}));

const getInstallationDates = (installation: Installation | null) => {
  const byRole = (role: ActivityDifferentiator) => (worker: Worker) =>
    worker.activityDifferentiator === role;
  const toStartDate = (worker: Worker) =>
    isAssignment(worker) ? worker.assignmentStartDate : worker.plannedStartDate;
  const toEndDate = (worker: Worker) =>
    isAssignment(worker) ? worker.assignmentEndDate : worker.plannedEndDate;
  const getEarliest = (acc: string, date: string): string =>
    new Date(acc).getTime() < new Date(date).getTime() ? acc : date;
  const getLatest = (acc?: string, date?: string): string | undefined => {
    if (acc && date)
      return new Date(acc).getTime() > new Date(date).getTime() ? acc : date;
    else return acc;
  };

  const installers = [
    ...(installation?.assignees || []),
    ...(installation?.subcontractors || []),
  ].filter(byRole(ActivityDifferentiator.INST));

  if (installers.length === 0) return [undefined, undefined];

  const startDate = installers.map(toStartDate).reduce(getEarliest);
  const endDate = installers.map(toEndDate).reduce(getLatest);

  return [startDate, endDate];
};

const ProgressView = ({
  installation,
  networkNumber,
}: ProgressViewProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { deviations } = useContext(InstallationContext);

  const [plannedStartDate, plannedEndDate] = getInstallationDates(installation);

  const isForInstallationInspection =
    installation?.status === InstallationStatus.FOR_INSTALLATION_INSPECTION;

  return (
    <div className={classes.root}>
      <Box pb={0.5}>
        <Accordion square={true} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="team-panel-content"
            className={classes.summary}
          >
            <Typography>{t('progressView.installationTeam')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details} aria-label="team-panel-content">
            {installation && (
              <TeamList installation={installation} networkNumber={networkNumber} />
            )}
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box pb={0.5}>
        <Accordion square={true} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="progress-panel-content"
            className={classes.summary}
          >
            <Typography>
              {t('progressView.installationActions')}
              {isForInstallationInspection && ' (1)'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {isForInstallationInspection && (
              <InstallationActions
                networkNumber={networkNumber}
                deviations={deviations}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box pb={0.5}>
        <Accordion square={true} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="progress-panel-content"
            className={classes.summary}
          >
            <Typography>{t('progressView.installationProgress')}</Typography>
          </AccordionSummary>
          <AccordionDetails
            className={classes.details}
            aria-label="progress-panel-content"
          >
            <InstallationProgress
              installation={installation}
              networkNumber={networkNumber}
            />
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box pb={0.5}>
        <Accordion square={true} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="summary-panel-content"
            className={classes.summary}
          >
            <Typography>{t('progressView.installationSummary')}</Typography>
          </AccordionSummary>
          <AccordionDetails
            className={classes.details}
            aria-label="summary-panel-content"
          >
            <HandoverStatusWithSummary
              actualStartDate={installation?.actualDates?.actualInstallerStartDate}
              actualTargetDate={installation?.actualDates?.actualInstallerHandoverDate}
              plannedStartDate={plannedStartDate}
              plannedTargetDate={plannedEndDate}
              deviations={deviations}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default ProgressView;
