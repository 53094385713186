import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { kebabCase } from 'lodash';

import {
  Box,
  Button,
  FormGroup,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { theme } from '@konecorp/ui-library';

import { Deviation } from '../../schemas';
import { WorkerData } from '../DeviationsList';

export type DeviationsListUpdateProps = {
  selectedDeviations: Deviation[];
  workers: WorkerData[];
  onUpdate: (payload: UpdateDeviationPayload) => void;
  onCancel: () => void;
};

export type UpdateDeviationPayload = {
  assignee: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const DeviationsListUpdate = (props: DeviationsListUpdateProps): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();

  const [state, setState] = useState<UpdateDeviationPayload>({
    assignee: '',
  });

  const { selectedDeviations, workers, onUpdate, onCancel } = props;

  const isUpdateDisabled = !state.assignee;

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [event.target.name]: event.target.value });

  return (
    <>
      <Box mb={2}>
        <Typography align="center">{`${selectedDeviations.length} ${t(
          'deviationsList.deviationsSelected'
        )}`}</Typography>
      </Box>
      <Box mb={2}>
        <Typography align="center">{t('deviationsListUpdate.info')}</Typography>
      </Box>
      <FormGroup>
        <TextField
          name="assignee"
          label={t('deviationsListUpdate.assignee')}
          variant="outlined"
          className={styles.select}
          defaultValue=""
          value={state.assignee}
          onChange={handleSelectChange}
          inputProps={{ 'data-testid': 'assignee-input' }}
          fullWidth
          select
        >
          {workers.map((worker) => (
            <MenuItem value={worker.employeeId} key={kebabCase(worker.displayName)}>
              {worker.displayName}
            </MenuItem>
          ))}
        </TextField>
      </FormGroup>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          className={styles.button}
          style={{ marginRight: theme.spacing(1) }}
          disabled={isUpdateDisabled}
          onClick={() => onUpdate(state)}
          fullWidth
        >
          {t('deviationsListUpdate.update')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          size="large"
          className={styles.button}
          onClick={onCancel}
          fullWidth
        >
          {t('deviationsListUpdate.cancel')}
        </Button>
      </Box>
    </>
  );
};

export default DeviationsListUpdate;
