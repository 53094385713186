import { createContext } from 'react';
import { AuthStateProps, AcquireTokenFunction } from '@konecorp/ui-library';
import { Installation } from '../schemas';
import { InstallationContextState } from './installation';

export type ErrorMessage = {
  message: string;
  error: unknown;
};

export interface ContextState {
  authState: AuthStateProps;
  networkNumber: string;
  installationData: Installation | null;
  ongoingInstallations: null | string[];
  toBeStartedInstallations: null | string[];
  completedInstallations: null | string[];
  isLoading: boolean;
  acquireToken?: AcquireTokenFunction;
  errorMessage: ErrorMessage | null;
  updateInstallationData: (installationData: Installation | null) => void;
  updateAuthState: (authState: AuthStateProps) => void;
  updateNetworkNumber: (networkNumber: string) => void;
  updateOngoingInstallations: (ongoinNetworkNumbers: string[]) => void;
  updateToBeStartedInstallations: (toBeStartedNetworkNumbers: string[]) => void;
  updateCompletedInstallations: (completedNetworkNumber: string[]) => void;
  updateIsLoading: (status: boolean) => void;
  updateAcquireToken: (newFunction: () => AcquireTokenFunction) => void;
  updateErrorMessage: (errorMessage: ErrorMessage | null) => void;
}

export default createContext({} as ContextState);

export const InstallationContext = createContext({} as InstallationContextState);
