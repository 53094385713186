import axios from 'axios';
import type { Attachment } from '../schemas';
import { API_TYPE, post } from './fetch';

type UploadParams = {
  files: File[];
  networkNumber: string;
  questionSequenceNumber: number | string;
  questionSetIdParam: string | null;
  jwtToken: string;
};

type UploadDeviationsParams = {
  files: File[];
  networkNumber: string;
  jwtToken: string;
};

type DownloadParams = {
  files: Attachment[];
  jwtToken: string;
};

type SignatureResponse = {
  signedUrl: string;
  originalFilename: string;
  locationFilename: string;
};

export enum FileType {
  ATTACHMENT,
  DEVIATION,
}

export const getDownloadSignedUrlForAttachment = async (
  params: DownloadParams
): Promise<SignatureResponse[]> => {
  const { files, jwtToken } = params;
  const payload = {
    attachments: files.map(({ filename, location }) => ({ filename, location })),
  };
  return await post('v1/attachments/download', jwtToken, API_TYPE.APPLICATION, payload);
};

export const getDownloadSignedUrlForDeviationsAttachment = async (
  params: DownloadParams
): Promise<SignatureResponse[]> => {
  const { files, jwtToken } = params;
  const payload = {
    attachments: files.map(({ filename, location }) => ({ filename, location })),
  };
  return await post(
    'v1/deviations/files/downloads',
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const getUploadSignedUrlForAttachment = async (
  params: UploadParams
): Promise<SignatureResponse[]> => {
  const { files, networkNumber, questionSequenceNumber, questionSetIdParam, jwtToken } =
    params;
  const payload = {
    filenames: files.map((file) => file.name),
  };
  return await post(
    `v1/attachments/${networkNumber}/${questionSetIdParam}/${Number(
      questionSequenceNumber
    )}/upload`,
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const getUploadSignedUrlForDeviationsAttachment = async (
  params: UploadDeviationsParams
): Promise<SignatureResponse[]> => {
  const { files, networkNumber, jwtToken } = params;
  const payload = {
    filenames: files.map((file) => file.name),
    networkNumber,
  };
  return await post(
    'v1/deviations/files/uploads',
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const uploadAttachments = async (
  params: UploadParams | UploadDeviationsParams,
  fileType = FileType.ATTACHMENT
): Promise<Attachment[] | undefined> => {
  const { files } = params;
  const attachmentParam: Attachment[] = [];
  try {
    const responseSignedURLList =
      fileType === FileType.ATTACHMENT
        ? await getUploadSignedUrlForAttachment(params as UploadParams)
        : await getUploadSignedUrlForDeviationsAttachment(
            params as UploadDeviationsParams
          );
    if (responseSignedURLList && responseSignedURLList.length > 0) {
      await Promise.all(
        responseSignedURLList.map((response: SignatureResponse, index: number) => {
          attachmentParam.push({
            filename: response.originalFilename,
            location: response.locationFilename,
          });
          return axios.put(`${response.signedUrl}`, files[index]);
        })
      );
    }
  } catch (e) {
    console.error('Failed to upload attachments', e);
  }
  return attachmentParam.length > 0 ? attachmentParam : undefined;
};
