import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  AppBar,
  ButtonBase,
  IconButton,
  Typography,
  Toolbar,
  Grid,
  Slide,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NaviBack, NaviInfo, theme } from '@konecorp/ui-library';

export interface SubHeaderTitleProps {
  title: string[];
}

const SubHeaderTitle = (props: PropsWithChildren<SubHeaderTitleProps>) => {
  const { title } = props;
  const TIMEOUT = 10000;
  const [index, setIndex] = useState(0);

  const tick = () => setIndex((i) => (i + 1) % title.length);

  useEffect(() => {
    const st = setTimeout(tick, TIMEOUT);
    return () => clearTimeout(st);
  }, [index]);

  if (title.length === 1) {
    return <Typography variant="h6">{title[0]}</Typography>;
  }

  const titleToRender = title[index];

  return (
    <Slide
      direction="down"
      in={true}
      timeout={1000}
      mountOnEnter
      unmountOnExit
      key={index}
    >
      <ButtonBase onClick={tick}>
        <Typography variant="h6">{titleToRender}</Typography>
      </ButtonBase>
    </Slide>
  );
};

export interface SubHeaderProps {
  handleGoBackClick?: () => void;
  handleInfoClick?: () => void;
  title: string | string[];
}

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    icon: {
      fontSize: '2.7rem',
      '& path': {
        fill: theme.palette.common.white,
      },
      '& #Ellipse_62': {
        stroke: theme.palette.common.white,
      },
    },
  })
);

const SubHeader = (props: PropsWithChildren<SubHeaderProps>): JSX.Element => {
  const { handleGoBackClick, handleInfoClick, title } = props;
  const classes = useStyles(theme);

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Grid container>
          <Grid container item xs={2} md={2}>
            {handleGoBackClick && (
              <IconButton
                color="inherit"
                data-testid="header__back-button"
                edge="start"
                onClick={handleGoBackClick}
              >
                <NaviBack className={classes.icon} />
              </IconButton>
            )}
          </Grid>
          <Grid container item xs={8} md={8} alignItems="center" justify="center">
            {Array.isArray(title) ? (
              <SubHeaderTitle title={title} />
            ) : (
              <Typography variant="h6">{title}</Typography>
            )}
          </Grid>
          <Grid container item xs={2} md={2}>
            {handleInfoClick && (
              <IconButton
                color="inherit"
                data-testid="header__info-button"
                edge="end"
                onClick={handleInfoClick}
              >
                <NaviInfo className={classes.icon} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default SubHeader;
