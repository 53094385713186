import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

type InstallationStartingInstallerProps = {
  setAllowStart: (value: boolean) => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    //This help the checkbox always align on the first line of the label
    checkBox: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(1),
      '& p': {
        paddingTop: theme.spacing(1),
      },
    },
    checkList: {
      fontSize: '20px', //make li dot bigger
    }
  })
);

const InstallationStartingInstaller = (
  props: InstallationStartingInstallerProps
): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { setAllowStart } = props;

  return (
    <Box className={classes.checkList} data-testid="installation-starting-installer">
      <Typography>{t('installationStartingInstaller.beforeStarting')}</Typography>
      <ul>
        <li>
          <Typography>{t('installationStartingInstaller.haveMaterials')}</Typography>
        </li>
        <li>
          <Typography>{t('installationStartingInstaller.haveTools')}</Typography>
        </li>
        <li>
          <Typography>{t('installationStartingInstaller.siteReady')}</Typography>
        </li>
        <li>
          <Typography>{t('installationStartingInstaller.scheduleOk')}</Typography>
        </li>
        <li>
          <Typography>
            {t('installationStartingInstaller.isPermissionsAndDocumentationChecked')}
          </Typography>
        </li>
      </ul>

      <FormControlLabel
        className={classes.checkBox}
        control={
          <Checkbox color="primary" onChange={(_, checked) => setAllowStart(checked)} />
        }
        color="primary"
        label={<Typography>{t('installationStartingInstaller.checked')}</Typography>}
      />
    </Box>
  );
};

export default InstallationStartingInstaller;
