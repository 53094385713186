import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, MenuItem, TextField, Typography } from '@material-ui/core';
import { InstallationStatus } from '../../schemas';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { removeDuplicates } from '../../helpers/arrays';

export type PutNetworkStatusFormPayload = {
  networkNumbers: string[];
  status: InstallationStatus;
};

export type UpdateNetworkStatusFormProps = {
  onSubmit: (formState: PutNetworkStatusFormPayload) => Promise<void>;
};

export const REGEXP = /^(\d{8})(\s*,\s*\d{8})*$/;

type NetworkStatusFormState = {
  networkNumbers: string;
  desiredState: InstallationStatus;
};

const UpdateNetworkStatusForm = ({
  onSubmit,
}: UpdateNetworkStatusFormProps): JSX.Element => {
  const initialState = {
    networkNumbers: '',
    desiredState: InstallationStatus.TO_BE_STARTED,
  };

  const [formState, setFormState] = useState<NetworkStatusFormState>(initialState);
  const [validationError, setValidationError] = useState('');
  const { t } = useTranslation();

  const stringToNetworkNumbers = (str: string) => str.split(',').map((s) => s.trim());

  const validateNetworkNumbers = (numbers: string) => {
    if (!REGEXP.test(numbers)) {
      setValidationError(t('updateNetworkStatusForm.validationError'));
    } else if (stringToNetworkNumbers(numbers).length > 20) {
      setValidationError(t('updateNetworkStatusForm.tooManyNetworksError'));
    } else {
      setValidationError('');
    }
  };

  const debouncedValidateNetworkNumbersChange = debounce(
    (numbers: string) => {
      validateNetworkNumbers(numbers);
    },
    250,
    { leading: true }
  );

  useEffect(() => {
    return () => {
      debouncedValidateNetworkNumbersChange.cancel();
    };
  }, [debouncedValidateNetworkNumbersChange]);

  const validateNetworkNumbersChange = useCallback(
    (numbers) => debouncedValidateNetworkNumbersChange(numbers),
    [setValidationError, validationError]
  );

  const handleNetworkNumbersChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setFormState({ ...formState, networkNumbers: e.target.value });
    validateNetworkNumbersChange(e.target.value);
  };

  const handleInstallationStatusChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    e.preventDefault();
    setFormState({ ...formState, desiredState: e.target.value as InstallationStatus });
  };

  const handleSubmit = async () => {
    const networkNumbers = removeDuplicates(
      stringToNetworkNumbers(formState.networkNumbers)
    );
    await onSubmit({
      status: formState.desiredState,
      networkNumbers,
    });
    setFormState({ ...formState, networkNumbers: '' });
  };

  const disableSubmitButton = !!validationError || !formState.networkNumbers;

  return (
    <Box>
      <Box mt={2}>
        <Typography variant="body1">
          {t('updateNetworkStatusForm.description')}
        </Typography>
      </Box>

      <Box mt={2} mb={3}>
        <TextField
          fullWidth
          required
          multiline
          rows={4}
          id="networkNumbers"
          label="Network Numbers"
          name="networkNumbers"
          variant="outlined"
          onChange={handleNetworkNumbersChange}
          value={formState.networkNumbers}
          data-testid="network-numbers-textfield"
          inputProps={{
            'data-testid': 'network-numbers-textarea',
          }}
          error={!!validationError}
          helperText={`${validationError} ${t(
            'updateNetworkStatusForm.networkNumbersHelperText'
          )}`}
        ></TextField>
      </Box>

      <Box mt={3}>
        <TextField
          fullWidth
          required
          label="Desired State"
          name="desiredState"
          variant="outlined"
          select
          onChange={handleInstallationStatusChange}
          value={formState.desiredState}
          data-testid="installation-status-select"
        >
          {Object.values(InstallationStatus).map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box my={2}>
        <Button
          disabled={disableSubmitButton}
          variant="outlined"
          size="large"
          color="primary"
          data-testid="installation-status-submit"
          onClick={handleSubmit}
        >
          {t('updateNetworkStatusForm.submitButton')}
        </Button>
      </Box>
    </Box>
  );
};

export default UpdateNetworkStatusForm;
