import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useIfSubcontractor = (): [boolean] => {
  const location = useLocation();

  const ifSubcontractor = useMemo(() => {
    return location.pathname.includes('subcontractor');
  }, [location.pathname]);

  return [ifSubcontractor];
};
