import { useContext } from 'react';
import Context from '../context/index';
import { useGetCurrentUserRole } from './useGetCurrentUserRole';
import { ActivityDifferentiator, Answer, Installation } from '../schemas';

type updateAnswerInContextFunction = (
  newAnswer: Answer,
  questionSetIdParam: string | null,
  questionSequenceNumber: number | null
) => void;

export const useUpdatePreChecklistAnswer = (): [updateAnswerInContextFunction] => {
  const { installationData, updateInstallationData } = useContext(Context);
  const [userRole] = useGetCurrentUserRole();

  const updatePreChecklistAnswer = (
    newAnswer: Answer,
    questionSetIdParam: string | null,
    questionSequenceNumber: number | null
  ) => {
    if (newAnswer && questionSequenceNumber !== null && questionSetIdParam) {
      switch (userRole) {
        case ActivityDifferentiator.SPV: {
          const newAnswerSets = installationData?.preInstallCheckListAnswers?.map(
            (answerSet) => {
              if (answerSet.questionSetId === questionSetIdParam) {
                const newAnswers = answerSet.answers.map((ans, i) =>
                  i === questionSequenceNumber ? newAnswer : ans
                );
                return {
                  questionSetId: answerSet.questionSetId,
                  answers: newAnswers,
                };
              }
              return answerSet;
            }
          );
          updateInstallationData({
            ...installationData,
            preInstallCheckListAnswers: newAnswerSets,
          } as Installation);
          break;
        }
      }
    }
  };
  return [updatePreChecklistAnswer];
};
