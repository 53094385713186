/** Return new array with element at index replaced with newValue */
export const replaceIndex = <T>(arr: T[], index: number, newValue: T): T[] =>
  arr.slice(0, index).concat(newValue, arr.slice(index + 1));

/** Return new array with unique members */
export const removeDuplicates = <T>(arr: T[]): T[] =>
  arr.reduce((acc: T[], curr: T) => (acc.includes(curr) ? acc : [...acc, curr]), []);

/** Return object from array of [key, value] tuples*/
export const tuplesToObject = <T>(arr: [string, T][]): { [key: string]: T } =>
  arr.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

/** Shuffle an array order using Fisher-Yates algorithm */
export const shuffle = <T>(arr: T[], i = 0): T[] => {
  if (i >= arr.length) return arr;
  const j = Math.floor(Math.random() * (i + 1));
  const replacedArr = replaceIndex(arr, i, arr[j]);
  return shuffle(replaceIndex(replacedArr, j, arr[i]), i + 1);
};
