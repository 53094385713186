import React, { useContext, useState } from 'react';
import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { theme } from '@konecorp/ui-library';
import {
  BuildOutlined,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Deviation, DeviationStatus, DeviationVariation } from '../../schemas';
import Context, { InstallationContext } from '../../context';
import DeviationForm, {
  CreateDeviationPayload,
  DeviationFormPrefill,
  EditDeviationPayload,
} from '../DeviationForm';
import { isInstallationCompleted } from '../../helpers/getInstallationLists';

const useStyles = makeStyles(() => ({
  tableCell: {
    border: 'none',
    padding: 0,
  },
  icon: {
    width: 30,
    height: 30,
  },
  largeIcon: {
    width: 45,
    height: 45,
  },
  listItem: {
    backgroundColor: 'rgb(245,251,255)',
    marginLeft: 5,
    maxWidth: 'calc(100vw - 5px)',
  },
  divider: {
    backgroundColor: theme.palette.secondary.light,
  },
  closeSnagButton: {
    padding: 0,
  },
  activeBackground: {
    backgroundColor: theme.palette.info.main,
  },
  disabledBackground: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  activeTitle: {
    paddingLeft: '1em',
    color: theme.palette.text.primary,
  },
  disabledTitle: {
    paddingLeft: '1em',
    color: theme.palette.action.disabled,
  },
  snagTitle: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 100px)',
  },
  listItemAction: {
    right: 0,
    marginRight: theme.spacing(1),
  },
  dialogContent: {
    padding: 0,
  },
}));

type SnagProps = {
  snag: Deviation;
  openEditForm: (snag: Deviation) => void;
  onCloseSnag: (snag: Deviation) => Promise<void>;
};

const Snag = (props: SnagProps): JSX.Element => {
  const { snag, onCloseSnag, openEditForm } = props;
  const classes = useStyles(theme);

  return (
    <>
      <ListItem className={classes.listItem}>
        <ListItemText
          onClick={() => openEditForm(snag)}
          className={classes.snagTitle}
          primary={<Typography noWrap>{snag.description}</Typography>}
        />
        <ListItemSecondaryAction className={classes.listItemAction}>
          <IconButton
            className={classes.closeSnagButton}
            data-testid={`close-snag-${snag.guid}`}
            onClick={async () => await onCloseSnag(snag)}
          >
            {snag.status === DeviationStatus.CLOSED ? (
              <CheckCircleRounded htmlColor="#59ab46" className={classes.largeIcon} />
            ) : (
              <CheckCircleOutlineRounded
                htmlColor={theme.palette.secondary.main}
                className={classes.largeIcon}
              />
            )}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider className={classes.divider} light />
    </>
  );
};

export type SnagListProps = {
  id: string;
  questionSetId: string;
  questionSequence: number;
  onCreateSnag: (deviation: CreateDeviationPayload) => Promise<void>;
  onEditSnag: (deviation: EditDeviationPayload) => Promise<void>;
  onCloseSnag: (snag: Deviation) => Promise<void>;
};

const SnagList = (props: SnagListProps): JSX.Element => {
  const { id, questionSetId, questionSequence, onCreateSnag, onEditSnag, onCloseSnag } =
    props;
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const { installationData } = useContext(Context);
  const { deviations } = useContext(InstallationContext);

  const [openSnagList, setOpenSnagList] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [snagToEdit, setSnagToEdit] = useState<Deviation>();
  const [prefill, setPrefill] = useState<DeviationFormPrefill>();

  const snags = deviations.filter(
    (deviation) =>
      deviation.questionSetId === questionSetId &&
      deviation.questionSequence === questionSequence &&
      deviation.variation === DeviationVariation.SNAG
  );

  const openCreateSnagForm = () => {
    setPrefill({
      questionSetId,
      questionSequence,
      variation: DeviationVariation.SNAG,
    });
    setSnagToEdit(undefined);
    setDialogOpen(true);
  };

  const openEditSnagForm = (snagToEdit: Deviation) => {
    setPrefill(undefined);
    setSnagToEdit(snagToEdit);
    setDialogOpen(true);
  };

  const closeSnagFormDialog = () => {
    setSnagToEdit(undefined);
    setPrefill(undefined);
    setDialogOpen(false);
  };

  const isSnagListEmpty = !snags.length;

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        <Paper variant="outlined" data-testid={`${id}-snag-list-container`}>
          <Grid container>
            <Grid
              xs={1}
              item
              className={
                isSnagListEmpty ? classes.disabledBackground : classes.activeBackground
              }
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <IconButton>
                  <BuildOutlined
                    htmlColor={theme.palette.background.default}
                    className={classes.icon}
                  />
                </IconButton>
              </Box>
            </Grid>
            <Grid xs={8} container item alignContent="center">
              <Typography
                className={isSnagListEmpty ? classes.disabledTitle : classes.activeTitle}
              >
                {t('snagList.title')} ({snags.length})
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <IconButton
                  data-testid={`${id}-snag-add-to-list`}
                  onClick={openCreateSnagForm}
                  disabled={isInstallationCompleted(installationData?.status)}
                >
                  <Add
                    htmlColor={theme.palette.secondary.main}
                    className={classes.icon}
                  />
                </IconButton>
                <IconButton
                  data-testid={`${id}-snag-open-list`}
                  disabled={isSnagListEmpty}
                  onClick={() => setOpenSnagList(!openSnagList)}
                >
                  {openSnagList ? (
                    <KeyboardArrowUp
                      htmlColor={theme.palette.secondary.main}
                      className={classes.icon}
                    />
                  ) : (
                    <KeyboardArrowDown
                      htmlColor={theme.palette.secondary.main}
                      className={classes.icon}
                    />
                  )}
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Collapse unmountOnExit in={openSnagList}>
            <List disablePadding data-testid={`${id}-snag-list`}>
              <Box
                className={
                  isSnagListEmpty ? classes.disabledBackground : classes.activeBackground
                }
              >
                {snags.map((snag) => (
                  <Snag
                    key={snag.guid}
                    snag={snag}
                    openEditForm={openEditSnagForm}
                    onCloseSnag={onCloseSnag}
                  />
                ))}
              </Box>
            </List>
          </Collapse>
          <Dialog open={isDialogOpen} fullScreen>
            <DialogContent className={classes.dialogContent}>
              <DeviationForm
                initialDeviation={snagToEdit}
                prefill={prefill}
                onCreate={onCreateSnag}
                onEdit={onEditSnag}
                onClear={closeSnagFormDialog}
              />
            </DialogContent>
          </Dialog>
        </Paper>
      </TableCell>
    </TableRow>
  );
};

export default SnagList;
