import React, { useContext, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { uniqBy } from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  DeviationBlocker,
  DeviationMinor,
  InfoModal,
  NaviClose,
  theme,
} from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

import Context from '../../context';
import { API_TYPE, fetchEmployeeFullName, get, put } from '../../helpers/fetch';
import {
  ActivityDifferentiator,
  Deviation,
  DeviationStatus,
  Handover,
  Installation,
  InstallationStatus,
  SpecialUserIds,
} from '../../schemas';
import { NetworkInstallationPaths } from '../../containers/NetworkInstallation';
import HandoverSummaryPreview from '../HandoverSummaryPreview';
import { useGetToken } from '../../hooks/useGetToken';

enum DialogType {
  NONE,
  HANDOVER,
  INFO,
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.shape.borderRadius,
    margin: '10px auto',
    padding: theme.spacing(2),
    textTransform: 'uppercase',
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    width: 40,
    height: 40,
  },
  dialogPaper: {
    height: '80%',
  },
  dialogActions: {
    margin: theme.spacing(1),
  },
  actionText: {
    margin: theme.spacing(1, 0),
  },
}));

type VerifyInstallationDialogProps = {
  isOpen: boolean;
  handover: Handover | null;
  onVerify: () => void;
  onClose: () => void;
};

const VerifyInstallationDialog = ({
  isOpen,
  handover,
  onClose,
  onVerify,
}: VerifyInstallationDialogProps) => {
  const classes = useStyles(theme);
  const { t } = useTranslation();
  return (
    <Dialog
      data-testid="handover-dialog"
      open={isOpen}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
    >
      <DialogTitle>
        {t('installationActions.verifyHandover')}
        <NaviClose className={classes.closeIcon} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" className={classes.actionText}>
          {t('installationActions.verifyHandoverFromInstaller')}
        </Typography>
        <HandoverSummaryPreview handover={handover} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="primary"
          variant="outlined"
          data-testid="cancel-button"
          onClick={onClose}
          fullWidth
        >
          {t('installationActions.cancelButton')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          data-testid="verify-button"
          onClick={onVerify}
          fullWidth
        >
          {t('installationActions.acceptButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type InstallationActionsProps = {
  deviations: Deviation[];
  networkNumber: string;
};

const InstallationActions = ({
  deviations,
  networkNumber,
}: InstallationActionsProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles(theme);
  const history = useHistory();
  const {
    installationData,
    updateErrorMessage,
    updateIsLoading,
    updateInstallationData,
  } = useContext(Context);
  const [getTokenFunction] = useGetToken();

  const [openDialog, setOpenDialog] = useState(DialogType.NONE);
  const [handoverCreatorName, setHandoverCreatorName] = useState<string>('');
  const [handover, setHandover] = useState<Handover | null>(null);
  const [newStatus, setNewStatus] = useState<InstallationStatus | null>(null);

  useEffect(() => {
    (async (): Promise<Handover | null> => {
      try {
        updateIsLoading(true);
        const accessToken = await getTokenFunction();
        const handoverData: Handover = await get(
          `v1/installations/${networkNumber}/handovers/latest`,
          accessToken
        );
        setHandover(handoverData);

        const subcontractors = uniqBy(
          installationData?.subcontractors || [],
          'activityDifferentiator'
        );

        const getSubcontractorName = (role: ActivityDifferentiator): string =>
          subcontractors.find(
            (subcontractor) => subcontractor.activityDifferentiator === role
          )?.subcontractor.name || t('installationActions.subcontractor');

        const creatorName = await (async () => {
          switch (handoverData.creator) {
            case SpecialUserIds.SUBCONTRACTOR_INSTALLER:
              return getSubcontractorName(ActivityDifferentiator.INST);
            case SpecialUserIds.SUBCONTRACTOR_TESTER:
              return getSubcontractorName(ActivityDifferentiator.CMSN);
            default:
              return await fetchEmployeeFullName(handoverData.creator, accessToken);
          }
        })();

        setHandoverCreatorName(creatorName);
      } catch (error) {
        updateErrorMessage({ message: 'Error while fetching handover data', error });
      } finally {
        updateIsLoading(false);
      }
      return null;
    })();
  }, []);

  const byBlockers = (deviation: Deviation) => deviation.blocker || deviation.compliance;
  const byNonBlockers = (deviation: Deviation) => !byBlockers(deviation);

  const openDeviations = deviations.filter(
    ({ status }) => status === DeviationStatus.OPEN
  );

  const approveInstallationInspection = async () => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();
      const updatedInstallation: Installation | null = await put(
        `v1/installations/${networkNumber}/status`,
        accessToken,
        API_TYPE.APPLICATION,
        { status: InstallationStatus.FOR_TESTER_ACCEPTANCE }
      );
      if (updatedInstallation) {
        setNewStatus(updatedInstallation.status);
        setOpenDialog(DialogType.INFO);
      }
    } catch (error) {
      updateErrorMessage({ message: 'Error while updating installation status', error });
    } finally {
      updateIsLoading(false);
    }
  };

  const handleCloseInfoModal = () => {
    setOpenDialog(DialogType.NONE);
    if (newStatus)
      updateInstallationData({ ...installationData, status: newStatus } as Installation);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="body1">
        {t('installationActions.handoverFrom', { creator: handoverCreatorName })}
      </Typography>
      <Box my={2} display="flex">
        <Link
          variant="body1"
          onClick={() =>
            history.push(
              generatePath(NetworkInstallationPaths.DEVIATIONS, { networkNumber })
            )
          }
        >
          {t('installationActions.openDeviations')}
        </Link>
        <Box pl={3} display="flex">
          <DeviationBlocker className={classes.icon} />
          <Typography variant="body1" style={{ marginRight: 10 }}>
            {openDeviations.filter(byBlockers).length}
          </Typography>
          <DeviationMinor className={classes.icon} />
          <Typography variant="body1">
            {openDeviations.filter(byNonBlockers).length}
          </Typography>
        </Box>
      </Box>

      <Button
        color="primary"
        variant="outlined"
        data-testid="open-handover-dialog-button"
        onClick={() => setOpenDialog(() => DialogType.HANDOVER)}
        fullWidth
      >
        {t('installationActions.openHandoverDialog')}
      </Button>
      <VerifyInstallationDialog
        isOpen={openDialog === DialogType.HANDOVER}
        handover={handover}
        onVerify={async () => await approveInstallationInspection()}
        onClose={() => setOpenDialog(() => DialogType.NONE)}
      />
      <InfoModal
        open={openDialog === DialogType.INFO}
        message={t('installationActions.updateSuccessful')}
        onClose={handleCloseInfoModal}
        closeButtonText={t('installationActions.okButton')}
        isCenteredMessage
      />
    </Box>
  );
};

export default InstallationActions;
