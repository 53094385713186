import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import { CancelRounded, CheckCircleRounded } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { InfoModal, theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';
import UploadWidget from '../UploadWidget';
import { Attachment } from '../../schemas';
import { SendHandoverParams } from '../../containers/Handover';
import { useCheckConnection } from '../../hooks/useCheckConnection';

export type NebSupervisorCompletionProps = {
  commentText: string;
  selectedFiles: File[];
  isAccepted: boolean;
  deleteAttachmentsLocally: (file: File | Attachment) => void;
  handleUploadButton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sendHandover: (params: SendHandoverParams) => Promise<void>;
  isReadOnly?: boolean;
  setCommentText: (comment: string) => void;
};

const NebSupervisorCompletion = (props: NebSupervisorCompletionProps): JSX.Element => {
  const {
    commentText,
    deleteAttachmentsLocally,
    handleUploadButton,
    isAccepted,
    selectedFiles,
    sendHandover,
    isReadOnly,
    setCommentText,
  } = props;

  const useStyles = makeStyles((theme) =>
    createStyles({
      commentBox: { width: '17.4rem' },
      commentLabel: { marginBottom: '0.5rem' },
      confirmCompleteButtonContainer: {
        alignSelf: 'center',
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(2),
        marginTop: '1.7rem',
        padding: '2px',
        width: 'fit-content',
      },
      icon: { height: '1.5rem', marginRight: '0.3rem', width: '1.5rem' },
      handoverContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.25rem',
      },
      inspectionStatus: { display: 'flex', width: '100%', justifyContent: 'center' },
      statusDisplay: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '2.6rem',
        textTransform: 'uppercase',
      },
      statusLabel: { width: '7.5rem' },
      textInput: {
        width: '100%',
        marginBottom: '0.5rem',
        '& > div': { padding: 0 },
        '& textarea': { padding: '0.7rem' },
      },
      title: { fontWeight: 700, marginBottom: '2.25rem' },
      uploadWidget: { marginBottom: '1rem' },
    })
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const [offlineHandover, setOfflineHandover] = useState<boolean>(false);
  const [isOnline] = useCheckConnection();
  const handleSendHandover = () => {
    if (isOnline) {
      const params: SendHandoverParams = {
        comment: commentText,
        files: selectedFiles,
      };
      sendHandover(params);
    } else {
      setOfflineHandover(true);
    }
  };

  return (
    <Box
      className={`${classes.handoverContainer}`}
      component="section"
      data-testid="neb-supervisor-complete"
    >
      <Typography component="h1" variant="h6" align="center" className={classes.title}>
        {t('nebSupervisorCompletion.title')}
      </Typography>
      <Box className={classes.inspectionStatus}>
        <Typography variant="body2" className={classes.statusLabel}>
          {t('nebSupervisorCompletion.statusLabel')}
        </Typography>
        {isAccepted ? (
          <Box className={classes.statusDisplay}>
            <CheckCircleRounded
              htmlColor={theme.palette.success.main}
              className={classes.icon}
            />
            <Typography variant="body2">
              {t('nebSupervisorCompletion.statusAccepted')}
            </Typography>
          </Box>
        ) : (
          <Box className={classes.statusDisplay}>
            <CancelRounded
              htmlColor={theme.palette.error.main}
              className={classes.icon}
            />
            <Typography variant="body2">
              {t('nebSupervisorCompletion.statusRejected')}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className={classes.commentBox}>
        <Typography variant="body2" className={classes.commentLabel}>
          {t('nebSupervisorCompletion.commentLabel')}
        </Typography>
        <TextField
          variant="outlined"
          className={classes.textInput}
          placeholder={t('nebSupervisorCompletion.commentPlaceholder')}
          id="standard-multiline-static"
          value={commentText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCommentText(event.target.value);
          }}
          multiline
          rows={3}
          inputProps={{
            'data-testid': 'comment-input',
          }}
        />
      </Box>
      <UploadWidget
        deleteAttachmentsLocally={deleteAttachmentsLocally}
        handleUploadButton={handleUploadButton}
        selectedFiles={selectedFiles}
      />
      <Box className={classes.confirmCompleteButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendHandover}
          data-testid="confirm-complete-button"
          disableElevation
          disabled={isReadOnly}
        >
          {t('nebSupervisorCompletion.confirmComplete')}
        </Button>
      </Box>
      {offlineHandover && (
        <InfoModal
          open={true}
          message={t('connection.offlineHandover')}
          onClose={(): void => setOfflineHandover(false)}
          closeButtonText={t('connection.ok')}
          isCenteredMessage
        />
      )}
    </Box>
  );
};

export default NebSupervisorCompletion;
