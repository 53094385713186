/* eslint-disable no-console */
import i18next from 'i18next';

import { get } from './fetch';
import {
  InstallationCardData,
  InstallationStatus,
  NetworkDeviationCount,
  Installation,
  ExtendedInstallation,
  CompactNetwork,
  AnswerSet,
} from '../schemas';
import { getLocalizedText } from './custom-i18n';
import { getDeviationsData } from './deviationActions';

export enum NumberType {
  NETWORK_NUMBER = 'networkNumber',
  EQUIPMENT_NUMBER = 'equipmentNumber',
}

export enum CompareStatusResult {
  BEFORE,
  SAME,
  AFTER,
}

/** An array of all InstallationStatus enum values */
export const installationStatuses = Object.values(InstallationStatus);
/** Returns the index of an InstallationStatus value in the
 * InstallationStatus enum for comparison (greater-than / less-than) purposes */
export const getInstallationStatusIndex = (status: InstallationStatus): number =>
  installationStatuses.indexOf(status);

export const compareStatus = (
  firstStatus: InstallationStatus,
  secondStatus: InstallationStatus
): CompareStatusResult => {
  const firstStatusIndex = getInstallationStatusIndex(firstStatus);
  const secondStatusIndex = getInstallationStatusIndex(secondStatus);

  if (firstStatusIndex > secondStatusIndex) return CompareStatusResult.AFTER;

  if (firstStatusIndex < secondStatusIndex) return CompareStatusResult.BEFORE;

  return CompareStatusResult.SAME;
};
export const betweenStatus = (
  status: InstallationStatus,
  minStatus: InstallationStatus,
  maxStatus: InstallationStatus
): boolean =>
  compareStatus(status, minStatus) !== CompareStatusResult.BEFORE &&
  compareStatus(status, maxStatus) !== CompareStatusResult.AFTER;

export const isInstallationCompleted = (status?: InstallationStatus | null): boolean =>
  compareStatus(
    status || InstallationStatus.TO_BE_STARTED,
    InstallationStatus.INSTALLATION_COMPLETE
  ) !== CompareStatusResult.BEFORE;

export const sortInstallationsCardData = (
  firstInstallationCardData: InstallationCardData,
  secondInstallationCardData: InstallationCardData
): 1 | -1 => {
  const { network: firstNetwork } = firstInstallationCardData;
  const { network: secondNetwork } = secondInstallationCardData;

  if (firstNetwork.salesOrderDescription > secondNetwork.salesOrderDescription) {
    return 1;
  }
  if (
    firstNetwork.salesOrderDescription < secondNetwork.salesOrderDescription ||
    firstNetwork.networkNumber > secondNetwork.networkNumber
  ) {
    return -1;
  }

  return 1;
};

const getFurthestAnsweredQdQuestionSetId = (answers: AnswerSet[]): string | null => {
  for (let index = answers.length - 1; index >= 0; index--) {
    const questionSetHasAnswer = answers[index].answers.some(
      (answer) => answer && answer.value !== null
    );

    if (questionSetHasAnswer) {
      return answers[index].questionSetId;
    }
  }
  return null;
};

const getLatestAnsweredQdQuestionSetText = (installation: Installation): string => {
  const { installerQuestions, installerAnswers, questionSetInfo, status } = installation;

  if (
    status === InstallationStatus.TO_BE_STARTED ||
    status === InstallationStatus.FOR_INSTALLER_ACCEPTANCE
  ) {
    return i18next.t('installationCard.notStarted');
  }
  if (
    (installerQuestions?.length <= 1 &&
      installerQuestions[0]?.questionSetId === 'TC000002') ||
    status === InstallationStatus.FOR_SEB_ACCEPTANCE ||
    status === InstallationStatus.SEB_ACCEPTED ||
    status === InstallationStatus.SEB_REJECTED
  ) {
    return i18next.t('installationCard.started');
  }

  const furthestAnsweredQdQuestionSetId =
    getFurthestAnsweredQdQuestionSetId(installerAnswers);
  if (furthestAnsweredQdQuestionSetId === null)
    return i18next.t('installationCard.notStarted');

  const localizedTextObject = questionSetInfo?.find(
    (questionSet) => questionSet.setId === furthestAnsweredQdQuestionSetId
  );
  if (localizedTextObject !== undefined) {
    return (
      getLocalizedText(i18next, localizedTextObject.description)?.text ||
      furthestAnsweredQdQuestionSetId
    );
  }
  return furthestAnsweredQdQuestionSetId;
};

export const getNetworkDeviationCount = async (
  networkNumber: string,
  token: string
): Promise<NetworkDeviationCount | null> => {
  try {
    const deviations = await getDeviationsData(token, networkNumber);
    const normalDeviations = deviations.filter(
      (deviation) => !deviation.blocker && deviation.status === 'OPEN'
    );
    const blockerDeviations = deviations.filter(
      (deviation) => deviation.blocker && deviation.status === 'OPEN'
    );

    return {
      normal: normalDeviations.length,
      blockers: blockerDeviations.length,
    };
  } catch (error) {
    return null;
  }
};

export const getInstallationData = async (
  number: string,
  token: string,
  returnDefault = false,
  getBy?: NumberType
): Promise<ExtendedInstallation | null> => {
  const defaultInstallationData: ExtendedInstallation = {
    firstAssignmentDate: '',
    hasQualityForm: null,
    latestAnsweredQdQuestionSet: i18next.t('installationCard.unavailable'),
  };
  const url = getBy
    ? `v1/installations/${number}?getBy=${getBy}`
    : `v1/installations/${number}`;

  try {
    const fetchedInstallation: Installation = await get(url, token);

    if (!fetchedInstallation && returnDefault) {
      return defaultInstallationData;
    } else if (!fetchedInstallation && !returnDefault) {
      return null;
    }

    const sortedAssignees = [...fetchedInstallation.assignees].sort(
      (firstAssignment, secondAssignment) => {
        return firstAssignment.assignmentStartDate.localeCompare(
          secondAssignment.assignmentStartDate
        );
      }
    );
    const latestAnsweredQdQuestionSet =
      getLatestAnsweredQdQuestionSetText(fetchedInstallation);

    return {
      ...fetchedInstallation,
      firstAssignmentDate: sortedAssignees[0]?.assignmentStartDate.substr(0, 10),
      hasQualityForm: fetchedInstallation.installerQuestions?.length > 0,
      latestAnsweredQdQuestionSet,
    };
  } catch (error) {
    if (returnDefault) {
      return defaultInstallationData;
    } else {
      return null;
    }
  }
};

export const getInstallationCardData = async (
  networkNumber: string,
  token: string
): Promise<InstallationCardData> => {
  const [deviations, installation] = await Promise.all([
    getNetworkDeviationCount(networkNumber, token),
    getInstallationData(networkNumber, token, true, NumberType.NETWORK_NUMBER),
  ]);
  return {
    deviations,
    installation,
    network: installation as CompactNetwork,
  } as InstallationCardData;
};
