import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grow, Paper } from '@material-ui/core';
import { DeviationBlocker, DeviationMinor } from '@konecorp/ui-library';
import { formatDate } from '../../helpers/formating';
import { useTranslation } from 'react-i18next';

import { Deviation, DeviationStatus } from '../../schemas';

const useStyles = makeStyles((theme) =>
  createStyles({
    summary: {
      borderWidth: '0.1em',
      border: 'solid',
      borderColor: theme.palette.secondary.light,
      '& p': {
        lineHeight: theme.spacing(0.3),
      },
    },
    deviationGrid: {
      display: 'grid',
      gridTemplateColumns: '3fr 1fr 1fr',
    },
    dateGrid: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
    },
    rootPaper: {
      width: '100%',
    },
  })
);

export type HandoverStatusWithSummaryProps = {
  plannedStartDate?: string;
  plannedTargetDate?: string;
  actualStartDate?: string | null;
  actualTargetDate?: string | null;
  deviations?: Deviation[];
};

type CountObject = {
  count: number;
  delay: number;
};

type InstallationDeviationDetails = {
  blocker: CountObject;
  nonBlocker: CountObject;
};

type InstallationDeviationCount = {
  all: InstallationDeviationDetails;
  closed: InstallationDeviationDetails;
  open: InstallationDeviationDetails;
};

const getInstallationDeviationCount = (
  deviations: Deviation[]
): InstallationDeviationCount => {
  const byStatus = (status: DeviationStatus) => (deviation: Deviation) =>
    deviation.status === status;
  const byBlockers = (deviation: Deviation) => deviation.blocker || deviation.compliance;
  const byNonBlockers = (deviation: Deviation) => !byBlockers(deviation);
  const sumDelay = (count: number, deviation: Deviation) =>
    count + (deviation.delay || 0);

  const openDeviations = deviations.filter(byStatus(DeviationStatus.OPEN));
  const closedDeviations = deviations.filter(byStatus(DeviationStatus.CLOSED));
  const allDeviations = [...openDeviations, ...closedDeviations]; // except cancelled

  return {
    all: {
      blocker: {
        count: allDeviations.filter(byBlockers).length,
        delay: allDeviations.filter(byBlockers).reduce(sumDelay, 0),
      },
      nonBlocker: {
        count: allDeviations.filter(byNonBlockers).length,
        delay: allDeviations.filter(byNonBlockers).reduce(sumDelay, 0),
      },
    },
    open: {
      blocker: {
        count: openDeviations.filter(byBlockers).length,
        delay: openDeviations.filter(byBlockers).reduce(sumDelay, 0),
      },
      nonBlocker: {
        count: openDeviations.filter(byNonBlockers).length,
        delay: openDeviations.filter(byNonBlockers).reduce(sumDelay, 0),
      },
    },
    closed: {
      blocker: {
        count: closedDeviations.filter(byBlockers).length,
        delay: closedDeviations.filter(byBlockers).reduce(sumDelay, 0),
      },
      nonBlocker: {
        count: closedDeviations.filter(byNonBlockers).length,
        delay: closedDeviations.filter(byNonBlockers).reduce(sumDelay, 0),
      },
    },
  };
};
const HandoverStatusWithSummary = (
  props: HandoverStatusWithSummaryProps
): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    actualStartDate,
    actualTargetDate,
    plannedStartDate,
    plannedTargetDate,
    deviations,
  } = props;

  const plannedStartFormatted = formatDate(plannedStartDate) ?? '';
  const plannedTargetFormatted = formatDate(plannedTargetDate) ?? '';
  const actualStartFormatted = formatDate(actualStartDate) ?? '';
  const actualTargetFormatted = formatDate(actualTargetDate) ?? '';

  const deviationsCount = getInstallationDeviationCount(deviations || []);

  return (
    <Grow in={true} timeout={700}>
      <Paper className={classes.rootPaper}>
        <Box className={classes.summary} p={2} pt={3} pr={3}>
          <Typography variant="h6">
            {t('handoverStatusPage.installationSummary')}
          </Typography>

          <Box pt={4} className={classes.dateGrid}>
            <Typography variant="body2">
              {t('handoverStatusPage.plannedStartDate')}
            </Typography>
            <Typography variant="body2" align="right" data-testid="planned-start">
              {plannedStartFormatted}
            </Typography>
          </Box>

          <Box className={classes.dateGrid}>
            <Typography variant="body2">
              {t('handoverStatusPage.actualStartDate')}
            </Typography>
            <Typography variant="body2" align="right" data-testid="actual-start">
              {actualStartFormatted}
            </Typography>
          </Box>

          <Box className={classes.dateGrid}>
            <Typography variant="body2">
              {t('handoverStatusPage.plannedTargetDate')}
            </Typography>
            <Typography variant="body2" align="right" data-testid="planned-target">
              {plannedTargetFormatted}
            </Typography>
          </Box>

          <Box className={classes.dateGrid}>
            <Typography variant="body2">
              {t('handoverStatusPage.actualTargetDate')}
            </Typography>
            <Typography variant="body2" align="right" data-testid="actual-target">
              {actualTargetFormatted}
            </Typography>
          </Box>

          <Box pt={4} className={classes.deviationGrid}>
            <Box>
              <Typography variant="body2">
                {t('handoverStatusPage.deviations')}
              </Typography>
            </Box>
            <Box textAlign="center">
              <DeviationBlocker />
            </Box>
            <Box textAlign="center">
              <DeviationMinor />
            </Box>
          </Box>

          <Box className={classes.deviationGrid}>
            <Box pl={2}>
              <Typography variant="body2">
                {t('handoverStatusPage.totalInNetwork')}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="body2" data-testid="totalBlockerInProject">
                {deviationsCount.all.blocker.count}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="body2" data-testid="totalNonBlockerInProject">
                {deviationsCount.all.nonBlocker.count}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.deviationGrid}>
            <Box pl={2}>
              <Typography variant="body2">{t('handoverStatusPage.closed')}</Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="body2" data-testid="closedBlocker">
                {deviationsCount.closed.blocker.count}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="body2" data-testid="closedNonBlocker">
                {deviationsCount.closed.nonBlocker.count}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.deviationGrid}>
            <Box pl={2}>
              <Typography variant="body2">{t('handoverStatusPage.stillOpen')}</Typography>
            </Box>
            <Box textAlign="center" data-testid="openBlocker">
              <Typography variant="body2">
                {deviationsCount.open.blocker.count}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="body2" data-testid="openNonBlocker">
                {deviationsCount.open.nonBlocker.count}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.deviationGrid}>
            <Box pl={2}>
              <Typography variant="body2">
                {t('handoverStatusPage.totalEstimatedDelay')}
              </Typography>
            </Box>
            <Box textAlign="center" data-testid="totalDelayBlocker">
              <Typography variant="body2">
                {deviationsCount.all.blocker.delay}
                {t('units.hour')}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="body2" data-testid="totalDelayNonBlocker">
                {deviationsCount.all.nonBlocker.delay}
                {t('units.hour')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grow>
  );
};

export default HandoverStatusWithSummary;
