import React, { useState } from 'react';
import { Collapse, IconButton, Typography, Grid } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

export type ContactInformationProps = {
  supervisor?: string;
  customerContact?: string;
  testers: string[];
  installers: string[];
};

const useStyles = makeStyles(() =>
  createStyles({
    'row-level-1': {
      backgroundColor: '#005A93',
      color: '#FFFFFF',
      '& p': {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
      '& .MuiIconButton-root': {
        paddingTop: theme.spacing(1),
        color: '#FFFFFF',
      },
      height: theme.typography.fontSize * 3,
    },
    'row-odd': {
      backgroundColor: '#F5F6F7',
      minHeight: theme.typography.fontSize * 3,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    'row-even': {
      backgroundColor: '#FFFFFF',
      minHeight: theme.typography.fontSize * 3,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
  })
);

const ContactInformation = (props: ContactInformationProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const chevron = open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

  return (
    <>
      <Grid
        data-testid="contact-info-header"
        className={classes['row-level-1']}
        container
        onClick={() => setOpen(!open)}
      >
        <Grid item xs={11}>
          <Typography>{t('contactInformation.contactInformation')}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="expand row" size="small">
            {chevron}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container className={classes['row-odd']}>
          <Grid item xs={7}>
            <Typography>{t('contactInformation.supervisor')}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{props.supervisor}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes['row-even']}>
          <Grid item xs={7}>
            <Typography>{t('contactInformation.customerContact')}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{props.customerContact}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes['row-odd']}>
          <Grid item xs={7}>
            <Typography>{t('contactInformation.installer')}</Typography>
          </Grid>
          <Grid item xs={5}>
            {props.installers?.map((name, index) => (
              <Typography key={index}>{name}</Typography>
            ))}
          </Grid>
        </Grid>

        <Grid container className={classes['row-even']}>
          <Grid item xs={7}>
            <Typography>{t('contactInformation.tester')}</Typography>
          </Grid>
          <Grid item xs={5}>
            {props.testers?.map((name, index) => (
              <Typography key={index}>{name}</Typography>
            ))}
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default ContactInformation;
