import {
  IconRoleInstaller,
  IconFiletypeOther,
  IconRoleTester,
} from '@konecorp/ui-library';
import {
  Grid,
  Typography,
  IconButton,
  Collapse,
  Box,
  Button,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Installation, QuestionSet, AnswerSet } from '../../schemas';
import { green, blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(0.5),
      backgroundColor: '#005A93',
      color: '#FFFFFF',
      height: theme.typography.fontSize * 3,
      width: '100%',
      '& p': {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
      '& .MuiIconButton-root': {
        paddingTop: theme.spacing(1),
        color: '#FFFFFF',
      },
    },
    qdFormLinkText: {
      paddingLeft: theme.spacing(1),
      color: theme.palette.primary.main,
      textTransform: 'initial',
      textDecoration: 'underline',
    },
  })
);

type FormProps = {
  text: string;
  questions: QuestionSet[];
  answers: AnswerSet[];
  icon: JSX.Element;
  color: string;
  buttonKey: string;
  onShowForm: (questionSet: QuestionSet[], answerSet: AnswerSet[]) => void;
};

const ReviewForm = (props: FormProps) => {
  const { color, text, icon, questions, answers, buttonKey, onShowForm } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box mb={2} width="100%">
      <Box
        p={0.5}
        display="flex"
        alignItems="center"
        width="100%"
        style={{ backgroundColor: color }}
      >
        {icon}
        <Typography component="div" style={{ marginLeft: '4px' }}>
          {text}
        </Typography>
      </Box>
      <Button onClick={() => onShowForm(questions, answers)} aria-label={buttonKey}>
        <IconFiletypeOther fontSize="large" />
        <Typography className={classes.qdFormLinkText}>
          {t('reviewForm.openForm')}
        </Typography>
      </Button>
    </Box>
  );
};

export type PrintoutFormsProps = {
  installationData: Installation | null;
  onShowReviewFormWithData: (questionSet: QuestionSet[], answerSet: AnswerSet[]) => void;
};

const PrintoutForms = (props: PrintoutFormsProps): JSX.Element => {
  const { installationData, onShowReviewFormWithData } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const chevron = open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

  return (
    <>
      <Grid
        data-testid="printout-forms-header"
        className={classes.root}
        container
        onClick={() => setOpen(!open)}
      >
        <Grid item xs={11}>
          <Typography>{t('reviewForm.forms')}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="expand row" size="small">
            {chevron}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box m={2} display="flex" flexDirection="column" alignItems="flex-start">
          <ReviewForm
            text={t('reviewForm.qdPlusForm')}
            color={green[100]}
            icon={<IconRoleInstaller fontSize="small" />}
            questions={installationData?.installerQuestions ?? []}
            answers={installationData?.installerAnswers ?? []}
            buttonKey="qd-plus-form-button"
            onShowForm={onShowReviewFormWithData}
          />
          <ReviewForm
            text={t('reviewForm.qualityReviewForm')}
            color={blue[100]}
            icon={<IconRoleTester fontSize="small" />}
            questions={installationData?.testerQuestions ?? []}
            answers={installationData?.testerAnswers ?? []}
            buttonKey="quality-review-form-button"
            onShowForm={onShowReviewFormWithData}
          />
        </Box>
      </Collapse>
    </>
  );
};

export default PrintoutForms;
