import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button, Typography, Theme } from '@material-ui/core';
import SubHeader from '../SubHeader';
import { useParams, useHistory } from 'react-router-dom';
import { theme as koneTheme, theme, InfoModal } from '@konecorp/ui-library';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ActivityDifferentiator, InstallationStatus, isAssignment } from '../../schemas';
import PreCheckListQuestionsList from '../../containers/Pre-ChecklistQuestionsList';
import { useTranslation } from 'react-i18next';
import Context, { InstallationContext } from '../../context';
import { useGetToken } from '../../hooks/useGetToken';
import {
  API_TYPE,
  fetchEmployeeFullName,
  fetchSubcontractors,
  get,
  put,
} from '../../helpers/fetch';
import { Installation } from '../../schemas';
import { getDeviationsData } from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import FloatingSyncButton from '../FloatingSyncButton';

interface QueryParams {
  networkNumber: string;
}
const useStyles = makeStyles((t: Theme) =>
  createStyles({
    content: {
      marginLeft: t.spacing(2),
      marginRight: t.spacing(2),
      paddingTop: t.spacing(3),
      height: '100%',
    },
    greenHeadline: {
      backgroundColor: t.palette.success.light,
      padding: t.spacing(1),
    },
    blueHeadline: {
      backgroundColor: t.palette.info.light,
      padding: t.spacing(1),
    },
    grayHeadline: {
      backgroundColor: '#E0E0E0',
      padding: t.spacing(1),
    },
    assigmentInfo: {
      paddingLeft: t.spacing(3),
      paddingBottom: t.spacing(1),
      paddingTop: t.spacing(1),
    },
    extraInfo: {
      marginTop: t.spacing(2),
      '& > div': {
        paddingLeft: t.spacing(3),
        paddingTop: t.spacing(2),
      },
    },
    buttonRow: {
      paddingLeft: 0,
    },
    upperCase: {
      textTransform: 'uppercase',
    },
    closeDialogIcon: {
      width: 30,
      height: 30,
      position: 'absolute',
      zIndex: 1,
      right: theme.spacing(1.5),
      top: theme.spacing(1.5),
    },
  })
);

const PreChecklist = (): JSX.Element => {
  const history = useHistory();
  const [questions, setQuestions] = useState([]);
  const { dispatch } = useContext(InstallationContext);
  const [installerNames, setInstallerNames] = useState<string[]>([]);
  const [confirmationDialogOpen, setOpenConfimationDialog] = useState<boolean>(false);
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const {
    installationData,
    updateIsLoading,
    updateInstallationData,
    updateNetworkNumber,
  } = useContext(Context);
  const [getTokenFunction] = useGetToken();
  const { t } = useTranslation();
  const { networkNumber } = useParams() as QueryParams;
  const classes = useStyles(koneTheme);

  const handleCloseConfirmationDialog = async () => {
    try {
      updateIsLoading(true);
      await updateInstallationListsInContext();
      updateIsLoading(false);
      history.push('/tobestarted');
    } catch (e) {
      updateIsLoading(false);
      console.error('error when fetching installation lists', e);
    }
  };
  const getInstallerNames = async () => {
    const accessToken = await getTokenFunction();
    const subcontractors = await fetchSubcontractors(networkNumber, accessToken);
    const subcontractorInstallers = subcontractors.filter(
      (subcontractor) =>
        subcontractor.activityDifferentiator === ActivityDifferentiator.INST
    );
    const latestInstallers = await get(
      `v1/installations/${networkNumber}/?getBy=networkNumber`,
      accessToken
    );
    const koneInstaller = latestInstallers.assignees.filter(
      (worker: { activityDifferentiator: ActivityDifferentiator }) =>
        worker.activityDifferentiator === ActivityDifferentiator.INST &&
        !('subcontractor' in worker)
    );
    const installers = [...koneInstaller, ...subcontractorInstallers];
    const installerNamePromises = installers.map((worker) =>
      isAssignment(worker)
        ? fetchEmployeeFullName(worker.koneResourcePersonalNumber, accessToken)
        : Promise.resolve(worker.subcontractor.name)
    );
    return await Promise.all(installerNamePromises);
  };

  useEffect(() => {
    const fetchData = async () => {
      let questions;
      let fetchedDeviations;
      try {
        if (networkNumber) {
          updateIsLoading(true);
          const accessToken = await getTokenFunction();
          const data: Installation | null = await get(
            `v1/installations/${networkNumber}`,
            accessToken
          );
          if (data) {
            questions = await get(
              `v1/pre-install-checklist?role=${ActivityDifferentiator.SPV}`,
              accessToken
            );
            setQuestions(questions);
          }
          updateInstallationData(data);
          updateNetworkNumber(networkNumber);
          fetchedDeviations = await getDeviationsData(accessToken, networkNumber);
          dispatch({
            type: InstallationActionName.SET_DEVIATIONS,
            deviations: fetchedDeviations,
          });
        }
        if (questions) {
          const newUrl = `${history.location.pathname}?questionSetId=${
            questions[1].questionId.split('#')[1]
          }`;
          history.replace(newUrl);
        }
      } catch (e) {
        console.error(
          'Error while fetching installation data or questions or deviations',
          e
        );
      } finally {
        updateIsLoading(false);
      }
    };

    fetchData();
  }, [networkNumber, history]);

  const handleStartButtonClick = async (): Promise<void> => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await put(
        `v1/installations/${networkNumber}/status`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          status: InstallationStatus.FOR_INSTALLER_ACCEPTANCE,
        }
      );
      const installersNames = await getInstallerNames();
      setInstallerNames(installersNames);
      setOpenConfimationDialog(true);
    } catch (error) {
      console.error('error while updating installation status', error);
    } finally {
      updateIsLoading(false);
    }
  };
  // eslint-disable-next-line no-console
  console.log(
    'preInstallChecklistAnswers: ',
    installationData?.preInstallCheckListAnswers?.[0]?.answers
  );

  return (
    <>
      <SubHeader
        title={networkNumber}
        handleGoBackClick={() => {
          history.push(`/${networkNumber}/newinstallation`);
        }}
      />
      {installationData?.preInstallCheckListAnswers && (
        <PreCheckListQuestionsList
          questions={questions}
          answers={installationData?.preInstallCheckListAnswers}
        />
      )}
      <Grid item className={classes.extraInfo}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.buttonRow}
        >
          <Grid>
            <Button
              data-testid="start-button"
              disabled={
                (installationData?.preInstallCheckListAnswers?.[0]?.answers?.filter(
                  (answer) => answer !== null
                ).length || 0) !==
                questions.length - 1
              }
              variant="contained"
              color="primary"
              onClick={handleStartButtonClick}
            >
              <Typography>
                {' '}
                {t('supervisorNewInstallation.sendInstallationForAcceptance')}{' '}
              </Typography>
            </Button>
            <InfoModal
              closeButtonText={t('supervisorNewInstallation.OK')}
              message={t('supervisorNewInstallation.markedMessage', {
                installerNames: installerNames.join(', '),
              })}
              open={confirmationDialogOpen}
              onClose={handleCloseConfirmationDialog}
              isCenteredMessage
            />
          </Grid>
        </Grid>
      </Grid>
      <FloatingSyncButton />
    </>
  );
};

export default PreChecklist;
