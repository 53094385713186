import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '@konecorp/ui-library';

import { ExtendedInstallation } from '../../schemas';
import { formatDate } from '../../helpers/formating';

export type SelfAssignSupervisorProps = {
  networkNumber: string;
  installation: ExtendedInstallation;
  currentSupervisor: string;
  onConfirm: (networkNumber: string) => Promise<void>;
  onCancel: () => void;
};

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  assignSummary: {
    padding: theme.spacing(1),
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
}));

const SelfAssignSupervisor = (props: SelfAssignSupervisorProps): JSX.Element => {
  const { networkNumber, installation, currentSupervisor, onConfirm, onCancel } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const { customer, firstAssignmentDate } = installation;

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.assignSummary}>
          <Grid container item spacing={3}>
            <Grid item xs={8}>
              <Typography variant="subtitle2">{customer?.customerName1}</Typography>
              <Typography variant="subtitle2">{customer?.customerName2}</Typography>
              <Typography variant="subtitle2">
                {`${customer?.street || ''} ${customer?.houseNumber || ''}, 
                  ${customer?.cityPostalCode || ''} ${customer?.city || ''}`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{networkNumber}</Typography>
              <Typography variant="subtitle2">
                {formatDate(firstAssignmentDate)}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box pt={2}>
          <Typography>{t('selfAssignSupervisor.confirmMessage')}</Typography>
          <br />
          <b>{currentSupervisor}</b>
          <br />
          <Typography>{t('selfAssignSupervisor.confirmQuestion')}</Typography>
          <br />
          <Typography>{t('selfAssignSupervisor.emailMessage')}</Typography>
          <br />
        </Box>

        <Box pt={4} pb={2}>
          <Box display="inline" mr={1}>
            <Button
              variant="contained"
              color="primary"
              aria-label="button-assign-to-me-cancel"
              onClick={() => onCancel()}
            >
              {t('selfAssignSupervisor.cancelButton')}
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              aria-label="button-assign-to-me"
              onClick={() => onConfirm(networkNumber)}
            >
              {t('selfAssignSupervisor.yesButton')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SelfAssignSupervisor;
