import React, { ChangeEvent } from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
  Radio,
  Card,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { theme } from '@konecorp/ui-library';

export type RoutingQuestionProps = {
  questionText: string;
  answerOptions: string[];
  answer: string;
  onAnswerChange: (answer: string) => void;
  className?: string;
  readOnly?: boolean;
};

const useStyles = makeStyles((t: Theme) =>
  createStyles({
    questionCard: {
      padding: t.spacing(2),
    },
    radioGroup: {
      '& .MuiFormControlLabel-root': {
        height: t.spacing(5),
      },
    },
  })
);

const RoutingQuestion = (props: RoutingQuestionProps): JSX.Element => {
  const { answer, answerOptions, questionText, className, readOnly, onAnswerChange } =
    props;

  const classes = useStyles(theme);

  const handleRadioButtonChange = (event: ChangeEvent<HTMLInputElement>) => {
    onAnswerChange(event.target.value);
  };

  const arrayIndexToAlphabet = (index: number): string => {
    return String.fromCharCode(index + 1 + 64);
  };

  return (
    <Card className={[classes.questionCard, className].join(' ')} variant="outlined">
      <Typography>{questionText}</Typography>
      <FormControl>
        <RadioGroup
          aria-label="routing-answer"
          name="routing-answer"
          value={answer}
          onChange={handleRadioButtonChange}
          className={classes.radioGroup}
        >
          {answerOptions.map((option, index) => {
            return (
              <FormControlLabel
                disabled={readOnly}
                key={`routing-answer-${option}`}
                value={option}
                control={<Radio color="primary" data-testid={`radio-${option}`} />}
                label={`${arrayIndexToAlphabet(index)} - ${option}`}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Card>
  );
};

export default RoutingQuestion;
