import { FrontlineSettings, UserSettings } from '../schemas';
import { API_TYPE, get, put } from './fetch';

export const getSettings = async <SettingsType extends FrontlineSettings | UserSettings>(
  id: string,
  token: string
): Promise<SettingsType> => {
  return await get(`v1/applications/ies-app/settings/${id}`, token, API_TYPE.APPLICATION);
};

export const putSettings = async <SettingsType extends FrontlineSettings | UserSettings>(
  id: string,
  settings: SettingsType,
  token: string
): Promise<void> => {
  return await put(
    `v1/applications/ies-app/settings/${id}`,
    token,
    API_TYPE.APPLICATION,
    settings
  );
};
