import React from 'react';
import { Dialog, DialogContent, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme, NaviClose } from '@konecorp/ui-library';

import { QuestionSet, AnswerSet, Installation, Deviation } from '../../schemas';
import QuestionSummaryTable from '../../components/QuestionSummaryTable';

export type ReviewFormModalProps = {
  questions: QuestionSet[];
  answers: AnswerSet[];
  networkNumber: string;
  supervisorName: string;
  installationData: Installation | null;
  openDeviations: Deviation[];
  handleCloseOnClick: () => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: 'absolute',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    closeIcon: {
      fontSize: '2.7rem',
    },
  })
);

export const ReviewFormModal = ({
  questions,
  answers,
  handleCloseOnClick,
  networkNumber,
  installationData,
  supervisorName,
  openDeviations,
}: ReviewFormModalProps): JSX.Element => {
  const classes = useStyles(theme);

  return (
    <Dialog fullScreen open={true} aria-label="review-form-modal">
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            aria-label="close-button"
            color="inherit"
            edge="end"
            onClick={handleCloseOnClick}
          >
            <NaviClose className={classes.closeIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <QuestionSummaryTable
          supervisorName={supervisorName}
          questionSets={questions}
          answerSets={answers}
          networkNumber={networkNumber}
          installationData={installationData}
          openDeviations={openDeviations}
        />
      </DialogContent>
    </Dialog>
  );
};
