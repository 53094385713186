import React from 'react';
import { Box, Typography as MuiTypography, useMediaQuery } from '@material-ui/core';
import CustomizedLocalizedQuestionText from '../CustomizedLocalizedQuestionText';

import QDQuestionImage from '../../components/QDQuestionImage';

type ReviewQuestionInfoProps = {
  title: string;
  additionalInfo: string | null;
  imageLink: string | null;
};

const ReviewQuestionInfo = (props: ReviewQuestionInfoProps): JSX.Element => {
  const { title, additionalInfo, imageLink } = props;
  const isMobile = useMediaQuery('(max-width:767px)', { noSsr: true });

  return (
    <Box p={4} data-testid="review-question-info">
      <Box pb={4}>
        <CustomizedLocalizedQuestionText
          typographyVariant={isMobile ? 'h5' : 'h4'}
          localaizedQuestionText={title}
        />
      </Box>
      <QDQuestionImage imageLink={imageLink} />
      <Box pt={4}>
        <MuiTypography variant={isMobile ? 'body2' : 'h6'}>
          {additionalInfo}
        </MuiTypography>
      </Box>
    </Box>
  );
};

export default ReviewQuestionInfo;
