import React, { useState, useContext, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { theme } from '@konecorp/ui-library';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
  Installation,
  PlumbingMeasurementCategory,
  PlumbingNominalQuestion,
} from '../../schemas';
import Context from '../../context';
import { API_TYPE, put, get } from '../../helpers/fetch';
import NavigationButtons from '../NavigationButtons';
import {
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableRow,
} from '../PlumbingSummaryTable';
import {
  getQuestionListWithUpdatedActualValue,
  extractPlumbingQuestionFromInstallationData,
  getPlumbingMeasurements,
  getDefaultNominalValues,
} from '../../helpers/plumbingSummary';
import { addHandoverStep } from '../../helpers/question';
import QDQuestionImage from '../QDQuestionImage';
import Empty from '../../components/Empty';
import { useGetToken } from '../../hooks/useGetToken';
import { useIfReadOnly } from '../../hooks/useIfReadOnly';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  tableContainer: {
    maxWidth: '100%',
  },
  tableCellFirstColumn: {
    color: theme.palette.secondary.main,
    width: theme.spacing(10),
    borderBottom: theme.spacing(0),
  },
  input: {
    width: '100px',
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1),
    },
  },
  inputCell: {
    padding: 0,
  },
  imageContainer: {
    minHeight: theme.spacing(35),
  },
}));

export type PlumbingNominalValueCorrectionProps = {
  onClickForward: () => void;
  onNavigateBack: () => void;
  installationData: Installation;
  imageLink: string | null;
};

export type NominalValues = {
  A1ActualValue: number;
  A2ActualValue: number;
  A3ActualValue: number;
  A4ActualValue: number;
  A5ActualValue?: number;
  A6ActualValue?: number;
};

enum NominalView {
  Table,
  Image,
}

type TabPanelProps = {
  children: JSX.Element | string;
  currentSelectedTabValue: NominalView;
  tabName: NominalView;
};

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    padding: `0 ${theme.spacing(1.5)}`,
    fontSize: theme.typography.fontSize * 1.6,
  },
}))(Tab);

const TabPanel = (props: PropsWithChildren<TabPanelProps>): JSX.Element => {
  const { children, currentSelectedTabValue, tabName } = props;

  return (
    <div
      role="tabpanel"
      hidden={currentSelectedTabValue !== tabName}
      id={`simple-tabpanel-${tabName}`}
      aria-labelledby={`simple-tab-${tabName}`}
    >
      {currentSelectedTabValue === tabName && <Box pt={0.5}>{children}</Box>}
    </div>
  );
};

const getTabAriaControls = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const PlumbingNominalValueCorrection = (
  props: PlumbingNominalValueCorrectionProps
): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [getTokenFunction] = useGetToken();
  const [isReadOnlyMode] = useIfReadOnly();

  const { networkNumber, updateInstallationData, updateIsLoading } = useContext(Context);
  const { onNavigateBack, onClickForward, installationData, imageLink } = props;
  const plumbingMeasurementPoints: PlumbingMeasurementCategory =
    getPlumbingMeasurements(installationData);

  const [nominalValues, setNominalValues] = useState<NominalValues>(
    getDefaultNominalValues(plumbingMeasurementPoints)
  );
  const [currentSelectedTab, setCurrentSelectedTab] = useState<NominalView>(
    NominalView.Table
  );

  const onUpdateNominalValues = async (
    plumbingQuestions: PlumbingNominalQuestion[][]
  ) => {
    const changedQuestionsAsPayload = getQuestionListWithUpdatedActualValue(
      nominalValues,
      plumbingQuestions
    ).map((question) => ({
      questionSetId: question.questionSetId,
      questionSequenceNumber: Number(question.questionSequenceNumber),
      actualValue: Number(question.actualValue),
    }));

    updateIsLoading(true);
    try {
      if (changedQuestionsAsPayload.length) {
        const accessToken = await getTokenFunction();
        await put(
          `v1/installations/${networkNumber}/questions`,
          accessToken,
          API_TYPE.APPLICATION,
          { questions: changedQuestionsAsPayload }
        );
        const data: Installation | null = await get(
          `v1/installations/${networkNumber}`,
          accessToken
        );
        const installationDataWithHandover = data ? addHandoverStep(data) : null;
        updateInstallationData(installationDataWithHandover);
      }
    } catch (e) {
      console.error(e);
    } finally {
      updateIsLoading(false);
    }
    onClickForward();
  };

  const tableHeaders = [
    '',
    t('plumbingSummary.currentValue'),
    t('plumbingSummary.correction'),
  ];

  const onChangeInput = (indexToUpdate: number, newValue: number) => {
    const keyToUpdate = Object.keys(nominalValues).find(
      (_, index) => index === indexToUpdate
    );
    if (keyToUpdate) {
      const newNominalValues = {
        ...nominalValues,
        [keyToUpdate]: newValue,
      };
      setNominalValues(newNominalValues);
    } else {
      console.error(
        'Error while updating nominal values, indexing issue in table input fields'
      );
    }
  };

  const getNominalValue = (index: number) => {
    const value = Object.values(nominalValues).find((_, i) => i === index);
    return value ? value : '';
  };
  const plumbingQuestions = extractPlumbingQuestionFromInstallationData(
    installationData,
    plumbingMeasurementPoints.length
  );

  // checking first floor measurement to get first 6 readings (A1, A2....A6)
  const firstFloorQuestions = plumbingQuestions[0];
  const measurementCount = firstFloorQuestions?.length;

  if (measurementCount === 0) {
    console.error('Invalid installation data, floor (position) information missing');
    return <Box>{t('qdPage.noQuestion')}</Box>;
  }

  return (
    <Box
      data-testid="plumbing-nominal-values"
      display="flex"
      flexDirection="column"
      className={classes.container}
    >
      {/* when user hasn't choose wire or not, we don't have/know a set of questions to display a nominal table, hence display an error message */}
      {plumbingQuestions.length === 0 && (
        <Empty displayIcon={false} message={t('plumbingSummary.noLaserOrWireAnswer')} />
      )}
      {/* here we have a set of question to display a nominal table */}
      {plumbingQuestions.length > 0 && (
        <Box flexGrow={1}>
          <Tabs
            TabIndicatorProps={{ style: { background: '#0071B9' } }}
            value={currentSelectedTab}
            onChange={(_, newValue) => {
              setCurrentSelectedTab(newValue);
            }}
            aria-label="installation summary tabs"
          >
            <CustomTab
              data-testid="tab-table"
              label={t('plumbingSummary.titleNominal')}
              {...getTabAriaControls(NominalView.Table)}
            />
            <CustomTab
              data-testid="tab-image"
              label={t('plumbingSummary.titlePositions')}
              {...getTabAriaControls(NominalView.Image)}
            />
          </Tabs>

          <TabPanel
            currentSelectedTabValue={currentSelectedTab}
            tabName={NominalView.Table}
          >
            <Box>
              <TableContainer
                className={classes.tableContainer}
                data-testid="plumbing-nominal-values-table"
              >
                <Table
                  className={classes.tableContainer}
                  aria-label="nominal value table"
                >
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((header, index) => (
                        <StyledTableHeadCell
                          key={`nominal-table-th-${index}`}
                          align="center"
                        >
                          {header}
                        </StyledTableHeadCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {firstFloorQuestions.map((question, index) => {
                      const nominalValueKey = plumbingMeasurementPoints[index] || '';
                      return (
                        <StyledTableRow key={`plumbing-nominal-row-${index}`}>
                          <>
                            <StyledTableCell
                              className={classes.tableCellFirstColumn}
                              key={'nominal-table-cell-0'}
                              align="center"
                            >
                              {nominalValueKey}
                            </StyledTableCell>
                            <StyledTableCell
                              key={`nominal-table-actualValue-cell-${index}`}
                              align="center"
                            >
                              {question.actualValue}
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.inputCell}
                              key={`nominal-table-input-cell-${index}`}
                              align="center"
                            >
                              <TextField
                                id="standard-multiline-static"
                                placeholder={t('plumbingSummary.inputPlaceholder')}
                                className={classes.input}
                                value={getNominalValue(index)}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  onChangeInput(index, Number(event.target.value))
                                }
                                type="number"
                                variant="outlined"
                                inputProps={{
                                  'data-testid': `nominal-input-${index}`,
                                  inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                }}
                                disabled={isReadOnlyMode}
                              />
                            </StyledTableCell>
                          </>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                borderColor={theme.palette.secondary.main}
                borderRadius={5}
                m={2}
                mt={3}
                p={2}
                border={1}
              >
                <Typography>{t('plumbingSummary.nominalText')}</Typography>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel
            currentSelectedTabValue={currentSelectedTab}
            tabName={NominalView.Image}
          >
            <Box
              className={classes.imageContainer}
              mb={4}
              data-testid="plumbing-laser-image"
              display="flex"
              justifyContent="center"
            >
              <QDQuestionImage imageLink={imageLink} />
            </Box>
          </TabPanel>
        </Box>
      )}

      <Box justifyContent="center" p={3} mt="auto" pb={11}>
        <NavigationButtons
          onClickBackward={onNavigateBack}
          onClickForward={() => onUpdateNominalValues(plumbingQuestions)}
          backwardButtonText={t('plumbingSummary.backButton')}
          forwardButtonText={t('plumbingSummary.confirmButton')}
        />
      </Box>
    </Box>
  );
};

export default PlumbingNominalValueCorrection;
