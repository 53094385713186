import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { debounce } from 'lodash';

import { InputAdornment, TextField } from '@material-ui/core';

import { theme } from '@konecorp/ui-library';
import { Close } from '@material-ui/icons';

const SearchDebounced = (props: {
  onChange: (value: string) => void;
  onClose: () => void;
  placeholder?: string;
  waitTime?: number;
}): JSX.Element => {
  const { t } = useTranslation();

  const { onChange, onClose, placeholder, waitTime } = props;

  const [value, setValue] = useState<string>('');

  const delayedHandler = useCallback(
    debounce((value: string) => onChange(value), waitTime || 0),
    []
  );

  return (
    <TextField
      focused
      fullWidth
      variant="outlined"
      size="small"
      color="primary"
      value={value}
      placeholder={placeholder || t('searchDebounced.placeholder')}
      style={{ padding: theme.spacing(1) }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Close
              data-testid="search-close-icon"
              onClick={() => {
                setValue('');
                onClose();
              }}
            />
          </InputAdornment>
        ),
      }}
      onChange={(event) => {
        setValue(event.target.value);
        delayedHandler(event.target.value);
      }}
    />
  );
};

export default SearchDebounced;
