import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { TextField, IconButton, Typography } from '@material-ui/core';
import { theme } from '@konecorp/ui-library';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';

export enum SearchInputType {
  TEXT = 'text',
  NUMERIC = 'numeric',
}

export type SearchProps = {
  label?: string;
  initialSearchText?: string;
  searchLabel?: string;
  searchInputType?: SearchInputType;
  minLength?: number;
  maxLength?: number;
  errorMessage?: string;
  onSubmit: (searchText: string) => void;
  disabled?: boolean;
};

const Search = ({
  searchInputType = SearchInputType.TEXT,
  label = `A ${searchInputType} search field`,
  initialSearchText = '',
  minLength,
  maxLength,
  errorMessage,
  onSubmit,
  disabled = false,
  searchLabel,
}: SearchProps): JSX.Element => {
  const [searchText, setSearchText] = useState<string>(initialSearchText);
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
    searchInput: {
      paddingRight: theme.spacing(0),
      '& button.MuiIconButton-root': {
        borderRadius: '0px',
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.info.dark,
        '& hover': {
          backgroundColor: theme.palette.info.dark,
        },
      },
    },
  }));

  const classes = useStyles(theme);

  const handleOnChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.validity.valid) {
      const searchTextValue = event.target.value;
      setSearchText(searchTextValue);
    }
  };

  const handleOnClickSearchButton = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onSubmit(searchText);
  };

  const hasErrorMessage = Boolean(errorMessage && errorMessage.length > 0);

  return (
    <div className={classes.container}>
      <Typography variant="subtitle2" gutterBottom>
        {searchLabel}
      </Typography>
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        error={hasErrorMessage}
        helperText={errorMessage}
        value={searchText}
        onChange={handleOnChangeSearchText}
        inputProps={{
          'aria-label': 'search-input',
          inputMode: searchInputType,
          pattern: searchInputType === SearchInputType.NUMERIC ? '[0-9]*' : '(.*?)',
          minLength,
          maxLength,
        }}
        InputProps={{
          className: classes.searchInput,
          endAdornment: (
            <IconButton
              aria-label="search-icon"
              onClick={handleOnClickSearchButton}
              disabled={disabled}
            >
              <SearchIcon fontSize="large" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};
export default Search;
