import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enResources from './translations/en.json';
import enGbResources from './translations/en-GB.json';
import itResources from './translations/it.json';
import plResources from './translations/pl.json';
import roResources from './translations/ro.json';
import svResources from './translations/sv.json';
import frResources from './translations/fr.json';

const resources = {
  en: {
    ...enResources,
  },
  'en-GB': {
    ...enGbResources,
  },
  it: {
    ...itResources,
  },
  pl: {
    ...plResources,
  },
  ro: {
    ...roResources,
  },
  sv: {
    ...svResources,
  },
  fr: {
    ...frResources,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
  });

export default i18n;
