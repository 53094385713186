import React, { useEffect } from 'react';

import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Handover } from '../../schemas';

import HandoverSummaryPreview from '../HandoverSummaryPreview';

type InstallationStartingTesterProps = {
  handover: Handover | null;
  setAllowStart: (value: boolean) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    previewHandover: {
      width: '100%',
    },
  })
);

const InstallationStartingTester = (
  props: InstallationStartingTesterProps
): JSX.Element => {
  const classes = useStyles();

  const { handover, setAllowStart } = props;

  useEffect(() => {
    setAllowStart(true);
  }, []);

  return (
    <Box data-testid="installation-starting-tester">
      <HandoverSummaryPreview className={classes.previewHandover} handover={handover} />
    </Box>
  );
};

export default InstallationStartingTester;
