import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Contact, ContactRole } from '../../schemas';

import { emailValidator } from '../../helpers/validators';
import { isValidPhoneNumber } from 'libphonenumber-js';

export type ContactFormProps = {
  contactToEdit?: Contact;
  onClose: () => void;
  onSubmit: (state: Contact) => Promise<void>;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2),
      textTransform: 'uppercase',
      borderBottom: '1px solid lightgrey',
    },
    fields: {
      padding: theme.spacing(2, 2, 5),
      borderBottom: '1px solid lightgrey',
      '& .MuiFormControl-root': {
        margin: theme.spacing(2, 0),
      },
    },
    buttonGroup: {
      margin: theme.spacing(4, 4, 2),
      '& .MuiButton-root:first-child': {
        marginRight: theme.spacing(2),
      },
    },
  })
);

const ContactForm = ({
  contactToEdit,
  onClose,
  onSubmit,
}: ContactFormProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const initialContact: Contact = {
    role: ContactRole.BUILDER,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    guid: '',
  };

  const validationSchema = yup.object({
    firstName: yup.string().trim().required(t('contactForm.requiredHelperText')),
    lastName: yup.string().trim().required(t('contactForm.requiredHelperText')),
    email: yup
      .string()
      .trim()
      .required(t('contactForm.requiredHelperText'))
      .test(
        'is-valid-email',
        t('contactForm.invalidEmail'),
        (email) => !!email && emailValidator(email)
      ),
    phone: yup
      .string()
      .trim()
      .required(t('contactForm.requiredHelperText'))
      .test(
        'is-valid-phone',
        t('contactForm.invalidPhone'),
        (phone) => !!phone && isValidPhoneNumber(phone)
      ),
  });

  const handleSubmit = async (values: Contact, helpers: FormikHelpers<Contact>) => {
    await onSubmit({
      ...values,
      guid: contactToEdit?.guid ?? '',
    });
    helpers.resetForm();
    onClose();
  };

  const formik = useFormik({
    initialValues: contactToEdit ?? initialContact,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Box
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}
    >
      <Typography variant="h6" align="center" className={classes.title}>
        {!contactToEdit ? t('contactForm.addContact') : t('contactForm.editContact')}
      </Typography>
      <Box display="flex" flexDirection="column" className={classes.fields}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="contact-form-role-select-label">
            {t('contactForm.role')}
          </InputLabel>
          <Select
            labelId="contact-form-role-select-label"
            data-testid="contact-form-role-select"
            margin="dense"
            value={formik.values.role}
            name="role"
            onChange={formik.handleChange}
            label={t('contactForm.role')}
          >
            {Object.values(ContactRole).map((role: ContactRole) => (
              <MenuItem key={role} value={role}>
                {t(`contactRoles.${role}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          inputProps={{
            'data-testid': 'contact-form-first-name-input',
          }}
          fullWidth
          required
          variant="outlined"
          margin="dense"
          type="text"
          name="firstName"
          label={t('contactForm.firstName')}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          inputProps={{
            'data-testid': 'contact-form-last-name-input',
          }}
          fullWidth
          required
          variant="outlined"
          margin="dense"
          type="text"
          name="lastName"
          label={t('contactForm.lastName')}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          data-testid="contact-form-email-textfield"
          inputProps={{
            'data-testid': 'contact-form-email-input',
          }}
          fullWidth
          required
          name="email"
          variant="outlined"
          margin="dense"
          type="email"
          label={t('contactForm.email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          inputProps={{
            'data-testid': 'contact-form-phone-input',
          }}
          fullWidth
          required
          name="phone"
          variant="outlined"
          margin="dense"
          type="phone"
          label={t('contactForm.phone')}
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        justifyItems="space-between"
        className={classes.buttonGroup}
      >
        <Button fullWidth variant="outlined" onClick={onClose} color="primary">
          {t('contactForm.cancel')}
        </Button>
        <Button
          fullWidth
          disableElevation
          type="submit"
          variant="contained"
          color="primary"
          disabled={!formik.isValid || !formik.dirty}
          data-testid="contact-form-save-button"
        >
          {t('contactForm.save')}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;
