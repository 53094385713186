import {
  SUPPORTED_IMAGE_FILE_TYPES,
  SUPPORTED_VIDEO_FILE_TYPES,
  SUPPORTED_AUDIO_FILE_TYPES,
  getFileTypesRegxString,
} from '../constants';

const imageFileTypes = getFileTypesRegxString(SUPPORTED_IMAGE_FILE_TYPES);
export const isImageFile = (fileName: string): boolean =>
  new RegExp(`^.*.(${imageFileTypes})$`).test(fileName);

const videoFileTypes = getFileTypesRegxString(SUPPORTED_VIDEO_FILE_TYPES);
export const isVideoFile = (fileName: string): boolean =>
  new RegExp(`^.*.(${videoFileTypes})$`).test(fileName);

const audioFileTypes = getFileTypesRegxString(SUPPORTED_AUDIO_FILE_TYPES);
export const isAudioFile = (fileName: string): boolean =>
  new RegExp(`^.*.(${audioFileTypes})$`).test(fileName);
